import { FormattedMessage, injectIntl } from 'react-intl';

const InjectMessage = props => {
    const { whitespaceStart, whitespaceEnd } = props;
    return <>
        {whitespaceStart && ' '}
        <FormattedMessage  {...props} />
        {whitespaceEnd && ' '}
    </>
};

//defaultProps
InjectMessage.defaultProps = {
    whitespaceStart: false,
    whitespaceEnd: false
};

export default injectIntl(InjectMessage, {
    withRef: false
});

export enum ProcessesActions {
    SET_PROCESSES= 'SET_PROCESSES',
    CLEAN_UP_PROCESSES = 'CLEAN_UP_PROCESSES',
    CREATE_PROCESS = 'CREATE_PROCESS',
    SET_CLIENT = 'SET_CLIENT',
    SET_FACILITY = 'SET_FACILITY',
    SET_SOLUTION = 'SET_SOLUTION',
    RESET_CLIENT = 'RESET_CLIENT',
    RESET_FACILITY = 'RESET_FACILITY',
    RESET_SOLUTION = 'RESET_SOLUTION',
    SET_HAS_ASSET_MANAGEMENT = 'SET_HAS_ASSET_MANAGEMENT',
    SET_IS_LOADING = 'SET_IS_LOADING'
}

export enum ProcessesStateDescriptions {
    CREATED = 'CREATED',
    PRE_QUOTE_SURVEY_REQUESTED = 'PRE_QUOTE_SURVEY_REQUESTED',
    PRE_QUOTE_SURVEY_COMPLETED = 'PRE_QUOTE_SURVEY_COMPLETED',
    PRE_INSTALLATION_SURVEY_REQUESTED = 'PRE_INSTALLATION_SURVEY_REQUESTED',
    PRE_INSTALLATION_SURVEY_COMPLETED = 'PRE_INSTALLATION_SURVEY_COMPLETED',
    INSTALLATION_KICK_OFF_SURVEY_REQUESTED = 'INSTALLATION_KICK_OFF_SURVEY_REQUESTED',
    INSTALLATION_KICK_OFF_SURVEY_COMPLETED = 'INSTALLATION_KICK_OFF_SURVEY_COMPLETED',
    COMISSIONING_PENDING = 'COMISSIONING_PENDING',
    COMISSIONING_COMPLETE = 'COMISSIONING_COMPLETE',
    PAUSED = 'PAUSED',
    CANCELLED = 'CANCELLED'
}
import { StructuresActions } from "constants/structures"
import { IStructuresReducerAction, IStructuresState } from "interfaces/structures"


export const initialStructuresReducer: IStructuresState = {
    structures: []
}

export const structuresReducer = (state = initialStructuresReducer, action: IStructuresReducerAction) => {
    switch(action.type) {
        case StructuresActions.SET_STRUCTURES:
            return {
                ...state,
                structures: action.payload
            }
        default:
            return state
    }
}
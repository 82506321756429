import { FiltersActions } from "constants/filters"
import { IFiltersState } from "interfaces/filters"
import { IFiltersReducerAction } from "interfaces/filters/filtersReducer"

export const initialFiltersReducer: IFiltersState = {
    client_name: '',
    from_updated_at: '',
    partner_ids: [],
    payment_methods: [],
    product_type_ids: [],
    state_ids: [],
    to_updated_at: ''
}

export const filtersReducer = (state = initialFiltersReducer, action: IFiltersReducerAction) => {
    switch(action.type) {
        case FiltersActions.SET_FILTERS:
            return {
                ...state,
                client_name: action.payload.client_name,
                from_updated_at: action.payload.from_updated_at,
                partner_ids: action.payload.partner_ids,
                payment_methods: action.payload.payment_methods,
                product_type_ids: action.payload.product_type_ids,
                state_ids: action.payload.state_ids,
                to_updated_at: action.payload.to_updated_at
            }
        case FiltersActions.CLEAN_UP_FILTERS: 
            return {
                ...state,
                client_name: initialFiltersReducer.client_name,
                from_updated_at: initialFiltersReducer.from_updated_at,
                partner_ids: initialFiltersReducer.partner_ids,
                payment_methods: initialFiltersReducer.payment_methods,
                product_type_ids: initialFiltersReducer.product_type_ids,
                state_ids: initialFiltersReducer.state_ids,
                to_updated_at: initialFiltersReducer.to_updated_at
            }
        default:
            return state
    }
}
import { memo } from 'react';
import PropTypes from 'prop-types';

//Material UI
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as DashboardIcon } from 'assets/images/icons/svg/dashboard.svg';
import useTheme from '@mui/material/styles/useTheme';

/**
 * DashboardCard
 * 
 * @param {*} param
 */
const DashboardCard = ({
    handleOnClickGoTo,
    isThisPathActive
}) => {

    const theme = useTheme();

    return (
        <div className="menu-item-content" onClick={handleOnClickGoTo}>
            <span className={`me-lg-2 default-svg${isThisPathActive ? "--open" : ""}`}>
                <DashboardIcon height={24} fill={theme.palette.primary.main} />
            </span>
            <div className="menu-item-text">
                <h5 className={`menu-item-label${isThisPathActive ? "--open" : ""}`}>
                    <IntlMessages id='label.dashboard' />
                </h5>
            </div>
        </div>
    );
}

//PropTypes
DashboardCard.propTypes = {
    handleOnClickGoTo: PropTypes.func.isRequired,
};

export default memo(DashboardCard);
import { USERS_TYPE_ID } from "constants/user"

export const GA_TYPE_EVT = {
    PROCESS_LIST: 'process_list',
    PROCESS_LIST_PAGINATION: 'process_list_pagination',
    PROCESS_LIST_FILTERS: 'process_list_filters',
    DASHBOARD_FILTER: 'dashboard_filter',
    DASHBOARD_CALENDAR: 'dashboard_calendar',
    PROCESS_DETAILS: 'process_details',
    PROCESS_DETAILS_TIMELINE: 'process_details_timeline',
    PROCESS_DETAILS_EDIT_CLIENT_DATA: 'process_details_edit_client_data',
    PROCESS_DETAILS_TECH_DETAILS: 'process_details_edit_tech_Details',
    PROCESS_DETAILS_INFO_SITE_SURVEY: 'process_details_info_site_survey',
    HOMEPAGE: 'homepage',
    NAVIGATION: 'navigation',
    LOGIN: 'login'
}

export const GA_PROCESS_LIST_FILTERS = {
    CONTRACT: 'Contract',
    CLIENT: 'Client',
    PARTNER: 'Partner',
    CITY: 'City',
    PROCESS_STATE: 'Process State',
    PRODUCT: 'Product Type',
    BUSINESS_MODEL: 'Business Model',
    PROPOSAL_GENERATOR: 'Proposal Generator',
    LAST_UPDATED_FROM: 'Last Updated From',
    LAST_UPDATED_UNTIL: 'Last Updated Until',
    SERVICE_MANAGER: 'Service Manager',
    SALES_OPERATION_MANAGER: 'Sals Operation Manager',
    SHOW_FILTERS: 'Show Filters',
    APPLY_FILTERS: 'Apply Filters',
    CLEAN_FILTERS: 'Clean Filters',
    REFRESH_TABLE_DATA: 'Refresh Table Data',
    SEARCH_BAR: 'Search Bar'
}

export const GA_EVT_CLICK_PROCESS_LIST_FILTERS = {
    [GA_PROCESS_LIST_FILTERS.CONTRACT]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.CONTRACT },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.CONTRACT },
    },
    [GA_PROCESS_LIST_FILTERS.CLIENT]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.CLIENT },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.CLIENT },
    },
    [GA_PROCESS_LIST_FILTERS.PARTNER]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.PARTNER },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.PARTNER },
    },
    [GA_PROCESS_LIST_FILTERS.CITY]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.CITY },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.CITY },
    },
    [GA_PROCESS_LIST_FILTERS.PROCESS_STATE]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.PROCESS_STATE },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.PROCESS_STATE },
    },
    [GA_PROCESS_LIST_FILTERS.PRODUCT]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.PRODUCT },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.PRODUCT },
    },
    [GA_PROCESS_LIST_FILTERS.BUSINESS_MODEL]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.BUSINESS_MODEL },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.BUSINESS_MODEL },
    },
    [GA_PROCESS_LIST_FILTERS.PROPOSAL_GENERATOR]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.PROPOSAL_GENERATOR },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.PROPOSAL_GENERATOR },
    },
    [GA_PROCESS_LIST_FILTERS.LAST_UPDATED_FROM]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.LAST_UPDATED_FROM },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.LAST_UPDATED_FROM },
    },
    [GA_PROCESS_LIST_FILTERS.LAST_UPDATED_UNTIL]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.LAST_UPDATED_UNTIL },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.LAST_UPDATED_UNTIL },
    },
    [GA_PROCESS_LIST_FILTERS.SERVICE_MANAGER]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.SERVICE_MANAGER },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.SERVICE_MANAGER },
    },
    [GA_PROCESS_LIST_FILTERS.SALES_OPERATION_MANAGER]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.SALES_OPERATION_MANAGER },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.SALES_OPERATION_MANAGER },
    },
    [GA_PROCESS_LIST_FILTERS.SHOW_FILTERS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.SHOW_FILTERS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.SHOW_FILTERS },
    },
    [GA_PROCESS_LIST_FILTERS.APPLY_FILTERS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.APPLY_FILTERS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.APPLY_FILTERS },
    },
    [GA_PROCESS_LIST_FILTERS.CLEAN_FILTERS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.CLEAN_FILTERS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.CLEAN_FILTERS },
    },
    [GA_PROCESS_LIST_FILTERS.REFRESH_TABLE_DATA]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.REFRESH_TABLE_DATA },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.REFRESH_TABLE_DATA },
    },
    [GA_PROCESS_LIST_FILTERS.SEARCH_BAR]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.SEARCH_BAR },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Filters', 'event_label': GA_PROCESS_LIST_FILTERS.SEARCH_BAR },
    },
}

export const GA_PROCESS_LIST = {
    PROCESS_DETAILS: 'Process Details',
    DOWNLOAD_DOCS: 'Download Docs'
}

export const GA_EVT_CLICK_PROCESS_LIST = {
    [GA_PROCESS_LIST.PROCESS_DETAILS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List', 'event_label': GA_PROCESS_LIST.PROCESS_DETAILS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List', 'event_label': GA_PROCESS_LIST.PROCESS_DETAILS },
    },
    [GA_PROCESS_LIST.DOWNLOAD_DOCS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List', 'event_label': GA_PROCESS_LIST.DOWNLOAD_DOCS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List', 'event_label': GA_PROCESS_LIST.DOWNLOAD_DOCS },
    },
}

export const GA_PROCESS_LIST_PAGINATION = {
    NEXT_PAGE: 'Next Page',
    PREVIOUS_PAGE: 'Previous Page',
    BEGINNING: 'Beginning',
    END: 'End',
    PROCESSES_PER_PAGE: 'Processes Per Page'
}

export const GA_EVT_CLICK_PROCESS_LIST_PAGINATION = {
    [GA_PROCESS_LIST_PAGINATION.NEXT_PAGE]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.NEXT_PAGE },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.NEXT_PAGE },
    },
    [GA_PROCESS_LIST_PAGINATION.PREVIOUS_PAGE]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.PREVIOUS_PAGE },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.PREVIOUS_PAGE },
    },
    [GA_PROCESS_LIST_PAGINATION.BEGINNING]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.BEGINNING },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.BEGINNING },
    },
    [GA_PROCESS_LIST_PAGINATION.END]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.END },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.END },
    },
    [GA_PROCESS_LIST_PAGINATION.PROCESSES_PER_PAGE]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.PROCESSES_PER_PAGE },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process List Pagination', 'event_label': GA_PROCESS_LIST_PAGINATION.PROCESSES_PER_PAGE },
    },
}

export const GA_DASHBOARD_FILTERS = {
    SERVICE_MANAGER: 'Service Manager'
}

export const GA_EVT_CLICK_DASHBOARD_FILTERS = {
    [GA_DASHBOARD_FILTERS.SERVICE_MANAGER]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Dashboard - Filters', 'event_label': GA_DASHBOARD_FILTERS.SERVICE_MANAGER },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Dashboard - Filters', 'event_label': GA_DASHBOARD_FILTERS.SERVICE_MANAGER },
    },
}

export const GA_DASHBOARD_CALENDAR = {
    CALENDAR: 'Calendar'
}

export const GA_EVT_CLICK_DASHBOARD_CALENDAR = {
    [GA_DASHBOARD_CALENDAR.CALENDAR]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Dashboard - Calendar', 'event_label': GA_DASHBOARD_CALENDAR.CALENDAR },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Dashboard - Calendar', 'event_label': GA_DASHBOARD_CALENDAR.CALENDAR },
    },
}

export const GA_PROCESS_DETAILS = {
    EDIT_CLIENT_DATA: 'Edit Client Data',
    EXPAND_FINANTIAL_INFO: 'Expand FInantial Info',
    COLLAPSE_FINANTIAL_INFO: 'Collapse Finantial Info',
    EXPAND_TECH_SUMMARY: 'Expand Tech Summary',
    COLLAPSE_TECH_SUMMARY: 'Collapse Tech Summary',
    EXPAND_TECH_DETAILS: 'Expand Tech Details',
    COLLAPSE_TECH_DETAILS: 'Collapse Tech Details'
}

export const GA_EVT_CLICK_PROCESS_DETAILS = {
    [GA_PROCESS_DETAILS.EDIT_CLIENT_DATA]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.EDIT_CLIENT_DATA },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.EDIT_CLIENT_DATA },
    },
    [GA_PROCESS_DETAILS.EXPAND_FINANTIAL_INFO]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.EXPAND_FINANTIAL_INFO },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.EXPAND_FINANTIAL_INFO },
    },
    [GA_PROCESS_DETAILS.COLLAPSE_FINANTIAL_INFO]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.COLLAPSE_FINANTIAL_INFO },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.COLLAPSE_FINANTIAL_INFO },
    },
    [GA_PROCESS_DETAILS.EXPAND_TECH_DETAILS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.EXPAND_TECH_DETAILS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.EXPAND_TECH_DETAILS },
    },
    [GA_PROCESS_DETAILS.COLLAPSE_TECH_DETAILS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.COLLAPSE_TECH_DETAILS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.COLLAPSE_TECH_DETAILS },
    },
    [GA_PROCESS_DETAILS.EXPAND_TECH_SUMMARY]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.EXPAND_TECH_SUMMARY },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.EXPAND_TECH_SUMMARY },
    },
    [GA_PROCESS_DETAILS.COLLAPSE_TECH_SUMMARY]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.COLLAPSE_TECH_SUMMARY },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details', 'event_label': GA_PROCESS_DETAILS.COLLAPSE_TECH_SUMMARY },
    },
}

export const GA_PROCESS_DETAILS_TIMELINE = {
    CHANGE_STATUS: 'Change Status',
    DOWNLOAD_DOCS: 'Download Docs',
    SEND_SITE_SURVEY: 'Send Site Survey',
    SCHEDULE_SITE_SURVEY: 'Schedule Site Survey',
    RESCHEDULE_SITE_SURVEY: 'Reschedule Site Survey',
    SEND_BUDGET_DOCS: 'Send Budget Docs',
    INFO_SITE_SURVEY: 'Info Site Survey',
    RESEND_BUDGET_DOCS: 'Resend Budget Docs'
}

export const GA_EVT_CLICK_PROCESS_DETAILS_TIMELINE = {
    [GA_PROCESS_DETAILS_TIMELINE.CHANGE_STATUS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.CHANGE_STATUS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.CHANGE_STATUS },
    },
    [GA_PROCESS_DETAILS_TIMELINE.DOWNLOAD_DOCS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.DOWNLOAD_DOCS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.DOWNLOAD_DOCS },
    },
    [GA_PROCESS_DETAILS_TIMELINE.SEND_SITE_SURVEY]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.SEND_SITE_SURVEY },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.SEND_SITE_SURVEY },
    },
    [GA_PROCESS_DETAILS_TIMELINE.SCHEDULE_SITE_SURVEY]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.SCHEDULE_SITE_SURVEY },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.SCHEDULE_SITE_SURVEY },
    },
    [GA_PROCESS_DETAILS_TIMELINE.RESCHEDULE_SITE_SURVEY]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.RESCHEDULE_SITE_SURVEY },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS.RESCHEDULE_SITE_SURVEY },
    },
    [GA_PROCESS_DETAILS_TIMELINE.SEND_BUDGET_DOCS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.SEND_BUDGET_DOCS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.SEND_BUDGET_DOCS },
    },
    [GA_PROCESS_DETAILS_TIMELINE.INFO_SITE_SURVEY]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.INFO_SITE_SURVEY },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.INFO_SITE_SURVEY },
    },
    [GA_PROCESS_DETAILS_TIMELINE.RESEND_BUDGET_DOCS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.RESEND_BUDGET_DOCS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Timeline', 'event_label': GA_PROCESS_DETAILS_TIMELINE.RESEND_BUDGET_DOCS },
    },
}

export const GA_PROCESS_DETAILS_EDIT_CLIENT_DATA = {
    SALES_OPERATION_MANAGER: 'Sales Operation Manager',
    SERVICE_MANAGER: 'Service Manager',
    EMAIL_SERVICE_MANAGER: 'Email Service Manager'
}

export const GA_EVT_CLICK_PROCESS_DETAILS_EDIT_CLIENT_DATA = {
    [GA_PROCESS_DETAILS_EDIT_CLIENT_DATA.SALES_OPERATION_MANAGER]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Client Data', 'event_label': GA_PROCESS_DETAILS_EDIT_CLIENT_DATA.SALES_OPERATION_MANAGER },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Client Data', 'event_label': GA_PROCESS_DETAILS_EDIT_CLIENT_DATA.SALES_OPERATION_MANAGER },
    },
    [GA_PROCESS_DETAILS_EDIT_CLIENT_DATA.SERVICE_MANAGER]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Client Data', 'event_label': GA_PROCESS_DETAILS_EDIT_CLIENT_DATA.SERVICE_MANAGER },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Client Data', 'event_label': GA_PROCESS_DETAILS_EDIT_CLIENT_DATA.SERVICE_MANAGER },
    },
    [GA_PROCESS_DETAILS_EDIT_CLIENT_DATA.EMAIL_SERVICE_MANAGER]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Client Data', 'event_label': GA_PROCESS_DETAILS_EDIT_CLIENT_DATA.EMAIL_SERVICE_MANAGER },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Client Data', 'event_label': GA_PROCESS_DETAILS_EDIT_CLIENT_DATA.EMAIL_SERVICE_MANAGER },
    },
}

export const GA_PROCESS_DETAILS_EDIT_TECH_DETAILS = {
    EDIT_TECH_DETAILS: 'Edit Tech Details',
    PANEL_NO: 'Panel No.',
    PANEL_COMPOSITION: 'Panel Composition',
    INVERTERS_COMPOSITION: 'Inverters Composition',
    PANEL_AREA: 'Panel Area',
    PEAK_POWER_PER_PANEL: 'Peak Power Per Panel',
    NO_POWER_INVERTERS: 'No. Power Inverters',
    ANNUAL_PRODUCTION: 'Annual Production',
    ORIENTATION_SLOPE: 'Orientation Slope',
    STRUCTURE: 'Structure',
    PREVENTIVE_MAINTENANCE: 'Preventive Maintenance'
}

export const GA_EVT_CLICK_PROCESS_DETAILS_EDIT_TECH_DETAILS = {
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.EDIT_TECH_DETAILS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.EDIT_TECH_DETAILS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.EDIT_TECH_DETAILS },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PANEL_NO]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PANEL_NO },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PANEL_NO },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PANEL_COMPOSITION]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PANEL_COMPOSITION },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PANEL_COMPOSITION },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.INVERTERS_COMPOSITION]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.INVERTERS_COMPOSITION },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.INVERTERS_COMPOSITION },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PANEL_AREA]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PANEL_AREA },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PANEL_AREA },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PEAK_POWER_PER_PANEL]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PEAK_POWER_PER_PANEL },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PEAK_POWER_PER_PANEL },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.NO_POWER_INVERTERS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.NO_POWER_INVERTERS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.NO_POWER_INVERTERS },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.ANNUAL_PRODUCTION]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.ANNUAL_PRODUCTION },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.ANNUAL_PRODUCTION },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.ORIENTATION_SLOPE]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.ORIENTATION_SLOPE },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.ORIENTATION_SLOPE },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.STRUCTURE]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.STRUCTURE },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.STRUCTURE },
    },
    [GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PREVENTIVE_MAINTENANCE]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PREVENTIVE_MAINTENANCE },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Edit Tech Details', 'event_label': GA_PROCESS_DETAILS_EDIT_TECH_DETAILS.PREVENTIVE_MAINTENANCE },
    },
}

export const GA_PROCESS_DETAILS_INFO_SITE_SURVEY = {
    REAL_COST_EQUIPMENT: 'Real Cost Equipment',
    DOWNLOAD_DOCS: 'Download Docs'
}

export const GA_EVT_CLICK_PROCESS_DETAILS_INFO_SITE_SURVEY = {
    [GA_PROCESS_DETAILS_INFO_SITE_SURVEY.REAL_COST_EQUIPMENT]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Info Site Survey', 'event_label': GA_PROCESS_DETAILS_INFO_SITE_SURVEY.REAL_COST_EQUIPMENT },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Info Site Survey', 'event_label': GA_PROCESS_DETAILS_INFO_SITE_SURVEY.REAL_COST_EQUIPMENT },
    },
    [GA_PROCESS_DETAILS_INFO_SITE_SURVEY.DOWNLOAD_DOCS]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Process Details - Info Site Survey', 'event_label': GA_PROCESS_DETAILS_INFO_SITE_SURVEY.DOWNLOAD_DOCS },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Process Details - Info Site Survey', 'event_label': GA_PROCESS_DETAILS_INFO_SITE_SURVEY.DOWNLOAD_DOCS },
    },
}

export const GA_HOMEPAGE = {
    SELECT_CLIENT_BUTTON: 'Select Client Button',
    PROCESSES_LIST: 'Processes List',
    DASHBOARD: 'Dashboard'
}

export const GA_EVT_CLICK_HOMEPAGE = {
    [GA_HOMEPAGE.SELECT_CLIENT_BUTTON]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Homepage', 'event_label': GA_HOMEPAGE.SELECT_CLIENT_BUTTON },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Homepage', 'event_label': GA_HOMEPAGE.SELECT_CLIENT_BUTTON },
    },
    [GA_HOMEPAGE.PROCESSES_LIST]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Homepage', 'event_label': GA_HOMEPAGE.PROCESSES_LIST },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Homepage', 'event_label': GA_HOMEPAGE.PROCESSES_LIST },
    },
    [GA_HOMEPAGE.DASHBOARD]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Homepage', 'event_label': GA_HOMEPAGE.DASHBOARD },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Homepage', 'event_label': GA_HOMEPAGE.DASHBOARD },
    },
}

export const GA_NAVIGATION = {
    LOGO_HOMEPAGE: 'Logo Homepage',
    PROCESSES: 'Processes',
    DASHBOARD: 'Dashboard',
    LANGUAGE_SELECTOR: 'Language Selector',
    PROFILE: 'Profile'
}

export const GA_EVT_CLICK_NAVIGATION = {
    [GA_NAVIGATION.LOGO_HOMEPAGE]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.LOGO_HOMEPAGE },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.LOGO_HOMEPAGE },
    },
    [GA_NAVIGATION.PROCESSES]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.PROCESSES },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.PROCESSES },
    },
    [GA_NAVIGATION.DASHBOARD]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.DASHBOARD },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.DASHBOARD },
    },
    [GA_NAVIGATION.LANGUAGE_SELECTOR]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.LANGUAGE_SELECTOR },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.LANGUAGE_SELECTOR },
    },
    [GA_NAVIGATION.PROFILE]: {
        [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.PROFILE },
        [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Navigation', 'event_label': GA_NAVIGATION.PROFILE },
    },
}

export const GA_LOGIN = {
    MANAGER: 'Manager',
    PARTNER: 'Partner'
}

export const GA_EVT_LOGIN = {
    [USERS_TYPE_ID.MANAGER]: { 'event_category': 'Login', 'event_label': GA_LOGIN.MANAGER },
    [USERS_TYPE_ID.PARTNER]: { 'event_category': 'Login', 'event_label': GA_LOGIN.PARTNER, },
}
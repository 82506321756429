import { getApiTheme } from "api/user";
import UserContext from "contexts/userContext";
import { IUsersContext } from "interfaces/users";
import { useContext, useEffect, useState } from "react";
import { isSuccessfulResponse } from "services/statusCodeHttp";
import { isDefined } from "services/util/auxiliaryUtils";
import defaultTheme from "themes/defaultTheme";


const useAPITheme = () => {

    const { userID, userTypeID } = useContext(UserContext) as IUsersContext;

    const [theme, setTheme] = useState(defaultTheme)

    const fetchApiTheme = async () => {
        if(isDefined(userTypeID)) {
            const themeResponse = await getApiTheme()
            if (isSuccessfulResponse(themeResponse?.status)) {
                const filteredResponse = {
                    // description: themeResponse?.data?.data?.description,
                    palette: themeResponse?.data?.data?.palette,
                    typography: themeResponse?.data?.data?.typography,
                    status: defaultTheme.status,
                    logo: {
                        name: themeResponse?.data?.data?.description,
                        src: themeResponse?.data?.data?.logo_file_name
                    }
                }
                setTheme(filteredResponse)
            }
        }
    }

    useEffect(()=>{
        fetchApiTheme()
    },[userID])//eslint-disable-line

    return (
        {
            theme
        }
    )
}

export default useAPITheme;
import { useState } from 'react';
import { toast } from "react-toastify";
import { StatusCodes } from 'http-status-codes';
import { getResetPassword, putRedefinePassword } from 'api/user';
import IntlMessages from "components/util/IntlMessages";
import useNotifications from 'hooks/notifications';
import { intlMessages } from 'services/util/auxiliaryUtils';

/**
 * Hook for handling recover password methods
 * @returns 
 */
const useRecoveryPassword = () => {
    const { toastNotify } = useNotifications();
    const [isRequesting, setIsRequesting] = useState(false);
    const [isRequestingRedefinePwd, setIsRequestingRedefinePwd] = useState(false);
    const [msgResetPassword, setMsgResetPassword] = useState(null);

    const { OK } = StatusCodes;

    /**
     * Ask for a new password
     * @param {string} email 
     */
    const fetchResetPassword = async (email) => {

        setMsgResetPassword(null);

        setIsRequesting(true);
        await getResetPassword(email);

        toastNotify('sucess', intlMessages("page.resetPwd.requestSuccess"));
        setMsgResetPassword('page.resetPwd.confirmYourEmail');
        setIsRequesting(false);
    }

    /**
     * Redefine Password when changed_pass is off from API
     * @param {function} setChangePass 
     * @param {string} userID 
     * @param {object} body 
     */
    const fetchRedefinePassword = async (setChangePass, userID, body) => {

        setIsRequestingRedefinePwd(true);
        const response = await putRedefinePassword(userID, body);

        if (response?.status === OK) {
            toast.success(<IntlMessages id='label.changeWasSuccessful' />);
            const tokenResponse = response?.data?.data?.token;
            updateToken(tokenResponse);
            setChangePass(true);
        }

        setIsRequestingRedefinePwd(false);
    }

    /**
     * Update Token on localStorage when redefined password
     * @param {string} token 
     */
    const updateToken = (token) => {
        localStorage.setItem('userToken', token);
    }


    return {
        fetchResetPassword,
        isRequesting,
        msgResetPassword,
        isRequestingRedefinePwd,
        fetchRedefinePassword
    }
}

export default useRecoveryPassword;

import { PRODUCT_IDS } from 'constants/product';
import { USERS_TYPE_ID } from 'constants/user';
import { COMPANY_IDS } from 'constants/settings';
import { PROCESS_DETAILS_INPUT_NAMES } from '.';
import { PAYMENT_METHOD_TAGS } from 'constants/businessModels';

export const PROCESS_DETAILS_SECTIONS = {
    GENERAL_INFORMATION: 'generalInformation',
    CLIENT_INFORMATION: 'clientInformation',
    FINANCIAL_INFORMATION: 'financialInformation',
    TECHNICAL_SUMMARY: 'technicalSummary',
    TECHNICAL_DETAILS: 'technicalDetails'
}

export const PROCESS_DETAILS_SECTIONS_CONFIGS = {
    [COMPANY_IDS.EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.RR]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                }
            },
            [PRODUCT_IDS.SPVSB]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                }
            },
            [PRODUCT_IDS.RR]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                }
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
    },
    [COMPANY_IDS.EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                }
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
    },
    [COMPANY_IDS.EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                }
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
    },
    [COMPANY_IDS.EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                }
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                }
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_SECTIONS.GENERAL_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.CLIENT_INFORMATION]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.FINANCIAL_INFORMATION]: {
                    visible: false
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_SUMMARY]: {
                    visible: true
                },
                [PROCESS_DETAILS_SECTIONS.TECHNICAL_DETAILS]: {
                    visible: true
                },
            }
        },
    },
}

export const GENERAL_INFORMATION_CONFIGS = {
    [COMPANY_IDS.EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN_REASON]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN_REASON]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN_REASON]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN_REASON]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP_TAG]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VYN_REASON]: {
                    visible: false
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.RR]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_STANDARD_REASON]: {
                    visible: false
                },
            }
        },
    },
    [COMPANY_IDS.EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP_TAG]: {
                    visible: false
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
            }
        },
    },
    [COMPANY_IDS.EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
            },
        },
    },
    [COMPANY_IDS.EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_VIP]: {
                    visible: true
                },
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
            },
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALESFORCE_ID]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROPOSAL_NUMBER]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.STATE_TAG]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PARTNER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.USER_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.EMAIL_PROPOSAL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_PANNEL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AGREED_DATE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SIGNATURE_DATE]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.UPDATE_AT]: {
                    visible: false
                },
            }
        },
    },
}

export const CLIENT_INFORMATION_CONFIGS = {
    [COMPANY_IDS.EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOT_NUMBER]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOT_NUMBER]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOT_NUMBER]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOT_NUMBER]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOT_NUMBER]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            }
        },
    },
    [COMPANY_IDS.EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            }
        },
    },
    [COMPANY_IDS.EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
        },
    },
    [COMPANY_IDS.EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_NAME]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.FISCAL_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_PHONE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTACT_EMAIL]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.ACTIVITY_SECTOR]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.EDP_CLIENT]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.VOLTAGE_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SALES_OPERATION_MANAGER_DESCRIPTION]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_NAME]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MANAGER_EMAIL]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LATIDUE]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.LONGITUDE]: {
                    visible: true
                },
            }
        },
    },
}

export const FINANTIAL_INFORMATION_CONFIGS_DEFAULTS = {
    [COMPANY_IDS.EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.RR]: {
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                }
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: false
                },
            }
        },
    },
    [COMPANY_IDS.EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: false
                },
            }
        },
    },
    [COMPANY_IDS.EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: true
                },
            },
        },
    },
    [COMPANY_IDS.EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: true
                },
            },
        }
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: false
                },
            },
            [PRODUCT_IDS.RR]: {
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                }
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                // OLD
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                    visible: false
                },
            }
        },
    },
}

export const FINANTIAL_INFORMATION_CONFIGS = {
    [COMPANY_IDS.EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE_SECOND_VERSION]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.LEC]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE_SECOND_VERSION]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.LEC]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.RR]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },

                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },

                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE_SECOND_VERSION]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },

                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
                [PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },

                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
                [PAYMENT_METHOD_TAGS.LEC]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
            },
            [PRODUCT_IDS.ME]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.PARTNERSHIP]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },                    
                    [PROCESS_DETAILS_INPUT_NAMES.COMPANY_REVENUES]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            }
        },
    },
    [COMPANY_IDS.EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE_SECOND_VERSION]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.EASY_SOLAR]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.LEC]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.ME]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            }
        },
    },
    [COMPANY_IDS.EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE_SECOND_VERSION]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.LEC]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.ME]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            }
        },
    },
    [COMPANY_IDS.EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE_SECOND_VERSION]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.LEC]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_MIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PROJECT_DURATION]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.ME]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            }
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE_SECOND_VERSION]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.LEC]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.ME]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            },
            [PRODUCT_IDS.RR]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },

                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },

                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE_SECOND_VERSION]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },

                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
                [PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },

                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
                [PAYMENT_METHOD_TAGS.LEC]: {
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },

                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PAYMENT_METHOD_TAGS.UP_FRONT]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.INSTALLMENTS]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
                [PAYMENT_METHOD_TAGS.SERVICE]: {
                    //#region Finantial
                    [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WP_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.COST]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                        visible: false,
                        changeAfterSurvey: true,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_PAYMENTS_NUMBER]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SERVICE_MONTHLY_FEE_SELECTED]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.BUSINESS_MODEL]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                        visible: false,
                        changeAfterSurvey: false,
                    },
                    //#endregion Finantial
                    //#region Technical
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                        visible: true,
                        changeAfterSurvey: false,
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                        visible: true,
                        changeAfterSurvey: true,
                    },
                    //#endregion Technical
                    // OLD
                    [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVING_SELECTED_COMPANY]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TARIFF_SELECTED]: {
                        visible: true
                    },
                    [PROCESS_DETAILS_INPUT_NAMES.TIR_NEW]: {
                        visible: true
                    },
                },
            }
        },
    },
}

export const TECHNICAL_SUMMARY_CONFIGS = {
    [COMPANY_IDS.EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                        max: {
                            value: 2500,
                            message: 'yup.dynamic.valuesBetween'
                        },
                        min: {
                            value: 0,
                            message: 'yup.dynamic.valuesBetween'
                        },
                    }
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_CONSUMPTION_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUM_TAKERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                        max: {
                            value: 2500,
                            message: 'yup.dynamic.valuesBetween'
                        },
                        min: {
                            value: 0,
                            message: 'yup.dynamic.valuesBetween'
                        },
                    }
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_CONSUMPTION_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUM_TAKERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_GROUPS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CHARGERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CABLE_METERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.OFERTA_ZERO]: {
                    visible: true,
                    changeAfterSurvey: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERIES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERY_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_GROUPS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CHARGERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CABLE_METERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.OFERTA_ZERO]: {
                    visible: true,
                    changeAfterSurvey: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERIES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERY_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            }
        },
    },
    [COMPANY_IDS.EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                    validation: {
                        required: "yup.message.required",
                    },
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                    validation: {
                        required: "yup.message.required",
                    },
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CHARGERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CABLE_METERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.OFERTA_ZERO]: {
                    visible: true,
                    changeAfterSurvey: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERIES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERY_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CHARGERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CABLE_METERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.OFERTA_ZERO]: {
                    visible: true,
                    changeAfterSurvey: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERIES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERY_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            }
        },
    },
    [COMPANY_IDS.EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
    },
    [COMPANY_IDS.EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERIES]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERY_POWER]: {
                    visible: true
                },
            }
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CHARGERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CABLE_METERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERIES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERY_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_GROUPS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANNELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER,
                    visible: true,
                    label: 'processDetails.label.batteryManufacturer',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL,
                    visible: true,
                    label: 'processDetails.label.batteryDescription',
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH,
                    visible: true,
                    unit: 'kwh',
                    label: 'processDetails.label.batteryCapacity',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.batteryPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW,
                    visible: true,
                    unit: 'kw',
                    label: 'processDetails.label.inverterPower',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryNrInverters',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    name: PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS,
                    visible: true,
                    label: 'processDetails.label.batteryHybridInverter',
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CHARGERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CABLE_METERS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERIES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERY_POWER]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_IMPLEMENTATION_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_GROUPS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
    },
}

export const TECHNICAL_DETAILS_CONFIGS = {
    [COMPANY_IDS.EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                // [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                //     visible: true,
                //     changeAfterSurvey: true,
                // },
                [PROCESS_DETAILS_INPUT_NAMES.NR_CARREGADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCIA_QUADRO_M]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCAL_INSTALAÇÃO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_FIXACAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GAMA]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARCA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODELO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_AC_KVA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_DC_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NR_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONFIG_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.RAPIDO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_BATERIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NECESSARIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_DE_CONDENSADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FILTRO_HARMONICOS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.RR]: {
                
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: false
                },
                // [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                //     visible: true,
                //     changeAfterSurvey: true,
                // },
                [PROCESS_DETAILS_INPUT_NAMES.NR_CARREGADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCIA_QUADRO_M]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCAL_INSTALAÇÃO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_FIXACAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GAMA]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARCA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODELO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_AC_KVA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_DC_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NR_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONFIG_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.RAPIDO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_BATERIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NECESSARIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_DE_CONDENSADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FILTRO_HARMONICOS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            }
        },
    },
    [COMPANY_IDS.EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ASBESTOS_PRESENCE]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GROUP_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GROUP_NAME]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID_GRUPO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_INSTALLED]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_REMOVED]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_THICKNESS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PERCENTAGE_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRESENCE_AGGRAVATION_1]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRESENCE_AGGRAVATION_2]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                // [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                //     visible: true,
                //     changeAfterSurvey: true,
                // },
                [PROCESS_DETAILS_INPUT_NAMES.NR_CARREGADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCIA_QUADRO_M]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCAL_INSTALAÇÃO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_FIXACAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GAMA]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARCA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODELO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_AC_KVA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_DC_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NR_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONFIG_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.RAPIDO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_BATERIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NECESSARIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_DE_CONDENSADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FILTRO_HARMONICOS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ASBESTOS_PRESENCE]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GROUP_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GROUP_NAME]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID_GRUPO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_INSTALLED]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_REMOVED]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_THICKNESS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PERCENTAGE_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRESENCE_AGGRAVATION_1]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRESENCE_AGGRAVATION_2]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: false
                },
                // [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                //     visible: true,
                //     changeAfterSurvey: true,
                // },
                [PROCESS_DETAILS_INPUT_NAMES.NR_CARREGADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCIA_QUADRO_M]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCAL_INSTALAÇÃO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_FIXACAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GAMA]: {
                    visible: false,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARCA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODELO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_AC_KVA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_DC_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NR_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONFIG_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.RAPIDO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_BATERIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NECESSARIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_DE_CONDENSADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FILTRO_HARMONICOS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            }
        },
    },
    [COMPANY_IDS.EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CHARGERS]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_CABLE_METERS]: {
                    visible: true
                },
            },
            [PRODUCT_IDS.CFP]: {
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERIES]: {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_BATTERY_POWER]: {
                    visible: true
                },
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
    },
    [COMPANY_IDS.EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: false
                },
                // [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                //     visible: true,
                //     changeAfterSurvey: true,
                // },
                [PROCESS_DETAILS_INPUT_NAMES.NR_CARREGADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCIA_QUADRO_M]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCAL_INSTALAÇÃO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_FIXACAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GAMA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARCA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODELO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_AC_KVA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_DC_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NR_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONFIG_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.RAPIDO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_BATERIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NECESSARIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_DE_CONDENSADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FILTRO_HARMONICOS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ASBESTOS_PRESENCE]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GROUP_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GROUP_NAME]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID_GRUPO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_INSTALLED]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_REMOVED]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_THICKNESS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PERCENTAGE_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRESENCE_AGGRAVATION_1]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRESENCE_AGGRAVATION_2]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.COMPOSICAO_INVERSORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NOMINAL_INVERSORES_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCAO_ANUAL_MWH]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VENDA_A_REDE]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.STRUCTURE_LEVELS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: false
                },
                // [PROCESS_DETAILS_INPUT_NAMES.PLATAFORMA_GESTAO]: {
                //     visible: true,
                //     changeAfterSurvey: true,
                // },
                [PROCESS_DETAILS_INPUT_NAMES.NR_CARREGADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCIA_QUADRO_M]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCAL_INSTALAÇÃO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_FIXACAO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GAMA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARCA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODELO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_AC_KVA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_DC_KW]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NR_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONFIG_TOMADAS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.RAPIDO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_BATERIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_NECESSARIA_KVAR]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIPO_DE_CONDENSADORES]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FILTRO_HARMONICOS]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
            [PRODUCT_IDS.RR]: {
                'edit': {
                    visible: false
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ASBESTOS_PRESENCE]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GROUP_AREA]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.GROUP_NAME]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID_GRUPO]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_INSTALLED]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_REMOVED]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_THICKNESS]: {
                    visible: true,
                    changeAfterSurvey: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MATERIAL_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PERCENTAGE_TRANSLUCID]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRESENCE_AGGRAVATION_1]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRESENCE_AGGRAVATION_2]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: true,
                    changeAfterSurvey: true,
                },
            },
        },
    },
}


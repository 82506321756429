import { ProcessesActions } from "constants/processesV2";
import { IProcessesState } from "interfaces/processes";
import { IProcessesReducerAction } from "interfaces/processes/processesReducer";

export const initialProcessesReducer: IProcessesState = {
    processes: [],
    totalProcesses: '',
    hasAssetManagement: false,
    isLoading: true,
    isMounting: true
}

export const processesReducer = (state = initialProcessesReducer, action: IProcessesReducerAction) => {
    switch(action.type) {
        case ProcessesActions.SET_PROCESSES:
            return {
                ...state,
                processes: action?.payload?.processes,
                totalProcesses: action?.payload?.total,
                isMounting: false
            }
        case ProcessesActions.SET_HAS_ASSET_MANAGEMENT: 
            return {
                ...state,
                hasAssetManagement: action?.payload
            }
        case ProcessesActions.SET_IS_LOADING: 
            return {
                ...state,
                isLoading: action?.payload
            }
        default:
            return state
    }
}
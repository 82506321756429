import { memo } from 'react';
import PropTypes from 'prop-types';

//Material UI
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as ProcessesIcon } from 'assets/images/icons/svg/processes.svg';
import useTheme from '@mui/material/styles/useTheme';

/**
 * ProcessesCard
 * 
 * @param {*} param
 */
const ProcessesCard = ({
    handleOnClickGoTo,
    isThisPathActive
}) => {


    const theme = useTheme();

    return (
        <div className="menu-item-content" onClick={handleOnClickGoTo}>
            <span className={`me-lg-2 default-svg${isThisPathActive ? "--open" : ""}`}>
                <ProcessesIcon height={24} fill={theme.palette.primary.main} />
            </span>
            <div className="menu-item-text">
                <h5 className={`menu-item-label${isThisPathActive ? "--open" : ""}`}>
                    <IntlMessages id='label.processes' />
                </h5>
            </div>
        </div>
    );
}

//PropTypes
ProcessesCard.propTypes = {
    handleOnClickGoTo: PropTypes.func.isRequired,
};

export default memo(ProcessesCard);
import { useEffect } from 'react';

export const withTracker = (WrappedComponent, name) => {  
    const trackPage = (title) => {
        /* window.gtag('event', 'page_view', {
            'page_location': window.location.origin,
            'page_path': window.location.pathname,
            'page_title': title
        }); */
        // Matomo
        window._paq.push(['setDocumentTitle', title]);
        window._paq.push(['setCustomUrl', window.location.href]);
        window._paq.push(['trackPageView']);
        window._paq.push(['enableLinkTracking']);
    };

    const HOC = props => {
        let title = name;
        
        useEffect(() => {
            //console.log("page: ",title);
            trackPage(title);
        }, []); //eslint-disable-line

        return <WrappedComponent {...props} />;
    }

    return HOC;
}
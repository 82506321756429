import { AssetManagementActions, IAssetManagementContext } from "interfaces/assetManagement";
import { IReactNodeWithChildren } from "interfaces/utils";
import { createContext, useReducer } from "react";
import { assetManagementReducer, initialAssetManagementReducer } from "./assetManagementReducer";

const AssetManagementContext = createContext<IAssetManagementContext>({
    assetManagementState: initialAssetManagementReducer,
    assetManagementDispatchHandler: () => { }
})

const AssetManagementProvider: React.FC<IReactNodeWithChildren> = ({children}) => {

    const [assetManagementState, assetManagementDispatch] = useReducer(assetManagementReducer, initialAssetManagementReducer);

    const assetManagementDispatchHandler = (type: AssetManagementActions, payload?: any) => {
        assetManagementDispatch({
            type: type,
            payload: payload
        })
    }

    const assetManagementContextValues: IAssetManagementContext = { assetManagementState, assetManagementDispatchHandler }

    return (
        <AssetManagementContext.Provider value={assetManagementContextValues}>
            {children}
        </AssetManagementContext.Provider>
    )
}

export { AssetManagementContext, AssetManagementProvider }
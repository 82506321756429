import { lazy } from 'react';
import { connect } from "react-redux";
import lazyLoader from 'services/lazyLoader';
import { isUserFieldV2 } from 'services/user';
import { withTracker } from 'services/withTracker';

const ProcessDetailsContainer = lazy(() => lazyLoader(() => import("pages/Restricted/ProcessDetails")));
const ProcessDetailsContainerV2 = lazy(() => lazyLoader(() => import("pages/Restricted/ProcessDetails/indexV2")));


/**
 * @context Route to ProcessDetails
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProcessDetails = (props) => ( !isUserFieldV2(props.userTypeID) ? <ProcessDetailsContainer/> : <ProcessDetailsContainerV2/>);

// ProcessDetails.propTypes = {
// };


const mapStateToProps = () => ({
});

export default connect(mapStateToProps,
    {  }
)(withTracker(ProcessDetails, 'Process Details'));

import { ProcessesActions } from "constants/processesV2";
import { IProcessesContext } from "interfaces/processes";
import { IReactNodeWithChildren } from "interfaces/utils";
import { createContext, useReducer } from "react";
import { initialProcessesReducer, processesReducer } from "./processesReducer";

const ProcessesContext = createContext<IProcessesContext>({
    processesState: initialProcessesReducer,
    processesDispatchHandler: () => { }
})

const ProcessesProvider: React.FC<IReactNodeWithChildren> = ({children}) => {

    const [processesState, processesDispatch] = useReducer(processesReducer, initialProcessesReducer);

    const processesDispatchHandler = (type: ProcessesActions, payload?: any) => {
        processesDispatch({
            type: type,
            payload: payload
        })
    }

    const processesContextValues: IProcessesContext = { processesState, processesDispatchHandler }

    return (
        <ProcessesContext.Provider value={processesContextValues}>
            {children}
        </ProcessesContext.Provider>
    )
}

export { ProcessesContext, ProcessesProvider }
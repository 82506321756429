import { useState, createContext, useEffect, useContext } from 'react';
import useAuth from 'hooks/auth';
import { isTokenValid } from 'services/user';
import UserContext from './userContext';
import SettingsContext from './settingsContext';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const { setUser/* , userTypeID */ } = useContext(UserContext);
    const { switchLang } = useContext(SettingsContext);

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);

    const {
        loginHandler,
        getTokenInfo,
        logoutHandler,
    } = useAuth(
        setIsUserAuthenticated,
        setIsLoadingLogin,
        setUser,
        switchLang
    );

    // When user refresh, pages it checks the token on localStorange
    useEffect(() => {
        if (!!isTokenValid()) {
            const token = localStorage.getItem('userToken');
            setUser(getTokenInfo(token));
            setIsUserAuthenticated(true);
        }
    }, [isUserAuthenticated]) // eslint-disable-line 

    /* useEffect(() => {
        if (isDefined(userTypeID)) {
            window.gtag('set', 'User_Type_Efz', userTypeID);
        }
    }, [userTypeID]) */


    return (
        <AuthContext.Provider value={
            {
                loginHandler,
                logoutHandler,
                switchLang,
                isUserAuthenticated,
                isLoadingLogin
            }
        }>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;

import { memo } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { Button } from '@save2compete/efz-design-system';
import { OutlinedInput } from '@mui/material';

// Components
import IntlMessages from "components/util/IntlMessages";
import ErrorMessage from 'components/ErrorMessage';

// Services
import { EMAIL_REGEXP, intlMessages } from 'services/util/auxiliaryUtils';
import { Link } from 'react-router-dom';
import { BASENAME_URL } from 'constants/settings';

/**
 * 
 * @param {*} param0 
 */
const FormRecoverPassword = ({
    isRequesting,
    handleOnSubmit,
    msgResetPassword
}) => {


    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
        },
    });

    const onSubmit = payload => (handleOnSubmit(payload));


    return (
        <div>
            <form autoComplete={'false'} onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className="col-12">
                        <div className="d-flex flex-column mt-2">
                            {/* email */}
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "yup.message.required",
                                    pattern: {
                                        value: EMAIL_REGEXP,
                                        message: 'yup.message.email'
                                    }
                                }}
                                render={({ field }) => 
                                <OutlinedInput 
                                    {...field} 
                                    placeholder={intlMessages('yup.placeholder.emailExample')}
                                    className={`w-100`} 
                                    disabled={isRequesting}
                                />
                                }
                            />
                            {errors?.email && <span className="d-block w-100"><ErrorMessage error={errors.email} /></span>}
                            {!!msgResetPassword && <p className="text-center text-info p-0 m-0 mt-3"><IntlMessages id={msgResetPassword} /></p>}
                        </div>
                    </div>
                </div>
                {/*actions*/}
                <div className="mt-4 mb-3 d-flex justify-content-center">
                    <Button
                        datatype="formRecoverPassword-submit"
                        disabled={isRequesting}
                        variant="primary"
                        type="submit"
                    >
                        <IntlMessages id={'label.resetPassword'} />
                    </Button>
                </div>
            </form>

            {/*Go to Login*/}
            <div className="link-back-login d-flex justify-content-center">
                <h4>
                    <Link to={`${BASENAME_URL}login`} className={isRequesting ? 'disabled-cursor' : ''}>
                        <IntlMessages id='label.backLogin' />
                    </Link>
                </h4>
            </div>
        </div>
    )
}


FormRecoverPassword.propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    handleOnSubmit: PropTypes.func.isRequired,
    msgResetPassword: PropTypes.string
};

export default memo(FormRecoverPassword);
import { useState, useContext } from 'react';
import orderBy from 'lodash.orderby';

// Contexts
import FiltersContext from 'contexts/filtersContext';

// API
import { getNotStandardReasons, getSearchSalesOperationsManager, getVynReasons } from 'api/processes';
// import { StatusCodes } from 'http-status-codes';
import axios from 'axios';

/**
 * Custom Hook to control filters options and selection
 * @returns 
 */
const useDropdownContext = () => {
    const [isLoadingSOMFilter, setIsLoadingSOMFilter] = useState(false);
    const [isLoadingNotStandardReasons, setIsLoadingNotStandardReasons] = useState(false);

    const {
        processFilters,
        setProcessFilters,
        filtersOptions,
        setFiltersOptions
    } = useContext(FiltersContext);

    const fetchSOMOptions = async () => {
        setIsLoadingSOMFilter(true);
        const allSOM = await getSearchSalesOperationsManager(false);
        const activeSOM = await getSearchSalesOperationsManager(true);

        setFiltersOptions({
            ...filtersOptions,
            salesOperationsManager: orderBy(allSOM?.data?.data, ['description'], ['asc']),
            activeSalesOperationsManager: orderBy(activeSOM?.data?.data, ['description'], ['asc'])
        })
        setIsLoadingSOMFilter(false);
    }

    // const fetchNotStandardReasonsOptions = async () => {
    //     setIsLoadingNotStandardReasons(true)

    //     const response = await getNotStandardReasons()

    //     if (response?.status === StatusCodes.OK) {
    //         setFiltersOptions({
    //             ...filtersOptions,
    //             notStandardReasons: orderBy(response?.data?.data, ['description'], ['asc'])
    //         })
    //     }

    //     setIsLoadingNotStandardReasons(false)
    // }

    const fetchOptions = async () => {
        setIsLoadingNotStandardReasons(true)

        let allRequests = [
            getNotStandardReasons(),
            getVynReasons()
        ];

        const responses = await axios.all(allRequests);

        setFiltersOptions({
            ...filtersOptions,
            notStandardReasons: orderBy(responses[0]?.data?.data, ['description'], ['asc']),
            vynReasons: responses[1]?.data?.data
        });

        setIsLoadingNotStandardReasons(false)
    };

    return {
        salesOperationManagerFilters: filtersOptions?.salesOperationsManager ?? [],
        activeSalesOperationManagerFilters: filtersOptions?.activeSalesOperationsManager ?? [],
        notStandardReasonsOptions: filtersOptions?.notStandardReasons ?? [],
        vynReasonsOptions: filtersOptions?.vynReasons ?? [],
        fetchSOMOptions,
        fetchOptions,
        isLoadingSOMFilter,
        isLoadingNotStandardReasons,
        processFilters,
        setProcessFilters
    }
}

export default useDropdownContext;
import { useState, createContext, useEffect } from 'react';
import { INITIAL_USER } from 'constants/user';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

const UserContext = createContext({});

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState(INITIAL_USER);
    const [hasChangedPass, setHasChangedPass] = useState(true);

    /**
     * Check if there user has changed the password or not after forgot password
     */
    useEffect(() => {
        if (isFieldDefined(user?.changed_password)) {
            setHasChangedPass(user?.changed_password);
        }
    }, [user]); // eslint-disable-line

    return (
        <UserContext.Provider value={
            {
                userID: user.id,
                userTypeID: user.profile_id,
                userCompanyID: user.company_id,
                userUsername: user.username,
                currency: user.currency.code,
                symbol: user.currency.symbol,
                language: user.language,
                locale: user?.locale ?? 'pt-PT',
                setUser,
                hasChangedPass,
                setHasChangedPass
            }
        }>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
import { IFacility } from "interfaces/facility";

export const INITIAL_FACILITY: IFacility = {
    id: "",
    cliente_id: "",
    morada: "",
    location: {
        address: ""
    },
    cpe: "",
    total_tariffs: []
}
import PropTypes from 'prop-types';

// Material UI
import { IconButton } from '@save2compete/efz-design-system';

const CookieHeader = ({ 
    title,
    close
}) => {

    return (
        <div className='cookie-consent-header'>
            <IconButton
                dataTestId="cookie-consent-header-close"
                className='close-cookie'
                onClick={() => close()} 
                icon="xMarkExit"
                variant="tertiary"
            />
            <span>
                {title}
            </span>
        </div>
    )
}

CookieHeader.propTypes = {
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
}

export default CookieHeader;
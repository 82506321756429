import { COMPANY_IDS } from "./settings"

export const INPUT_NAMES = {
    CONTRACT_ID: 'contract_id',
    CLIENT_NAME: 'client_name',
    PARTNER_NAME: 'partner_name',
    PARTNER_ID: 'partner_ids',
    CITY_FILTER: 'city_filter',
    STATE_ID: 'state_ids',
    PRODUCT_TYPE_ID: 'product_type_ids',
    FROM_UPDATED_AT: 'from_updated_at',
    TO_UPDATED_AT: 'to_updated_at',
    USER_NAME: 'user_name',
    SERVICE_MANAGER: 'service_managers',
    SERVICE_OPERATION_MANAGERS: 'service_operation_managers' ,
    PAYMENT_METHODS: 'payment_methods',
    BUSINESS_MODEL_IDS: 'business_model_ids',
    HAS_BATTERY: 'has_battery',
    REASON_IDS: 'reason_ids'
}

export const PROCESSES_FILTERS_INPUTS = {
    [INPUT_NAMES.CONTRACT_ID] : {
        name: INPUT_NAMES.CONTRACT_ID,
        label: `page.processes.filters.contractID`,
        placeholder: `page.processes.filters.contractID.placeholder`
    },
    [INPUT_NAMES.CLIENT_NAME] : {
        name: INPUT_NAMES.CLIENT_NAME,
        label: `page.processes.filters.clientName`,
        placeholder: `page.processes.filters.clientName.placeholder`
    },
    [INPUT_NAMES.PARTNER_ID] : {
        name: INPUT_NAMES.PARTNER_ID,
        label: `page.processes.filters.partnerName`,
        placeholder: `page.processes.filters.partnerName.placeholder`
    },
    [INPUT_NAMES.CITY_FILTER] : {
        name: INPUT_NAMES.CITY_FILTER,
        label: `page.processes.filters.cityFilter`,
        placeholder: `page.processes.filters.cityFilter.placeholder`
    },
    [INPUT_NAMES.STATE_ID] : {
        name: INPUT_NAMES.STATE_ID,
        label: `page.processes.filters.stateName`,
        placeholder: `page.processes.filters.stateName.placeholder`
    },
    [INPUT_NAMES.PRODUCT_TYPE_ID] : {
        name: INPUT_NAMES.PRODUCT_TYPE_ID,
        label: `page.processes.filters.product`,
        placeholder: `page.processes.filters.product.placeholder`
    },
    [INPUT_NAMES.PAYMENT_METHODS] : {
        name: INPUT_NAMES.PAYMENT_METHODS,
        label: `processDetails.label.payment_methods_description`,
        placeholder: `label.placeholder.selectOption`,
        optionsLabel: `product.typeBusinessModel.`
    },
    [INPUT_NAMES.FROM_UPDATED_AT] : {
        name: INPUT_NAMES.FROM_UPDATED_AT,
        label: `page.processes.filters.fromUpdatedAt`,
        placeholder: `page.processes.filters.updatedAt.placeholder`
    },
    [INPUT_NAMES.TO_UPDATED_AT] : {
        name: INPUT_NAMES.TO_UPDATED_AT,
        label: `page.processes.filters.toUpdatedAt`,
        placeholder: `page.processes.filters.updatedAt.placeholder`
    },
    [INPUT_NAMES.USER_NAME] : {
        name: INPUT_NAMES.USER_NAME,
        label: `page.processes.filters.userName`,
        placeholder: `page.processes.filters.userName.placeholder`
    },
    [INPUT_NAMES.SERVICE_MANAGER] : {
        name: INPUT_NAMES.SERVICE_MANAGER,
        label: `page.processes.filters.serviceManager`,
        placeholder: `label.placeholder.selectOption`
    },
    [INPUT_NAMES.SERVICE_OPERATION_MANAGERS] : {
        name: INPUT_NAMES.SERVICE_OPERATION_MANAGERS,
        label: `processDetails.label.sales_operation_manager_description`,
        placeholder: `label.placeholder.selectOption`
    },
    [INPUT_NAMES.REASON_IDS] : {
        name: INPUT_NAMES.REASON_IDS,
        label: `processDetails.label.reason`,
        placeholder: `label.placeholder.selectOption`
    },
}

export const COMPANIES_WITH_SOLAR_BATTERIES = [
    COMPANY_IDS.EDP_PT,
    COMPANY_IDS.EDP_ES
]
import { PRODUCT_IDS } from "constants/product"
import { COMPANY_IDS } from "constants/settings"
import { USERS_TYPE_ID } from "constants/user"

export enum ProcessDetailsActions {
    SET_PROCESS_DETAILS = 'SET_PROCESS_DETAILS',
    CLEAN_UP_PROCESS_DETAILS = 'CLEAN_UP_PROCESS_DETAILS',
    SET_GENERAL_INFORMATION = 'SET_GENERAL_INFORMATIONS'
}

export enum ProcessDetailsButtons {
    EDIT_TECHNICAL_SUMMARY = 'EDIT_TECHNICAL_SUMMARY',
    EDIT_TECHNICAL_DETAILS = 'EDIT_TECHNICAL_DETAILS',
    EDIT_FINANCIAL_INFO = 'EDIT_FINANCIAL_INFO'
}

export type TDefaultPropsMap = {
    mapOptions: {
        id: string,
        zoom: number,
        tilt: number,
        mapType: string,
        streetViewControl: boolean,
        rotateControl: boolean,
        disableDefaultUI: boolean,
        mapTypeControl: boolean,
        fullscreenControl: boolean,
        zoomControl: boolean,
        mapTypeId: string,
        dragable: boolean,
        minZoom: number,
        maxZoom: number,
        clickableIcons: boolean,
        gestureHandling: string
    },
    settings: {
        apiKey: string,
        libraries: Array<string>,
        version: string,
        language: string,
    }
}

export const PROCESS_DETAILS_DEFAULT_PROPS_MAP: TDefaultPropsMap = {
        mapOptions: {
            id: 'efz-map',
            zoom:19,
            minZoom:19,
            maxZoom: 19,
            tilt: 0,
            mapType: 'satellite',
            streetViewControl: false,
            rotateControl: true,
            disableDefaultUI: true,
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: false,
            dragable: false,
            mapTypeId: 'hybrid',
            clickableIcons: false,
            gestureHandling: 'none',
        },
        settings: {
            apiKey: process.env.REACT_APP_GMAP_API_KEY as string,
            libraries: ["places"],
            version: "3.30",
            language: "en",
        }
}

export const INITIAL_PROCESS_DETAILS = {
    active: false,
    business_model_description: '',
    business_model_id: 0,
    client_id: 0,
    client_info: {
        client_name: 'initial_name',
        contact_email: null,
        contact_name: 'initial_name',
        contact_phone: 'initial_phone',
        fiscal_number: 'initial_number',
        id: 'initial_id'
    },
    operational: false,
    has_asset_view: false,
    has_process_view: true,
    company_id: 0,
    contract_id: 0,
    contract_signed: false,
    current_state_id: 0,
    customer_signed_date: null,
    facility_id: 0,
    facility_info: {
        address: '',
        average_tariff: 0,
        contracted_power: 0,
        cpe: '',
        district: '',
        id: '',
        installed_power: 0,
        latitude: 0,
        longitude: 0,
        municipality: '',
        postal_code: '',
        required_power: 0,
        voltage_level: '',
        map_view: {
            latitude: 0,
            longitude: 0,
        }
    },
    field_tech_name: null,
    financial_information: {
        anual_price: 0,
        estimated_price: '',
        estimated_specific_price: '',
        margin: 0,
        max_additional_cost: '',
        monthly_fee_selected: '',
        monthly_payments_number: '',
        payment_model: '',
        process_cost: '',
        savings: '',
        share_savings: '',
        tariff: '',
        tir: '',
        tir_wacc: '',
        wacc: ''
    },
    id: 0,
    partner_id: null,
    product_description: '',
    product_id: 1,
    state_description: '',
    state_transition: [],
    technical_details: [{
        pos: {
            area_paineis_grupo_paineis: null,
            area_paineis_m2: null,
            autoconsumo_anual_MWh: null,
            battery_capacity_kwh: null,
            battery_charge_efficiency_perc: null,
            battery_degradation_year_perc: null,
            battery_description: null,
            battery_discharge_efficiency_perc: null,
            battery_dod_perc: null,
            battery_inverter_description: null,
            battery_inverter_is_hybrid: null,
            battery_inverter_manufacturer: null,
            battery_inverter_power_kw: null,
            battery_manufacturer: null,
            battery_nr_of_inverters: null,
            battery_power_kw: null,
            bundle_injection_decrease_kwh: null,
            bundle_injection_decrease_perc: null,
            composicao_inversores: null,
            composicao_paineis: null,
            consumo_anual_MWh: null,
            'custo_€': null,
            duracao_manutencao: null,
            has_battery: null,
            id_estrutura_grupo_paineis: null,
            id_grupo_paineis: null,
            inclinacao_graus_grupo_paineis: null,
            injecao_anual_MWh: null,
            injecao_prc: null,
            numero_paineis: null,
            numero_paineis_grupo_paineis: null,
            orientacao_graus_grupo_paineis: null,
            potencia_nominal_inversores_kW: null,
            potencia_pico_kwp_grupo_paineis: null,
            potencia_pico_por_painel_Wp: null,
            potencia_pico_total_kWp: null,
            potencia_previa_inversores: null,
            potencia_previa_pico: null,
            producao_anual_MWh: null,
            reducao_consumo_prc: null,
            ref_inclinacao_graus: null,
            ref_orientacao_graus: null,
            ref_tem_estrutura: null,
            specific_production_reference: null,
            tag_estrutura: null,
            tarifa_equivalente_ac_prc: null,
            tarifa_equivalente_injecao_prc: null,
            'tarifa_media_€_kWh': null,
            val_aas_2_0: null,
            venda_a_rede: null
        },
        pre: {
            area_paineis_grupo_paineis: null,
            area_paineis_m2: null,
            autoconsumo_anual_MWh: null,
            battery_capacity_kwh: null,
            battery_charge_efficiency_perc: null,
            battery_degradation_year_perc: null,
            battery_description: null,
            battery_discharge_efficiency_perc: null,
            battery_dod_perc: null,
            battery_inverter_description: null,
            battery_inverter_is_hybrid: null,
            battery_inverter_manufacturer: null,
            battery_inverter_power_kw: null,
            battery_manufacturer: null,
            battery_nr_of_inverters: null,
            battery_power_kw: null,
            bundle_injection_decrease_kwh: null,
            bundle_injection_decrease_perc: null,
            composicao_inversores: null,
            composicao_paineis: null,
            consumo_anual_MWh: null,
            'custo_€': null,
            duracao_manutencao: null,
            has_battery: null,
            id_estrutura_grupo_paineis: null,
            id_grupo_paineis: null,
            inclinacao_graus_grupo_paineis: null,
            injecao_anual_MWh: null,
            injecao_prc: null,
            numero_paineis: null,
            numero_paineis_grupo_paineis: null,
            orientacao_graus_grupo_paineis: null,
            potencia_nominal_inversores_kW: null,
            potencia_pico_kwp_grupo_paineis: null,
            potencia_pico_por_painel_Wp: null,
            potencia_pico_total_kWp: null,
            potencia_previa_inversores: null,
            potencia_previa_pico: null,
            producao_anual_MWh: null,
            reducao_consumo_prc: null,
            ref_inclinacao_graus: null,
            ref_orientacao_graus: null,
            ref_tem_estrutura: null,
            specific_production_reference: null,
            tag_estrutura: null,
            tarifa_equivalente_ac_prc: null,
            tarifa_equivalente_injecao_prc: null,
            'tarifa_media_€_kWh': null,
            val_aas_2_0: null,
            venda_a_rede: null
        }
    }],
    technical_summary: {
        pos: {
            annual_injection: null,
            annual_production: null,
            battery_capacity_kwh: null,
            battery_inverter_is_hybrid: null,
            battery_inverter_power_kw: null,
            battery_manufacturer: null,
            battery_model: null,
            battery_nr_of_inverters: null,
            battery_power_kw: null,
            inverter_composition: null,
            inverter_power: null,
            is_power_increase: null,
            maintenance_duration: null,
            panel_composition: null,
            previous_nominal_power: null,
            previous_peak_power: null,
            sell_grid: null,
            specific_production_reference: null,
            total_area: null,
            total_panel_number: null,
            total_peak_power: null
        },
        pre: {
            annual_injection: null,
            annual_production: null,
            battery_capacity_kwh: null,
            battery_inverter_is_hybrid: null,
            battery_inverter_power_kw: null,
            battery_manufacturer: null,
            battery_model: null,
            battery_nr_of_inverters: null,
            battery_power_kw: null,
            inverter_composition: null,
            inverter_power: null,
            is_power_increase: null,
            maintenance_duration: null,
            panel_composition: null,
            previous_nominal_power: null,
            previous_peak_power: null,
            sell_grid: null,
            specific_production_reference: null,
            total_area: null,
            total_panel_number: null,
            total_peak_power: null
        }
    },
    updated_at: ''
}

export enum StructuresNames {
    ROOF = 'ROOF',
    SOIL = 'SOIL',
    INCLINED = 'INCLINED', 
    FLAT = 'FLAT',
    EAST_WEST = 'EAST_WEST',
    CARPARK = 'CARPARK',
    GROUND = 'GROUND',
    STANDING_SEAM = 'STANDING_SEAM',
    COPLANAR = 'COPLANAR',
    SIMPLE_GROUND = 'SIMPLE_GROUND'
}

export const StructuresNamesArray = [
    StructuresNames.ROOF,
    StructuresNames.SOIL,
    StructuresNames.INCLINED,
    StructuresNames.FLAT,
    StructuresNames.EAST_WEST,
    StructuresNames.CARPARK,
    StructuresNames.GROUND,
    StructuresNames.STANDING_SEAM,
    StructuresNames.COPLANAR,
    StructuresNames.SIMPLE_GROUND
]

export const STRUCTURES_LOCATIONS = [
    {
        name: StructuresNames.ROOF,
        label: 'label.ROOF',
        value: StructuresNames.ROOF
    },
    {
        name: StructuresNames.SOIL,
        label: 'label.SOIL',
        value: StructuresNames.SOIL
    },
]

type TDISPOSITIONS_LOCATION = {
    [key: string]: {
        [key: string]: any
    }
}

export const DISPOSITIONS_LOCATION: TDISPOSITIONS_LOCATION = {
    [StructuresNames.ROOF]: {
        dispositions: [
            {
                name: StructuresNames.INCLINED,
                label: 'page.field2.0.label.INCLINED',
                value: StructuresNames.INCLINED,
            },
            {
                name: StructuresNames.FLAT,
                label: 'page.field2.0.label.FLAT',
                value: 'COPLANAR',
            },
            {
                name: StructuresNames.EAST_WEST,
                label: 'page.field2.0.label.EAST_WEST',
                value: StructuresNames.EAST_WEST,
            }
        ]
    },
    [StructuresNames.SOIL]: {
        disposition: [
            {
                name: StructuresNames.GROUND,
                label: 'page.field2.0.label.GROUND',
                value: StructuresNames.GROUND,
            },
            {
                name: StructuresNames.CARPARK,
                label: 'page.field2.0.label.CARPARK',
                value: StructuresNames.CARPARK,
            }
        ]
    }
}

export const PROCESS_DETAILS_INPUT_NAMES = {
    EDIT: 'edit',
    // General Information
    ID: 'id',
    CONTRACT_ID: 'contract_id',
    CONTRACT_SIGNED: 'contract_signed',
    CUSTOMER_SIGNED_DATE: 'customer_signed_date',
    PRODUCT_ID: 'product_id',
    PRODUCT_DESCRIPTION: 'product_description',
    // Client Data
    CLIENT_NAME: 'client_name',
    VAT: 'fiscal_number',
    REPRESENTATIVE: 'contact_name',
    REPRESENTATIVE_EMAIL: 'contact_email',
    REPRESENTATIVE_PHONE: 'contact_phone',
    // Facility Information
    ADDRESS: 'address',
    POSTAL_CODE: 'postal_code',
    LOCALITY: 'locality',
    MUNICIPALITY: 'municipality',
    DISTRICT: 'district',
    CPE: 'cpe',
    AVERAGE_TARIFF: 'average_tariff',
    VOLTAGE_LEVEL: 'voltage_level',
    CONTRACTED_POWER: 'contracted_power',
    REQUIRED_POWER: 'required_power',
    INSTALLED_POWER: 'installed_power',
    MAP_VIEW: 'map_view',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    // Financial Information
    PRICE: 'price',
    ANNUAL_PRICE: 'anual_price',
    ESTIMATED_PRICE: 'estimated_price',
    ESTIMATED_SPECIFIC_PRICE: 'estimated_specific_price',
    MARGIN: 'margin',
    MAX_ADDITIONAL_COST: 'max_additional_cost',
    MONTHLY_FEE_SELECTED: 'monthly_fee_selected',
    MONTHLY_PAYMENTS_NUMBER: 'monthly_payments_number',
    PAYMENT_MODEL: 'payment_model',
    PROCESS_COST: 'process_cost',
    SAVINGS: 'savings',
    SHARE_SAVINGS: 'share_savings',
    TARIFF: 'tariff',
    TIR: 'tir',
    TIR_WACC: 'tir_wacc',
    WACC: 'wacc',
    // Technical Summary 
    ANNUAL_INJECTION: 'annual_injection',
    ANNUAL_PRODUCTION: 'annual_production',
    BATTERY_CAPACITY_KWH: 'battery_capacity_kwh',
    BATTERY_INVERTER_IS_HYBRID: 'battery_inverter_is_hybrid',
    BATTERY_INVERTER_POWER_KW: 'battery_inverter_power_kw',
    BATTERY_MANUFACTURER: 'battery_manufacturer',
    BATTERY_MODEL: 'battery_model',
    BATTERY_NR_OF_INVERTERS: 'battery_nr_of_inverters',
    BATTERY_POWER_KW: 'battery_power_kw',
    INVERTER_COMPOSITION: 'inverter_composition',
    INVERTER_POWER: 'inverter_power',
    IS_POWER_INCREASE: 'is_power_increase',
    MAINTENANCE_DURATION: 'maintenance_duration',
    PANEL_COMPOSITION: 'panel_composition',
    PREVIOUS_NOMINAL_POWER: 'previous_nominal_power',
    PREVIOUS_PEAK_POWER: 'previous_peak_power',
    SELL_GRID: 'sell_grid',
    SPECIFIC_PRODUCTION_REFERENCE: 'specific_production_reference',
    TOTAL_AREA: 'total_area',
    TOTAL_PANEL_NUMBER: 'total_panel_number',
    TOTAL_PEAK_POWER: 'total_peak_power',
    TOTAL_N_CHARGERS: 'total_n_chargers',
    TOTAL_AC_POWER: 'total_ac_power',
    TOTAL_DC_POWER: 'total_dc_power',
    TOTAL_N_CABLE_METERS: 'total_n_cable_meters',
    MANAGEMENT_PLATFORM: 'management_platform',
    ZERO_OFFER: 'zero_offer',
    // Technical Details
    AREA_PAINEIS_GRUPO_PAINEIS: 'area_paineis_grupo_paineis',
    AREA_PAINEIS_M2: 'area_paineis_m2',
    AUTOCONSUMO_ANUAL_MWH: 'autoconsumo_anual_MWh',
    // BATTERY_CAPACITY_KWH: 'battery_capacity_kwh', // DUPLICATED
    BATTERY_CHARGE_EFFICIENCY_PERC: 'battery_charge_efficiency_perc',
    BATTERY_DEGRADATION_YEAR_PERC: 'battery_degradation_year_perc',
    BATTERY_DESCRIPTION: 'battery_description',
    BATTERY_DISCHARGE_EFFICIENCY_PERC: 'battery_discharge_efficiency_perc',
    BATTERY_DOD_PERC: 'battery_dod_perc',
    BATTERY_INVERTER_DESCRIPTION: 'battery_inverter_description',
    // BATTERY_INVERTER_IS_HYBRID: 'battery_inverter_is_hybrid', // DUPLICATED
    BATTERY_INVERTER_MANUFACTURER: 'battery_inverter_manufacturer', // DUPLICATED
    // BATTERY_INVERTER_POWER_KW: 'battery_inverter_power_kw',
    // BATTERY_MANUFACTURER: 'battery_manufacturer', // DUPLICATED
    // BATTERY_NR_OF_INVERTERS: 'battery_nr_of_inverters', // DUPLICATED
    // BATTERY_POWER_KW: 'battery_power_kw', // DUPLICATED
    BUNDLE_INJECTION_DECREASE_KWH: 'bundle_injection_decrease_kwh',
    BUNDLE_INJECTION_DECREASE_PERC: 'bundle_injection_decrease_perc',
    COMPOSICAO_INVERSORES: 'composicao_inversores',
    COMPOSICAO_PAINEIS: 'composicao_paineis',
    CONSUMO_ANUAL_MWH: 'consumo_anual_MWh',
    CUSTO_EUR: 'custo_€',
    DURACAO_MANUTENCAO: 'duracao_manutencao',
    HAS_BATTERY: 'has_battery',
    ID_ESTRUTURA_GRUPO_PAINEIS: 'id_estrutura_grupo_paineis',
    ID_GRUPO_PAINEIS: 'id_grupo_paineis',
    INCLINACAO_GRAUS_GRUPO_PAINEIS: 'inclinacao_graus_grupo_paineis',
    INJECAO_ANUAL_MWH: 'injecao_anual_MWh',
    INJECAO_PRC: 'injecao_prc',
    NUMERO_PAINEIS: 'numero_paineis',
    NUMERO_PAINEIS_GRUPO_PAINEIS: 'numero_paineis_grupo_paineis',
    ORIENTACAO_GRAUS_GRUPO_PAINEIS: 'orientacao_graus_grupo_paineis',
    POTENCIA_NOMINAL_INVERSORES_KW: 'potencia_nominal_inversores_kW',
    POTENCIA_PICO_KWP_GRUPO_PAINEIS: 'potencia_pico_kwp_grupo_paineis',
    POTENCIA_PICO_POR_PAINEL_WP: 'potencia_pico_por_painel_Wp',
    POTENCIA_PICO_TOTAL_KWP: 'potencia_pico_total_kWp',
    POTENCIA_PREVIA_INVERSORES: 'potencia_previa_inversores',
    POTENCIA_PREVIA_PICO: 'potencia_previa_pico',
    PRODUCAO_ANUAL_MWH: 'producao_anual_MWh',
    REDUCAO_CONSUMO_PRC: 'reducao_consumo_prc',
    REF_INCLINACAO_GRAUS: 'ref_inclinacao_graus',
    REF_ORIENTACAO_GRAUS: 'ref_orientacao_graus',
    REF_TEM_ESTRUTURA: 'ref_tem_estrutura',
    // SPECIFIC_PRODUCTION_REFERENCE: 'specific_production_reference', // DUPLICATED
    TAG_ESTRUTURA: 'tag_estrutura',
    TARIFA_EQUIVALENTE_AC_PRC: 'tarifa_equivalente_ac_prc',
    TARIFA_EQUIVALENTE_INJECAO_PRC: 'tarifa_equivalente_injecao_prc',
    TARIFA_MEDIA_EUR_KWH: 'tarifa_media_€_kWh',
    VAL_AAS_2_0: 'val_aas_2_0',
    VENDA_A_REDE: 'venda_a_rede',
    ORIENTACAO_INCLINACAO: 'orientacao_inclinacao',
    STRUCTURE_LOCATION: 'structure_location',
    STRUCTURE_DISPOSITION: 'structure_disposition',
    STRUCTURE_MATERIAL: 'structure_material',
    GROUP_INDEX: 'group_index',
    N_CHARGERS: 'n_chargers',
    DISTANCE_SWITCHBOARD: 'distance_switchboard',
    INSTALLATION_SITE: 'installation_site',
    FIXATION_SITE: 'fixation_site',
    TIER: 'tier',
    BRAND: 'brand',
    MODEL: 'model',
    AC_POWER: 'ac_power',
    DC_POWER: 'dc_power',
    N_PLUGS: 'n_plugs',
    PLUG_CONFIG: 'plug_config',
    FAST_CHARGING: 'fast_charging'
}

export const GENERAL_INFORMATION_CONFIG = {
    [COMPANY_IDS.EDP_UK]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.BASE_UK_B2B]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ_PPC]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    DEFAULT: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACT_ID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRODUCT_DESCRIPTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CUSTOMER_SIGNED_DATE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    }
}

export const CLIENT_DATA_CONFIG = {
    [COMPANY_IDS.EDP_UK]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.BASE_UK_B2B]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ_PPC]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    DEFAULT: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CLIENT_NAME]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.VAT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_EMAIL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REPRESENTATIVE_PHONE]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    }
}

export const FACILITY_INFORMATION_CONFIG = {
    [COMPANY_IDS.EDP_UK]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.BASE_UK_B2B]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ_PPC]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    DEFAULT: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ADDRESS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POSTAL_CODE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.LOCALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MUNICIPALITY]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTRICT]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CPE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AVERAGE_TARIFF]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.CONTRACTED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.REQUIRED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLED_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAP_VIEW]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    }
}

export const FINANCIAL_INFORMATION_CONFIG = {
    [COMPANY_IDS.EDP_UK]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.BASE_UK_B2B]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ_PPC]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    DEFAULT: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRICE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ESTIMATED_SPECIFIC_PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAX_ADDITIONAL_COST]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_FEE_SELECTED]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MONTHLY_PAYMENTS_NUMBER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SAVINGS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SHARE_SAVINGS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TARIFF]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIR_WACC]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PRICE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PROCESS_COST]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.WACC]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MARGIN]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PAYMENT_MODEL]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    }
}

export const TECHNICAL_SUMMARY_CONFIG = {
    [COMPANY_IDS.EDP_UK]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.BASE_UK_B2B]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ_PPC]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    DEFAULT: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_INJECTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ANNUAL_PRODUCTION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_CAPACITY_KWH]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_IS_HYBRID]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_INVERTER_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MANUFACTURER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_MODEL]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_NR_OF_INVERTERS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BATTERY_POWER_KW]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_COMPOSITION]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INVERTER_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.IS_POWER_INCREASE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MAINTENANCE_DURATION]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PANEL_COMPOSITION]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_NOMINAL_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PREVIOUS_PEAK_POWER]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SELL_GRID]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.SPECIFIC_PRODUCTION_REFERENCE]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AREA]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PANEL_NUMBER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_PEAK_POWER]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TOTAL_N_CABLE_METERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MANAGEMENT_PLATFORM]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ZERO_OFFER]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    }
}

export const TECHNICAL_DETAILS_CONFIG = {
    [COMPANY_IDS.EDP_UK]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.BASE_UK_B2B]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ_PPC]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: true,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {
            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
    DEFAULT: {
        [USERS_TYPE_ID.MANAGER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINEIS]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: true,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: true,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
        [USERS_TYPE_ID.PARTNER_V2]: {
            [PRODUCT_IDS.SPV]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.NUMERO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINEIS_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_POR_PAINEL_WP]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINEIS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_INCLINACAO]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TAG_ESTRUTURA]: {
                    visible: true,
                    editable: false,
                },
            },
            [PRODUCT_IDS.ME]: {
                [PROCESS_DETAILS_INPUT_NAMES.EDIT]: {
                    visible: false,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_CHARGERS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DISTANCE_SWITCHBOARD]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.INSTALLATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FIXATION_SITE]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.TIER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.BRAND]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.MODEL]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.AC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.DC_POWER]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.N_PLUGS]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.PLUG_CONFIG]: {
                    visible: true,
                    editable: false,
                },
                [PROCESS_DETAILS_INPUT_NAMES.FAST_CHARGING]: {
                    visible: true,
                    editable: false,
                }
            },
            [PRODUCT_IDS.CFP]: {

            }
        },
    },
}
import { lazy } from 'react';
import { connect } from "react-redux";
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';

const DashboardContainerV2 = lazy(() => lazyLoader(() => import("pages/Restricted/Dashboard")));

/**
 * @context Route to Dashboard
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Dashboard = (props) => (  <DashboardContainerV2/>  );

const mapStateToProps = () => ({
});

export default connect(mapStateToProps,
    {  }
)(withTracker(Dashboard, 'Dashboard'));

/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @component Login
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */

import { memo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import IntlMessages from "components/util/IntlMessages";
import PropTypes from 'prop-types';
import { Button } from '@save2compete/efz-design-system';
import ErrorMessage from 'components/ErrorMessage';
import { OutlinedInput } from '@mui/material';
import { intlMessages } from 'services/util/auxiliaryUtils';
import { Link } from 'react-router-dom';
import { BASENAME_URL } from 'constants/settings';

/**
 * 
 * @param {*} param0 
 */
const FormLogin = ({
    isRequesting,
    handleOnSubmit,
    mounted,
}) => {


    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            username: '',
            password: ''
        },
    });

    const onSubmit = payload => (handleOnSubmit(payload));


    return (
        <div>
            <form autoComplete={'true'} onSubmit={handleSubmit(onSubmit)} className="login-form">
                <div className="col-12 col-lg-10 col-xl-8">
                    {/* username */}
                    <Controller
                        name="username"
                        control={control}
                        rules={{ required: "yup.message.required" }}
                        render={({ field }) =>
                            <OutlinedInput
                                {...field}
                                placeholder={intlMessages('label.username')}
                                className={`w-100 ${errors?.username ? '' : 'mb-3'}`}
                                disabled={mounted && isRequesting}
                            />
                        }
                    />
                    {errors?.username && <span className="d-block mb-3 w-100"><ErrorMessage error={errors.username} /></span>}
                    {/* password */}
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) =>
                            <OutlinedInput
                                {...field}
                                type={'password'}
                                placeholder={intlMessages('label.password')}
                                className="w-100"
                                disabled={mounted && isRequesting}
                            />}
                    />
                    {errors?.password && <ErrorMessage error={errors.password} />}

                </div>


                {/*actions*/}
                <div className="d-flex align-items-center justify-content-between col-12 col-lg-10 col-xl-8 mt-4">
                    <Button
                        dataTestId="formLogin-signin"
                        disabled={mounted && isRequesting}
                        variant="primary"
                        className="w-100"
                        type='submit'
                        loading={mounted && isRequesting}
                    >
                        <IntlMessages id={'label.signin'} />
                    </Button>
                </div>
                {/*reset password*/}
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <h4>
                        <Link className={isRequesting ? 'disabled-cursor' : ''} to={`${BASENAME_URL}reset-password`}>
                            <IntlMessages id={'page.login.forgetPassword'} />
                        </Link>
                    </h4>
                </div>
            </form>
        </div>
    )
}


FormLogin.propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    handleOnSubmit: PropTypes.func.isRequired,
};

export default memo(FormLogin);
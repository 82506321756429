import { INPUT_NAMES } from "./processesFilters";

export enum FiltersActions {
    SET_GLOBAL_FILTER = 'SET_GLOBAL_FILTER' ,
    SET_FILTERS= 'SET_FILTERS',
    CLEAN_UP_FILTERS = 'CLEAN_UP_FILTERS'
}

export const PROCESSES_FILTERS_INPUTS_V2 = {
    [INPUT_NAMES.STATE_ID] : {
        name: INPUT_NAMES.STATE_ID,
        label: `page.processes.filters.stateName`,
        placeholder: `page.processes.filters.stateName.placeholder`
    },
    [INPUT_NAMES.FROM_UPDATED_AT] : {
        name: INPUT_NAMES.FROM_UPDATED_AT,
        label: `page.processes.filters.fromUpdatedAt`,
        placeholder: `page.processes.filters.fromUpdatedAt`
    },
    [INPUT_NAMES.TO_UPDATED_AT] : {
        name: INPUT_NAMES.TO_UPDATED_AT,
        label: `page.processes.filters.toUpdatedAt`,
        placeholder: `page.processes.filters.toUpdatedAt`
    },
    [INPUT_NAMES.PRODUCT_TYPE_ID] : {
        name: INPUT_NAMES.PRODUCT_TYPE_ID,
        label: `page.processes.filters.product`,
        placeholder: `page.processes.filters.product.placeholder`
    },
    [INPUT_NAMES.PAYMENT_METHODS] : {
        name: INPUT_NAMES.PAYMENT_METHODS,
        label: `processDetails.label.payment_methods_description`,
        placeholder: `label.placeholder.selectOption`,
    },
    [INPUT_NAMES.CLIENT_NAME] : {
        name: INPUT_NAMES.CLIENT_NAME,
        label: `label.processTable.client`,
        placeholder: `page.field2.0.processesTable.filters.clientName.placeholder`
    },
    [INPUT_NAMES.PARTNER_ID] : {
        name: INPUT_NAMES.PARTNER_ID,
        label: `page.field2.0.processesTable.filters.fiedlTechnician`,
        placeholder: `label.placeholder.selectOption`
    },
}
import { ENVIRONMENTS_FE } from "constants/settings";
import { getDomain } from "./settings";
import {
    URL_AUTH,
    URL_BASE,
    URL_STATES,
    URL_PARTNERS,
    URL_PROCESS_DASHBOARD,
    URL_PROCESS_FORMS,
    URL_PROCESS_LOGS,
    URL_PROCESS_SCHEDULES,
    URL_STANDBY_REASON,
    URL_CANCEL_REASON,
    URL_REFORMULATED_REASON,
    URL_FILE_UPLOAD,
    URL_PRODUCTS,
    URL_SERVICE_MANAGERS,
    URL_USERS,
    URL_SALES_OPERATIONS_MANAGERS,
    URL_UNFEASIBLE_REASON,
    URL_PAYMENT_METHODS,
    URL_PRODUCTION_ARRAY_FILE,
    URL_REFORMULATED_PSE_REASON,
    URL_REFORMULATED_SERVICE_MANAGER_REASON,
    URL_NOT_STANDARD_REASONS,
    URL_AUDIT_FILES,
    URL_PROCESS,
    URL_CLIENTS,
    URL_FACILITIES,
    URL_STRUCTURES,
    URL_ASSET_MANAGEMENT,
    URL_AVAILABLE_PROPOSALS,
    URL_REASONS,
    URL_GET_USERS,
    URL_VYN_REASONS
} from "constants/endpoints";
const ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : ENVIRONMENTS_FE.DEV;


/**
 * getBaseURL
 *
 * @return {*} 
 */
export function getBaseURL() {
    return (ENV === ENVIRONMENTS_FE.DEV ? 'http://' : 'https://') +
        getDomain(ENV) + '/api/'
}


/**
 * getURL
 * 
 * @param {*} name 
 */
export const getEndpoint = (name) => {
    switch (name) {
        case URL_BASE:
            return getBaseURL();
        case URL_AUTH:
            return getBaseURL() + URL_AUTH;
        case URL_PROCESS_LOGS:
            return getBaseURL() + URL_PROCESS_LOGS;
        case URL_PROCESS:
            return getBaseURL() + URL_PROCESS;
        case URL_PROCESS_DASHBOARD:
            return getBaseURL() + URL_PROCESS_DASHBOARD;
        case URL_PROCESS_SCHEDULES:
            return getBaseURL() + URL_PROCESS_SCHEDULES;
        case URL_PROCESS_FORMS:
            return getBaseURL() + URL_PROCESS_FORMS;
        case URL_STATES:
            return getBaseURL() + URL_STATES;
        case URL_PARTNERS:
            return getBaseURL() + URL_PARTNERS;
        case URL_STANDBY_REASON:
            return getBaseURL() + URL_STANDBY_REASON;
        case URL_CANCEL_REASON:
            return getBaseURL() + URL_CANCEL_REASON;
        case URL_REFORMULATED_REASON:
            return getBaseURL() + URL_REFORMULATED_REASON;
        case URL_REFORMULATED_PSE_REASON:
            return getBaseURL() + URL_REFORMULATED_PSE_REASON;
        case URL_REFORMULATED_SERVICE_MANAGER_REASON:
            return getBaseURL() + URL_REFORMULATED_SERVICE_MANAGER_REASON;
        case URL_FILE_UPLOAD:
            return getBaseURL() + URL_FILE_UPLOAD;
        case URL_PRODUCTS:
            return getBaseURL() + URL_PRODUCTS;
        case URL_SERVICE_MANAGERS:
            return getBaseURL() + URL_SERVICE_MANAGERS;
        case URL_USERS:
            return getBaseURL() + URL_USERS;
        case URL_SALES_OPERATIONS_MANAGERS:
            return getBaseURL() + URL_SALES_OPERATIONS_MANAGERS;
        case URL_UNFEASIBLE_REASON:
            return getBaseURL() + URL_UNFEASIBLE_REASON;
        case URL_PAYMENT_METHODS:
            return getBaseURL() + URL_PAYMENT_METHODS;
        case URL_PRODUCTION_ARRAY_FILE:
            return getBaseURL() + URL_PRODUCTION_ARRAY_FILE;
        case URL_NOT_STANDARD_REASONS:
            return getBaseURL() + URL_NOT_STANDARD_REASONS;
        case URL_VYN_REASONS:
            return getBaseURL() + URL_VYN_REASONS;
        case URL_AUDIT_FILES: 
            return getBaseURL() + URL_AUDIT_FILES;
        case URL_CLIENTS: 
            return getBaseURL() + URL_CLIENTS;
        case URL_FACILITIES: 
            return getBaseURL() + URL_FACILITIES;
        case URL_STRUCTURES: 
            return getBaseURL() + URL_STRUCTURES;
        case URL_ASSET_MANAGEMENT: 
            return getBaseURL() + URL_ASSET_MANAGEMENT;
        case URL_AVAILABLE_PROPOSALS: 
            return getBaseURL() + URL_PROCESS + '/' + URL_AVAILABLE_PROPOSALS;
        case URL_REASONS: 
            return getBaseURL() + URL_REASONS;
        case URL_GET_USERS: 
            return getBaseURL() + URL_PROCESS_FORMS + '/' + URL_GET_USERS;
        default://URL_BASE
            return getBaseURL();
    }
}



/**
 * getConfigHeader
 * 
 */
export function getConfigHeader() {
    return {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: "JWT " + localStorage.getItem('userToken'),
        cancellable: true,
        timeout: 80000
    }
}

const defaultTheme = {
    palette: {
        // For theme with only one color, set primary and secondary as the same
        primary: {
            main: 'rgba(59, 160, 184, 1)',
            bleached: 'rgba(59, 160, 184, 0.12)'
        },
        secondary: {
            main: 'rgba(87, 200, 168, 1)',
        }
        // For tertiary color, use the follwing object. Material UI components won't use it, but CSS will
        //tertiary: {}
    },
    status: {
        danger: 'orange'
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
        fontFamily: [
            "Open Sans",
            'Roboto',
            'Arial',
            'sans-serif'
        ].join(',')
    },
    logo: {
        name: 'defaultEfz',
        src: `${process.env.PUBLIC_URL}/logo_new_effizency.png`
    }
};

export default defaultTheme;

import { defineMessages } from 'react-intl';
import enMessages from '../locales/el_GR.json';

const elGRLang = {
    messages: {
        ...enMessages
    },
    locale: 'el-GR',
    data: defineMessages
};
export default elGRLang;

import { MatomoEvents, MatomoEventTypes, MatomoSections } from "constants/matomo";
import { TimelineStateButton } from "constants/timeline/configsV2";
import { isDefined } from "./util/auxiliaryUtils";

export const matomoEvt = (options) => {

    const {
        type,
        event,
        section,
        value,
        dimension
    } = options;

    window._paq.push(['trackEvent', type, event, section, isDefined(value) ? value : null, dimension]);

} 

export const getTimelineMatomoEvt = (action, viewToShow) => {

    const isAsset = viewToShow === 'asset'
    const MatomoEvent = isAsset ? MatomoEvents.PROCESS_DETAILS_ASSET_MANAGEMENT : MatomoEvents.PROCESS_DETAILS_INSTALLATION

    switch (action) {
        case TimelineStateButton.RETURN_PREVIOUS_STATUS:
            return matomoEvt({
                type: MatomoEvent,
                event: MatomoEventTypes.CLICK,
                section: MatomoSections.TIMELINE_RETURN_TO_PREVIOUS_STATUS,
                dimension: null
            })
        case TimelineStateButton.EDIT_REQUEST:
            return matomoEvt({
                type: MatomoEvent,
                event: MatomoEventTypes.CLICK,
                section: MatomoSections.TIMELINE_EDIT_REQUEST,
                dimension: null
            })
        case TimelineStateButton.UPDATE_STATUS:
            return matomoEvt({
                type: MatomoEvent,
                event: MatomoEventTypes.CLICK,
                section: MatomoSections.TIMELINE_UPDATE_STATUS,
                dimension: null
            })
        case TimelineStateButton.SEND_FORM:
            return matomoEvt({
                type: MatomoEvent,
                event: MatomoEventTypes.CLICK,
                section: MatomoSections.TIMELINE_SEND_FORM,
                dimension: null
            })
        case TimelineStateButton.SERVICE_REQUEST:
            return matomoEvt({
                type: MatomoEvent,
                event: MatomoEventTypes.CLICK,
                section: MatomoSections.TIMELINE_SERVICE_REQUEST,
                dimension: null
            })
        case TimelineStateButton.SEND_TO_ASSET_MANAGEMENT:
            return matomoEvt({
                type: MatomoEvent,
                event: MatomoEventTypes.CLICK,
                section: MatomoSections.TIMELINE_SEND_TO_ASSET_MANAGEMENT,
                dimension: null
            })
        default:
            break;
    }
}
import { createContext, useContext, useMemo } from "react";
import { array, object } from "prop-types";

// Constants
import { TECHNICAL_DETAILS_CONFIGS } from "constants/processDetails/configs";
import { PROCESS_DETAILS_INPUT_NAMES } from "constants/processDetails";

// Contexts
import UserContext from "contexts/userContext";
import { ProcessDetailsContext } from "contexts/processDetailsContext";

const {
    TECHNICAL_DETAILS,
    CONTRACT_ID
} = PROCESS_DETAILS_INPUT_NAMES;

const EquipmentsDetailsContext = createContext({
    configEquipmentsDetails: object,
    processDetails: object,
    preValues: array,
    posValues: array
});

const EquipmentsDetailsContextProvider = ({ children }) => {

    const {
        userTypeID,
        userCompanyID
    } = useContext(UserContext);

    const {
        processDetails,
        processDetailsTab
    } = useContext(ProcessDetailsContext)

    const configEquipmentsDetails = useMemo(() => (
        TECHNICAL_DETAILS_CONFIGS?.[userCompanyID]?.[userTypeID]?.[processDetails?.[processDetailsTab]?.product_type_id]
    ), [processDetails, userCompanyID, userTypeID, processDetailsTab]);

    const preValues = useMemo(() => processDetails?.[processDetailsTab]?.pre?.[TECHNICAL_DETAILS], [processDetails, processDetailsTab]);
    const posValues = useMemo(() => processDetails?.[processDetailsTab]?.pos?.[TECHNICAL_DETAILS], [processDetails, processDetailsTab]);
    const reformulateValues = useMemo(() => processDetails?.[0]?.pre?.[TECHNICAL_DETAILS], [processDetails]);
    const reformulateValuesToGroup = useMemo(() => processDetails?.filter(process => process?.is_reformulated).map(process => process?.pos?.[TECHNICAL_DETAILS]), [processDetails])
    const oldProposalValues = useMemo(() => ({
        pre: processDetails?.find(process => !process?.[PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED])?.pre?.[TECHNICAL_DETAILS],
        pos: processDetails?.find(process => !process?.[PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED])?.pos?.[TECHNICAL_DETAILS],
        contractID: processDetails?.find(process => !process?.[PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED])?.[CONTRACT_ID]
    }), [processDetails])

    return (
        <EquipmentsDetailsContext.Provider
            value={{
                configEquipmentsDetails,
                processDetails: processDetails?.[processDetailsTab],
                preValues,
                posValues,
                userTypeID,
                reformulateValues,
                reformulateValuesToGroup,
                oldProposalValues,
                simulationOutputs: processDetails?.[0]?.simulation_outputs
            }}
        >
            {children}
        </EquipmentsDetailsContext.Provider>
    )
}

export { EquipmentsDetailsContext, EquipmentsDetailsContextProvider }
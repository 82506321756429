import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from "react-router-dom";

// Components
import CookieHeader from './Header';
import CookieBody from './Body';

// Services
import { routeToCookiePolicy } from 'services/cookies';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

// Constants
import { USE_POLICY_BODY, PRIVACY_POLICY_BODY, COOKIES_POLICY_BODY } from 'constants/cookies';

const CookieConsent = ({
    type,
    close,
    showPolicyHandler
}) => {
    const isUserAuthenticated = !isFieldDefined(type);
    const location = useLocation();
    const navigate = useNavigate();
    const scrollRef = useRef(null);
    const scrollToTop = () => scrollRef.current.scrollIntoView();
    
    let title;
    let body;
    let updatedDate;
    let clickMethods = {};

    if (!isUserAuthenticated) {
        switch (type) {
            case 'use-conditions' :
                isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
                clickMethods.link1 = () => showPolicyHandler('privacy-policy');
                clickMethods.link2 = () => showPolicyHandler('cookies-policy');
                title = USE_POLICY_BODY(clickMethods.link1, clickMethods.link2).title;
                body = USE_POLICY_BODY(clickMethods.link1, clickMethods.link2).body;
                updatedDate = USE_POLICY_BODY(clickMethods.link1, clickMethods.link2).updated;
                break;
            
            case 'privacy-policy' :
                isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
                clickMethods.link1 = () => showPolicyHandler('use-conditions');
                clickMethods.link2 = () => showPolicyHandler('cookies-policy');
                title = PRIVACY_POLICY_BODY(clickMethods.link1, clickMethods.link2).title;
                body = PRIVACY_POLICY_BODY(clickMethods.link1, clickMethods.link2).body;
                updatedDate = PRIVACY_POLICY_BODY(clickMethods.link1, clickMethods.link2).updated;
                break;

            case 'cookies-policy' :
                isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
                clickMethods.link1 = () => showPolicyHandler('privacy-policy');
                title = COOKIES_POLICY_BODY(clickMethods.link1).title;
                body = COOKIES_POLICY_BODY(clickMethods.link1).body;
                updatedDate = COOKIES_POLICY_BODY(clickMethods.link1).updated;
                break;

            default :
                break;
        }
    } else {
        switch (location.state.type) {
                case 'use-conditions' :
                    isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
                    clickMethods.link1 = () => routeToCookiePolicy(navigate,'privacy-policy');
                    clickMethods.link2 = () => routeToCookiePolicy(navigate,'cookies-policy');
                    title = USE_POLICY_BODY(clickMethods.link1, clickMethods.link2).title;
                    body = USE_POLICY_BODY(clickMethods.link1, clickMethods.link2).body;
                    updatedDate = USE_POLICY_BODY(clickMethods.link1, clickMethods.link2).updated;
                    break;
                case 'privacy-policy' :
                    isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
                    clickMethods.link1 = () => routeToCookiePolicy(navigate,'use-conditions');
                    clickMethods.link2 = () => routeToCookiePolicy(navigate,'cookies-policy');
                    title = PRIVACY_POLICY_BODY(clickMethods.link1, clickMethods.link2).title;
                    body = PRIVACY_POLICY_BODY(clickMethods.link1, clickMethods.link2).body;
                    updatedDate = PRIVACY_POLICY_BODY(clickMethods.link1, clickMethods.link2).updated;
                    break;
    
                case 'cookies-policy' :
                    isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
                    clickMethods.link1 = () => routeToCookiePolicy(navigate,'privacy-policy');
                    title = COOKIES_POLICY_BODY(clickMethods.link1).title;
                    body = COOKIES_POLICY_BODY(clickMethods.link1).body;
                    updatedDate = COOKIES_POLICY_BODY(clickMethods.link1).updated;
                    break;
            default :
                break;
        }
    }

    return (
        <div className={`cookie-consent cookie-consent-${!isUserAuthenticated ? 'not' : ''}-authenticated `} ref={scrollRef}>
            {!isUserAuthenticated && <CookieHeader title ={title} close={close}/>}
            <CookieBody body={body} updatedDate={updatedDate}/>
        </div>
    )
}

CookieConsent.propTypes = {
    type: PropTypes.string,
    close: PropTypes.func,
    showPolicyHandler: PropTypes.func
}

export default CookieConsent;
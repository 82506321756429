export const BUSINESS_MODELS = [
    {
        id: 1,
        label: 'UP_FRONT'
    },
    {
        id: 2,
        label: 'INSTALLMENTS'
    },
    {
        id: 3,
        label: 'SERVICE'
    },
    {
        id: 4,
        label: 'SERVICE_PPA'
    },
    {
        id: 5,
        label: 'NOT_APPLICABLE'
    },
    {
        id: 6,
        label: 'LEC'
    },
    {
        id: 7,
        label: 'SERVICE_SECOND_VERSION'
    }
];

export const PAYMENT_METHOD_TAGS = {
    UP_FRONT: 'UP_FRONT',
    INSTALLMENTS: 'INSTALLMENTS',
    SERVICE: 'SERVICE',
    SERVICE_PPA: 'SERVICE_PPA',
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    LEC: 'LEC',
    SERVICE_SECOND_VERSION: 'SERVICE_SECOND_VERSION',
    PAY_AS_PRODUCED: 'PAY_AS_PRODUCED',
    PARTNERSHIP: 'PARTNERSHIP',
    EASY_SOLAR: 'EASY_SOLAR'
};

export const PAYMENT_MODELS_IDS = {
    UP_FRONT: 1,
    INSTALLMENTS: 2,
    SERVICE: 3,
    SERVICE_PPA: 4,
    NOT_APPLICABLE: 5,
    LEC: 7,
    SERVICE_SECOND_VERSION: 116,
    PAY_AS_PRODUCED: 142,
    PARTNERSHIP: [148]
};
/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Valida as props necessárias  para o acesso ao Container antes de renderizar-lo
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */

import { useContext, lazy } from 'react';

// Services
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';

// Contexts
import AuthContext from 'contexts/authContext';

const HomeContainer = lazy(() => lazyLoader(() => import("pages/Restricted/Home")));


/**
 * @context Route to Home
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Home = () => {

    const { isUserAuthenticated, logoutHandler} = useContext(AuthContext);
    
    return (
        isUserAuthenticated ? <HomeContainer /> : logoutHandler()
    )
};

export default withTracker(Home, 'Home');

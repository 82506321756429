import { PROCESS_STATE_IDS } from "constants/processDetails";
import { COMPANY_IDS } from "constants/settings";
import { USERS_TYPE_ID } from "constants/user";

import { ReactComponent as DownloadIcon } from "assets/images/timeline/actions/download.svg";
import { ReactComponent as SendIcon } from "assets/images/timeline/actions/send.svg";
import { ReactComponent as SiteSurveyIcon } from "assets/images/timeline/actions/site_survey.svg";
import { ReactComponent as SwitchIcon } from "assets/images/timeline/actions/switch.svg";
import { ReactComponent as ViewIcon } from "assets/images/timeline/actions/view.svg";
import { ReactComponent as CalendarIcon } from "assets/images/timeline/actions/calendar.svg";
import { ReactComponent as PendingIcon } from "assets/images/timeline/timeline-pending.svg";
import { ReactComponent as SSRequestedIcon } from "assets/images/timeline/timeline-ss-requested.svg";
import { ReactComponent as SSScheduledIcon } from "assets/images/timeline/timeline-ss-scheduled.svg";
import { ReactComponent as SSExecutedIcon } from "assets/images/timeline/timeline-ss-done.svg";
import { ReactComponent as PricingCompleteIcon } from "assets/images/timeline/timeline-pricing-complete.svg";
import { ReactComponent as ValidatedIcon } from "assets/images/timeline/timeline-won.svg";
import { ReactComponent as ReformulatedIcon } from "assets/images/timeline/timeline-reformulated.svg";
import { ReactComponent as PausedIcon } from "assets/images/timeline/timeline-paused.svg";
import { ReactComponent as ErrorIcon } from "assets/images/timeline/timeline-error.svg";
import { ReactComponent as ReturnIcon } from "assets/images/timeline/actions/return.svg";

export const STATE_SECTION = {
    DATE: 'date',
    COMMENTS: 'comments',
    REASON: 'reason',
    STATE_CHANGE: 'state_change',
    DOWNLOAD_DOCS: 'download_docs',
    SEND_SITE_SURVEY: 'send_site_survey',
    INFO_SITE_SURVEY: 'info_site_survey',
    SCHEDULE_PROCESS: 'schedule_process',
    RESCHEDULE_PROCESS: 'reschedule_process',
    SEND_DOCS: 'send_docs',
    RESEND_DOCS: 'resend_docs',
    TIR: 'tir',
    DOWNLOAD_AUDITS: 'download_audits',
    REVERT_STATUS: 'revert_status'
}

export const CONFIG_STATE_SECTION = {
    [STATE_SECTION.STATE_CHANGE]: {
        label: 'processDetails.button.UpdateProcess',
        icon: <SwitchIcon className="me-2" />
    },
    [STATE_SECTION.DOWNLOAD_DOCS]: {
        label: 'processDetails.button.download',
        icon: <DownloadIcon className="me-2" width="16" height="16" />
    },
    [STATE_SECTION.SEND_SITE_SURVEY]: {
        label: 'processDetails.button.dispatchSiteSurvey',
        icon: <SiteSurveyIcon className="me-2" width="16" height="16" />
    },
    [STATE_SECTION.INFO_SITE_SURVEY]: {
        label: 'processDetails.button.viewBudget',
        icon: <ViewIcon className={`me-2`} width="16" height="16" />
    },
    [STATE_SECTION.SCHEDULE_PROCESS]: {
        label: 'processDetails.button.addSchedule',
        icon: <CalendarIcon className="me-2" width="16" height="16" />
    },
    [STATE_SECTION.RESCHEDULE_PROCESS]: {
        label: 'processDetails.button.reAddSchedule',
        icon: <CalendarIcon className="me-2" width="16" height="16" />,
    },
    [STATE_SECTION.SEND_DOCS]: {
        label: 'processDetails.button.uploadPricing',
        icon: <SendIcon className="me-2" width="16" height="16" />,
    },
    [STATE_SECTION.RESEND_DOCS]: {
        label: 'processDetails.button.reUploadPricing',
        icon: <SendIcon className="me-2" width="16" height="16" />,
    },
    [STATE_SECTION.DOWNLOAD_AUDITS]: {
        label: 'processDetails.button.downloadAudits',
        icon: <DownloadIcon className="me-2" width="16" height="16" />
    },
    [STATE_SECTION.REVERT_STATUS]: {
        label: 'processDetails.button.revertStatus',
        icon: <ReturnIcon className="me-2" width="16" height="16" />
    },
}

export const CONFIG_TIMELINE = {
    [COMPANY_IDS.EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.RESCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
            [PROCESS_STATE_IDS.ON_HOLD_EDP]: {
                label: 'state.name.ON_HOLD_EDP',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'on_hold_edp_tag',
                    label: 'onHoldEdp.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED_PARTNER]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
            [PROCESS_STATE_IDS.ACCEPTED]: {
                label: 'state.name.ACCEPTED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
        }
    },
    [COMPANY_IDS.EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.RESCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
        }
    },
    [COMPANY_IDS.EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.RESCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
        }
    },
    [COMPANY_IDS.EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR,
                    STATE_SECTION.DOWNLOAD_AUDITS,
                    STATE_SECTION.REVERT_STATUS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.RESCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
        }
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SEND_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.TIR
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS,
                    STATE_SECTION.TIR
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PROCESS_STATE_IDS.ADJUDICATED]: {
                label: 'state.name.ADJUDICATED',
                icon: <PendingIcon className='pending-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.STATE_CHANGE,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED]: {
                label: 'state.name.SITE_SURVEY_REQUESTED',
                icon: <SSRequestedIcon className='ss-requested-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.SCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED]: {
                label: 'state.name.SITE_SURVEY_SCHEDULED',
                icon: <SSScheduledIcon className='ss-scheduled-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.RESCHEDULE_PROCESS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED]: {
                label: 'state.name.SITE_SURVEY_EXECUTED',
                icon: <SSExecutedIcon className='ss-executed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.SEND_DOCS,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED]: {
                label: 'state.name.PARTNER_PRICING_COMPLETED',
                icon: <PricingCompleteIcon className='pricing-completed-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.VALIDATED]: {
                label: 'state.name.VALIDATED',
                icon: <ValidatedIcon className='validated-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ]
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_PARTNER]: {
                label: 'state.name.REFORMULATE_REQUEST_PARTNER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.RESEND_DOCS,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_pse_tag',
                    label: 'reformulatePSE.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATE_REQUEST_SERVICE_MANAGER]: {
                label: 'state.name.REFORMULATE_REQUEST_SERVICE_MANAGER',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_service_manager_tag',
                    label: 'reformulateSM.name.'
                }
            },
            [PROCESS_STATE_IDS.REFORMULATED]: {
                label: 'state.name.REFORMULATED',
                icon: <ReformulatedIcon className='reformulate-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'reformulate_reason_tag',
                    label: 'reformulated.name.'
                }
            },
            [PROCESS_STATE_IDS.PAUSED]: {
                label: 'state.name.PAUSED',
                icon: <PausedIcon className='paused-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'standby_reason_tag',
                    label: 'standyBy.name.'
                }
            },
            [PROCESS_STATE_IDS.CANCELLED]: {
                label: 'state.name.CANCELLED',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'cancel_reason_tag',
                    label: 'cancelled.name.'
                }
            },
            [PROCESS_STATE_IDS.UNFEASIBLE]: {
                label: 'state.name.UNFEASIBLE',
                icon: <ErrorIcon className='error-icon' />,
                visible: [
                    STATE_SECTION.DATE,
                    STATE_SECTION.COMMENTS,
                    STATE_SECTION.REASON,
                    STATE_SECTION.INFO_SITE_SURVEY,
                    STATE_SECTION.DOWNLOAD_DOCS
                ],
                reason: {
                    input: 'unfeasible_reason_tag',
                    label: 'unfeasible.name.'
                }
            },
        }
    },
}
export enum MatomoEvents {
    LOGIN = 'Login',
    TOP_LEVEL_NAV = 'Top-Level Nav',
    PROCESSES_LIST = 'Processes List',
    PROCESS_DETAILS = 'Process Details',
    PROCESS_DETAILS_INSTALLATION = 'Process Details - Installation',
    PROCESS_DETAILS_ASSET_MANAGEMENT = 'Process Details - Asset Management',
}

export enum MatomoEventTypes {
    CLICK = 'Click',
    LOGIN = 'Login'
}

export enum MatomoSections {
    USER_TYPE = 'User Type',
    LANGUAGE_SELECTOR = 'Language Selector',
    PROCESSES = 'Processes',
    DASHBOARD = 'Dashboard',
    PROFILE = 'Profile',
    CREATE_PROCESS = 'Create Process',
    CREATE_ASSET_MANAGEMENT = 'Create Asset Management',
    INSTALLATION_TAB = 'Installation Tab',
    ASSET_MANAGEMENT_TAB = 'Asset Management Tab',
    SEARCH_BAR = 'Search Bar',
    ADVANCED_FILTERS = 'Advanced Filters',
    HIDE_ADVANCED_FILTERS = 'Hide Advanced Filters',
    FILTERS_UPDATED_DATE = 'Filters - Updated Date',
    FILTERS_STATE = 'Filters - Status',
    FILTERS_PRODUCT = 'Filters - Product',
    FILTERS_BM = 'Filters - Business Model',
    FILTERS_FIELD_TECH = 'Filters - Field Tech',
    FILTERS_PARTNER = 'Filters - Partner',
    FILTERS_CLIENT = 'Filters - Client',
    FILTERS_CITY = 'Filters - City',
    FILTERS_USERNAME = 'Filters - Username',
    FILTERS_SERVICE_MANAGER = 'Filters - Service Manager',
    FILTERS_OPERATION_MANAGER = 'Filters - Operation Manager',
    FILTERS_APPLY_FILTER = 'Filters - Apply Filters',
    DOWNLOAD_DOCS = 'Download Docs',
    OPEN_PROCESS = 'Open Process',
    ASSET_MANAGEMENT_VIEW = 'Asset Management View',
    INSTALLATION_VIEW = 'Installation View',
    BACK_TO_PROCESSES = 'Back To Processes',
    GENERAL_INFO_EDIT = 'General Info - Edit',
    GENERAL_INFO_SAVE = 'General Info - Save Edit',
    GENERAL_INFO_CANCEL = 'General Info - Cancel Edit',
    GENERAL_INFO_COLAPSE = 'General Info - Colapse',
    GENERAL_INFO_EXPAND = 'General Info - Expand',
    CLIENT_DATA_EDIT = 'Client Data - Edit',
    CLIENT_DATA_SAVE = 'Client Data - Save Edit',
    CLIENT_DATA_CANCEL = 'Client Data - Cancel Edit',
    CLIENT_DATA_COLAPSE = 'Client Data - Colapse',
    CLIENT_DATA_EXPAND = 'Client Data - Expand',
    FACILITY_INFO_COLAPSE = 'Facility Info - Colapse',
    FACILITY_INFO_EXPAND = 'Facility Info - Expand',
    FINANCIAL_INFO_COLAPSE = 'Financial Info - Colapse',
    FINANCIAL_INFO_EXPAND = 'Financial Info - Expand',
    FINANCIAL_INFO_EDIT = 'Financial Info - Edit',
    FINANCIAL_INFO_SAVE = 'Financial Info - Save Edit',
    FINANCIAL_INFO_CANCEL = 'Financial Info - Cancel Edit',
    TECH_SUMMARY_EDIT = 'Tech Summary - Edit',
    TECH_SUMMARY_SAVE = 'Tech Summary - Save Edit',
    TECH_SUMMARY_CANCEL = 'Tech Summary - Cancel Edit',
    TECH_SUMMARY_COLAPSE = 'Tech Summary - Colapse',
    TECH_SUMMARY_EXPAND = 'Tech Summary - Expand',
    TECH_DETAILS_EDIT = 'Tech Details - Edit Group',
    TECH_DETAILS_SAVE = 'Tech Details - Save Edit',
    TECH_DETAILS_CANCEL = 'Tech Details - Cancel Edit',
    TECH_DETAILS_COLAPSE = 'Tech Details - Colapse',
    TECH_DETAILS_EXPAND = 'Tech Details - Expand',
    TECH_DETAILS_COLAPSE_GROUP = 'Tech Details - Colapse Group',
    TECH_DETAILS_EXPAND_GROUP = 'Tech Details - Expand Group',
    TIMELINE_UPDATE_STATUS = 'Timeline - Update Status',
    TIMELINE_RETURN_TO_PREVIOUS_STATUS = 'Timeline - Return To Previous Status',
    TIMELINE_SEND_FORM = 'Timeline - Send Form',
    TIMELINE_EDIT_REQUEST = 'Timeline - Edit Request',
    TIMELINE_SEND_TO_ASSET_MANAGEMENT = 'Timeline - Send to Asset Management',
    TIMELINE_SERVICE_REQUEST = 'Timeline - Service Request',
    OPERATIONAL_SWITCH_ON = 'Operational Switch On',
    OPERATIONAL_SWITCH_OFF = 'Operational Switch Off',
}
import { Provider } from 'react-redux';
import Router from 'routes/Router';
import configureStore, { history } from './store';
import App from './pages/App';
import { PersistGate } from "redux-persist/integration/react";
import { AppContextProvider } from 'contexts';

export const { store, persistor } = configureStore();

const MainApp = () =>
    <Router history={history}>
        <Provider store={store}>
            <AppContextProvider>
                <PersistGate persistor={persistor} >
                    <App />
                </PersistGate>
            </AppContextProvider>
        </Provider>
    </Router>;

export default MainApp;
// import appLocaleData from 'react-intl/index';
import itMessages from 'lngProvider/locales/it_IT.json';

const itLang = {
  messages: {
    ...itMessages
  },
  locale: 'it-IT',
  // data: appLocaleData
};
export default itLang;

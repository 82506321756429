import { toast } from 'react-toastify';
import { StatusCodes } from 'http-status-codes';
import IntlMessages from 'components/util/IntlMessages';
import { number, string } from 'prop-types';
import { ReactComponent as CheckIcon } from 'assets/images/icons/svg/check-toast.svg';
import { ReactComponent as WarningIcon } from 'assets/images/icons/svg/warning-toast.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icons/svg/error-toast.svg';
import { ReactComponent as InfoIcon } from 'assets/images/icons/svg/info-toast.svg';
import { API_ERROR_TAGS } from 'constants/settings';

// ALL TAGS
const notify_pool_TAGS = [
    API_ERROR_TAGS.NO_FILES_AVAILABLE,
    API_ERROR_TAGS.FILES_TOO_LARGE,
    API_ERROR_TAGS.CLIENT_NOT_FOUND,
];

/**
 * useNotifications Custom toastify
 * 
 * @returns 
 */
const useNotifications = () => {//FIXME: rever os metodos implementados

    /**
     * notify
     * @param {message: string, status: number} response 
     */
    function notify(response = {
        message: string,
        status: number
    }) {

        const { message, status } = response;

        if ([StatusCodes.NOT_FOUND, StatusCodes.BAD_REQUEST].includes(status) && notify_pool_TAGS.includes(message))
            toastNotify('error', <IntlMessages id={`notify.${message}`} />);
    }

    const toastNotify = (type, toastMessage, toastID) => {
        let icon;
        let title;

        switch (type) {
            /* SUCESSO */
            case 'sucess':
                icon = <CheckIcon className='me-2' height={20} />;
                title = <IntlMessages id='label.sucess' />;
                toast.success(
                    <div className='d-flex align-items-center'>
                        <div>{icon}</div>
                        <div>
                            <div className='toast-title'>{title}</div>
                            <span className='toast-content'>{toastMessage}</span>
                        </div>
                    </div>
                );
                break;
            /* INFO */
            case 'info':
                icon = <InfoIcon className='me-2' />;
                title = <IntlMessages id='label.info' />;
                toast.info(
                    <div className='d-flex align-items-center'>
                        {icon}
                        <div>
                            <div className='toast-title'>{title}</div>
                            <span className='toast-content'>{toastMessage}</span>
                        </div>
                    </div>
                );
                break;
            /* WARNING */
            case 'warning':
                icon = <WarningIcon className='me-2' />;
                title = <IntlMessages id='label.warning' />;
                toast.warning(
                    <div className='d-flex align-items-center'>
                        {icon}
                        <div>
                            <div className='toast-title'>{title}</div>
                            <span className='toast-content'>{toastMessage}</span>
                        </div>
                    </div>
                    , {
                        toastId: toastID // avoid duplicate notifications
                    });
                break;
            /* ERROR */
            case 'error':
                icon = <ErrorIcon className='me-2' />;
                title = <IntlMessages id='label.error' />;
                toast.error(
                    <div className='d-flex align-items-center'>
                        {icon}
                        <div>
                            <div className='toast-title'>{title}</div>
                            <span className='toast-content'>{toastMessage}</span>
                        </div>
                    </div>
                    , toastID ? {
                        toastId: toastID // avoid duplicate notifications
                    } : undefined);
                break;

            default:
                return;
        }
    }

    return {
        notify,
        toastNotify
    };
}

export default useNotifications;
import enLocale from "date-fns/locale/en-GB";
import ptLocale from "date-fns/locale/pt";
import itLocale from "date-fns/locale/it";
import plLocale from "date-fns/locale/pl";
import esLocale from "date-fns/locale/es";

// * DEFAULT OBJECTS * \\
export const INITIAL_USER = {
    id: null,
    name: null,
    username: null,
    profile_id: null,
    company_id: null,
    language: null,
    locale: "en-GB",
    currency: {
        description: "Euro",
        code: "EUR",
        symbol: "€",
        sub_unit_description: "cent",
        sub_unit_symbol: "c"
    }
};

export const INITIAL_LOCALE = {
    languageId: 'english',
    locale: 'en',
    name: 'label.english',
    icon: 'gb'
}

export const AVAILABLE_USER_LOCALES = {
    ptPT: 'pt-PT',
    esES: 'es-ES',
    enGB: 'en-GB',
    czCZ: 'cz-CZ',
    itIT: 'it-IT',
    plPL: 'pl-PL',
    ptBR: 'pt-BR',
    enIE: 'en-IE',
    deCH: 'de-CH',
    elGR: 'el-GR',
}

export const LOCALE_PICKERS = {
    en: enLocale,
    pt: ptLocale,
    it: itLocale,
    pl: plLocale,
    es: esLocale,
}

// * CONSTANTS * \\
export const USERS_TYPE_ID = {
    MANAGER: 1,
    PARTNER: 2,
    MANAGER_V2: 3,
    PARTNER_V2: 4,
};

export const USER_COUNTRY_LatLng = {
    [AVAILABLE_USER_LOCALES.czCZ]:
    {
        center: {
            lat: 50.075539,
            lng: 14.437800
        },
        searchRestriction: 'cz',
        language: 'cs',
        region: 'DE',
    },
    [AVAILABLE_USER_LOCALES.deCH]: {
        center: {
            lat: 46.948002,
            lng: 7.448130
        },
        searchRestriction: 'ch',
        language: 'de',
        region: "DE",
    },
    [AVAILABLE_USER_LOCALES.enGB]: {
        center: {
            lat: 51.506651,
            lng: -0.126694
        },
        searchRestriction: 'gb',
        language: 'en-GB',
        region: 'DE',
    },
    [AVAILABLE_USER_LOCALES.esES]: {
        center: {
            lat: 40.416775,
            lng: -3.703790
        },
        searchRestriction: 'es',
        language: 'es',
        region: 'ES',
    },
    [AVAILABLE_USER_LOCALES.itIT]: {
        center: {
            lat: 41.860101,
            lng: 12.465905
        },
        searchRestriction: 'it',
        language: 'it',
        region: 'IT',
    },
    [AVAILABLE_USER_LOCALES.plPL]: {
        center: {
            lat: 52.233201,
            lng: 21.001987
        },
        searchRestriction: 'pl',
        language: 'pl',
        region: 'PL',
    },
    [AVAILABLE_USER_LOCALES.ptBR]: {
        center: {
            lat: -15.794427,
            lng: -47.883166
        },
        searchRestriction: 'br',
        language: AVAILABLE_USER_LOCALES.ptBR,
        region: 'PT',
    },
    [AVAILABLE_USER_LOCALES.ptPT]: {
        center: {
            lat: 38.722252,
            lng: -9.139337
        },
        searchRestriction: 'pt',
        language: AVAILABLE_USER_LOCALES.ptPT,
        region: 'PT',
    },
    [AVAILABLE_USER_LOCALES.elGR]: {
        center: {
            lat: 37.9839,
            lng: 23.7294
        },
        searchRestriction: 'gr',
        language: AVAILABLE_USER_LOCALES.elGR,
        region: 'GR',
    },
    default: {
        center: {
            lat: 38.722252,
            lng: -9.139337
        },
        searchRestriction: 'pt',
        language: 'pt',
        region: 'PT',
    },
}


// * ACTION TYPES * \\
export const LOGOUT_USER = 'LOGOUT_USER';
export const REQUEST_GET_LOGIN = 'REQUEST_GET_LOGIN';
export const REQUEST_GET_LOGIN_FAIL = 'REQUEST_GET_LOGIN_FAIL';
export const REQUEST_GET_LOGIN_SUCCESS = 'REQUEST_GET_LOGIN_SUCCESS';
export const SET_USER_AUTHENTICATED_FALSE = 'SET_USER_AUTHENTICATED_FALSE';

import { ProcessDetailsActions } from "constants/processDetails/indexV2";
import { IProcessDetailsContext } from "interfaces/processDetails/index";
import { IReactNodeWithChildren } from "interfaces/utils";
import { createContext, useReducer } from "react";
import { initialProcessDetailsReducer, processDetailsReducer } from "./processDetailsReducer";

const ProcessDetailsContext = createContext<IProcessDetailsContext>({
    processDetails: initialProcessDetailsReducer,
    processDetailsDispatchHandler: () => { }
})
ProcessDetailsContext.displayName = 'efz-ProcessDetailsContext-v2'

const ProcessDetailsProvider: React.FC<IReactNodeWithChildren> = ({children}) => {

    const [processDetails, processDetailsDispatch] = useReducer(processDetailsReducer, initialProcessDetailsReducer);

    const processDetailsDispatchHandler = (type: ProcessDetailsActions, payload?: any) => {
        processDetailsDispatch({
            type: type,
            payload: payload
        })
    }

    const processDetailsContextValues: IProcessDetailsContext = { processDetails, processDetailsDispatchHandler }

    return (
        <ProcessDetailsContext.Provider value={processDetailsContextValues}>
            {children}
        </ProcessDetailsContext.Provider>
    )
}

export { ProcessDetailsContext, ProcessDetailsProvider }
import { memo } from 'react';
import PropTypes from 'prop-types';

//Material UI
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

//Our Components
import LanguageSwitcher from 'components/Header/LanguageSwitcher';
import { AVAILABLE_LANGUAGES } from 'constants/settings';
import { useMemo } from 'react';


const LangSwitcherCard = ({ isOpen, handleToggle, handleSwitchLanguage, langCode }) => {

    const languageCurrent = useMemo(() => AVAILABLE_LANGUAGES.find(language => language.icon === langCode), [langCode]);

    return (
        <Dropdown
            isOpen={isOpen}
            toggle={handleToggle}
        >
            <DropdownToggle
                className="header-dropdown-toggle"
                tag="span"
                data-toggle="dropdown"
            >
                <div className="menu-item-content">
                    <span className='language-icon'>{languageCurrent?.isoLocale}</span>
                </div>
            </DropdownToggle>
            <DropdownMenu end className="w-auto transition-none" >
                <LanguageSwitcher
                    switchLanguage={handleSwitchLanguage}
                    handleRequestClose={handleToggle}
                    activeLanguage={langCode}
                />
            </DropdownMenu>
        </Dropdown>
    )
}


//PropTypes
LangSwitcherCard.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleSwitchLanguage: PropTypes.func.isRequired,
    langCode: PropTypes.string.isRequired,
}
export default memo(LangSwitcherCard);
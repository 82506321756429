import IntlMessages from "components/util/IntlMessages";


export const USE_POLICY_BODY = (link1, link2) => ({
    title: 'Conditions of use',
    body: (
        <div className='consent-use-conditions'>
            <div className='consent-title'>
                <span><IntlMessages id="page.terms" /></span>
                <hr />
            </div>
            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>1. <IntlMessages id="page.terms.purpose" /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    1.1.	<IntlMessages id="page.terms.purpose.1" />
                    <br />
                    <br />
                    1.2.	<IntlMessages id="page.terms.purpose.2" />
                    <br />
                    <br />
                    1.3.	<IntlMessages id="page.terms.purpose.3" />
                    <br />
                    <br />
                    1.4.	<IntlMessages id="page.terms.purpose.4.1" /> <span className='link' onClick={link1}><IntlMessages id="label.here" /></span> <IntlMessages id="page.terms.purpose.4.2" /> <span className='link' onClick={link2}><IntlMessages id="label.here" /></span>.
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>2. <IntlMessages id="page.terms.changes" /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    2.1.	<IntlMessages id="page.terms.changes.1" />
                    <br />
                    <br />
                    2.2.	<IntlMessages id="page.terms.changes.2" />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>3. <IntlMessages id="page.terms.access" /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    3.1.	<IntlMessages id="page.terms.access.1" />
                    <br />
                    <br />
                    3.2.	<IntlMessages id="page.terms.access.2" />
                    <br />
                    <br />
                    3.3.	<IntlMessages id="page.terms.access.3" />
                    <br />
                    <br />
                    3.4.	<IntlMessages id="page.terms.access.4" />
                    <br />
                    <br />
                    3.5.	<IntlMessages id="page.terms.access.5" />
                    <br />
                    <br />
                    3.6.	<IntlMessages id="page.terms.access.6" />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>4. <IntlMessages id="page.terms.use" /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    4.1.	<IntlMessages id="page.terms.use.1" />
                    <br />
                    <br />
                    4.2.	<IntlMessages id="page.terms.use.2" />
                    <br />
                    <br />
                    4.3.	<IntlMessages id="page.terms.use.3" />
                    <br />
                    <br />
                    4.4.	<IntlMessages id="page.terms.use.4" />
                    <br />
                    <br />
                    4.5.	<IntlMessages id="page.terms.use.5" />
                    <br />
                    <br />
                    4.6.	<IntlMessages id="page.terms.use.6" />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>5. <IntlMessages id="page.terms.obligations" /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    5.1.	<IntlMessages id="page.terms.obligations.1" />
                    <br />
                    <br />
                    &emsp;a)	<IntlMessages id="page.terms.obligations.1.a" />
                    <br />
                    <br />
                    &emsp;b)	<IntlMessages id="page.terms.obligations.1.b" />
                    <br />
                    <br />
                    &emsp;c)	<IntlMessages id="page.terms.obligations.1.c" />
                    <br />
                    <br />
                    &emsp;d)	<IntlMessages id="page.terms.obligations.1.d" />
                    <br />
                    <br />
                    &emsp;e)	<IntlMessages id="page.terms.obligations.1.e" />
                    <br />
                    <br />
                    &emsp;f)	<IntlMessages id="page.terms.obligations.1.f" />
                    <br />
                    <br />
                    &emsp;g)	<IntlMessages id="page.terms.obligations.1.g" />
                    <br />
                    <br />
                    &emsp;h)	<IntlMessages id="page.terms.obligations.1.h" />
                    <br />
                    <br />
                    5.2.	<IntlMessages id="page.terms.obligations.2" />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>6. <IntlMessages id="page.terms.security" /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    6.1.	<IntlMessages id="page.terms.security.1" />
                    <br />
                    <br />
                    6.2.	<IntlMessages id="page.terms.security.2" />
                    <br />
                    <br />
                    6.3.	<IntlMessages id="page.terms.security.3" />
                    <br />
                    <br />
                    6.4.	<IntlMessages id="page.terms.security.4" />
                    <br />
                    <br />
                    6.5.	<IntlMessages id="page.terms.security.5" />
                    <br />
                    <br />
                    6.6.	<IntlMessages id="page.terms.security.6" />
                    <br />
                    <br />
                    6.7.	<IntlMessages id="page.terms.security.7" />
                    <br />
                    <br />
                    6.8.	<IntlMessages id="page.terms.security.8" />
                    <br />
                    <br />
                    6.9.	<IntlMessages id="page.terms.security.9" />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>7. <IntlMessages id="page.terms.intellectual" /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    7.1.	<IntlMessages id="page.terms.intellectual.1" />
                    <br />
                    <br />
                    7.2.	<IntlMessages id="page.terms.intellectual.2" />
                    <br />
                    <br />
                    7.3.	<IntlMessages id="page.terms.intellectual.3" />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>8. <IntlMessages id="page.terms.privacy" /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id="page.terms.privacy.text" /> <span className='link' onClick={link1}><IntlMessages id="label.here" /></span>.
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>9. <IntlMessages id="page.terms.dispute" /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id="page.terms.dispute.text" />
                </div>
            </section>

            <p className='consent-rights-reserved'>
                <IntlMessages id="label.rights" />
            </p>
        </div>
    ),
    updated: '28/01/2021'
})

export const PRIVACY_POLICY_BODY = (link1, link2) => ({
    title: 'Privacy policy',
    body: ((
        <div className='consent-privacy-policy'>
            <div className='consent-title'>
                <span><IntlMessages id='page.privacy' /></span>
                <hr />
            </div>
            <section className='consent-section'>
                <div className='consent-section-content'>
                    <IntlMessages id='page.privacy.1' />
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.2' />
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.3' />
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.4' />
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.5.1' /> <span className='link' onClick={link1}><IntlMessages id='label.here' /></span> <IntlMessages id='page.privacy.5.2' /> <span className='link' onClick={link2}><IntlMessages id='label.here' /></span>.
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>1. <IntlMessages id='page.privacy.scope' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.privacy.scope.1' />
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.scope.2' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>2. <IntlMessages id='page.privacy.purpose' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.privacy.purpose.1' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>3. <IntlMessages id='page.privacy.recipients' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.privacy.recipients.1' />
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.recipients.2' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>4. <IntlMessages id='page.privacy.datatransfer' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.privacy.datatransfer.1' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>5. <IntlMessages id='page.privacy.storage' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.privacy.storage.1' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>6. <IntlMessages id='page.privacy.security' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.privacy.security.1' />
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.security.2' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>7. <IntlMessages id='page.privacy.datarights' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.privacy.datarights.1' />
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.datarights.2' />
                    <br />
                    <br />
                    <table className='privacy-policy-table'>
                        <tbody>
                            <tr>
                                <th className='text-center' colSpan='2'><IntlMessages id='page.privacy.datarights.table' /></th>
                            </tr>
                            {privacyPolicyTable.map((row, index) => (
                                <tr key={`privacy-policy-td-${index}`}>
                                    <td className='first-cell text-left'>{row.first}</td>
                                    <td className='second-cell'>{row.second}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.datarights.3' />
                    <br />
                    <br />
                    <IntlMessages id='page.privacy.datarights.4' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span>8. <IntlMessages id='page.privacy.changes' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.privacy.changes.1' />
                </div>
            </section>
        </div>
    )),
    updated: '26/01/2021'
})

export const COOKIES_POLICY_BODY = (link1) => ({
    title: 'Cookies policy',
    body: ((
        <div className='consent-privacy-policy'>
            <div className='consent-title'>
                <span><IntlMessages id='page.cookies' /></span>
                <hr />
            </div>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span><IntlMessages id='page.cookies.intro' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.cookies.intro.1.1' /> <span className='link' onClick={link1}><IntlMessages id='label.here' /></span><IntlMessages id='page.cookies.intro.1.2' />
                    <br />
                    <br />
                    <IntlMessages id='page.cookies.intro.2' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span><IntlMessages id='page.cookies.what' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.cookies.what.1' />
                    <br />
                    <br />
                    <IntlMessages id='page.cookies.what.2' />
                    <br />
                    <br />
                    &emsp;(a)	<IntlMessages id='page.cookies.what.2.a' />
                    <br />
                    <br />
                    &emsp;(b)	<IntlMessages id='page.cookies.what.2.b' />
                    <br />
                    <br />
                    &emsp;(c)	<IntlMessages id='page.cookies.what.2.c' />
                    <br />
                    <br />
                    &emsp;(d)	<IntlMessages id='page.cookies.what.2.d' />
                    <br />
                    <br />
                    <IntlMessages id='page.cookies.what.3' />
                    <br />
                    <br />
                    &emsp;(a)	<IntlMessages id='page.cookies.what.3.a' />
                    <br />
                    <br />
                    &emsp;(b)	<IntlMessages id='page.cookies.what.3.b' />
                    <br />
                    <br />
                    <IntlMessages id='page.cookies.what.4' />
                    <br />
                    <br />
                    &emsp;(a)	<IntlMessages id='page.cookies.what.4.a' />
                    <br />
                    <br />
                    &emsp;(b)	<IntlMessages id='page.cookies.what.4.b' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span><IntlMessages id='page.cookies.used' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.cookies.used.1' />
                    <br />
                    <br />
                    <table className='cookie-policy-table'>
                        <tbody>
                            <tr className='head-row'>
                                <th className='cell-spacer'></th>
                                {cookiePolicyTable.th.map((th, index) => (
                                    <th key={`cookie-policy-th-${index}`}>
                                        {th}
                                    </th>
                                ))}
                                <th className='cell-spacer'></th>
                            </tr>
                            {
                                cookiePolicyTable.td.map((tr, i) => (
                                    <tr className='cookie-policy-row'>
                                        <td className='cell-spacer'></td>
                                        {
                                            tr.map((td, y) => (
                                                <td key={`cookie-policy-td-${i}-${y}`}>
                                                    {td}
                                                </td>
                                            ))
                                        }
                                        <td className='cell-spacer'></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span><IntlMessages id='page.cookies.accept' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.cookies.accept.1' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span><IntlMessages id='page.cookies.manage' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.cookies.manage.1' />
                    <br />
                    <br />
                    •	<IntlMessages id='page.cookies.manage.1.1' whitespaceEnd /> <span><a className='link' href={browsersCookiePolicy.FIREFOX} target="_blank" rel="noopener noreferrer"><IntlMessages id='label.here' /></a></span>;
                    <br />
                    <br />
                    •	<IntlMessages id='page.cookies.manage.1.2' /> <span><a className='link' href={browsersCookiePolicy.CHROME} target="_blank" rel="noopener noreferrer"><IntlMessages id='label.here' /></a></span>;
                    <br />
                    <br />
                    •	<IntlMessages id='page.cookies.manage.1.3' /> <span><a className='link' href={browsersCookiePolicy.EDGE} target="_blank" rel="noopener noreferrer"><IntlMessages id='label.here' /></a></span>;
                    <br />
                    <br />
                    •	<IntlMessages id='page.cookies.manage.1.4' /> <span><a className='link' href={browsersCookiePolicy.SAFARI} target="_blank" rel="noopener noreferrer"><IntlMessages id='label.here' /></a></span>;
                    <br />
                    <br />
                    •	<IntlMessages id='page.cookies.manage.1.5' /> <span><a className='link' href={browsersCookiePolicy.OPERA} target="_blank" rel="noopener noreferrer"><IntlMessages id='label.here' /></a></span>;
                    <br />
                    <br />
                    <IntlMessages id='page.cookies.manage.2' />
                </div>
            </section>

            <section className='consent-section'>
                <div className='consent-section-title'>
                    <span><IntlMessages id='page.cookies.changes' /></span>
                    <hr />
                </div>
                <div className='consent-section-content'>
                    <IntlMessages id='page.cookies.changes.1' />
                </div>
            </section>

        </div>
    )),
    updated: '16/08/2022'
})

const privacyPolicyTable = [
    {
        first: <IntlMessages id='page.privacy.datarights.table.1.1' />,
        second: <IntlMessages id='page.privacy.datarights.table.1.2' />
    },
    {
        first: <IntlMessages id='page.privacy.datarights.table.2.1' />,
        second: <IntlMessages id='page.privacy.datarights.table.2.2' />
    },
    {
        first: <IntlMessages id='page.privacy.datarights.table.3.1' />,
        second: <IntlMessages id='page.privacy.datarights.table.3.2' />
    },
    {
        first: <IntlMessages id='page.privacy.datarights.table.4.1' />,
        second: <IntlMessages id='page.privacy.datarights.table.4.2' />
    },
    {
        first: <IntlMessages id='page.privacy.datarights.table.5.1' />,
        second: <IntlMessages id='page.privacy.datarights.table.5.2' />
    },
    {
        first: <IntlMessages id='page.privacy.datarights.table.6.1' />,
        second: <IntlMessages id='page.privacy.datarights.table.6.2' />
    }

]

const cookiePolicyTable = {
    th: [
        <IntlMessages id='page.cookies.used.table.header.1' />,
        <IntlMessages id='page.cookies.used.table.header.2' />,
        <IntlMessages id='page.cookies.used.table.header.3' />,
        <IntlMessages id='page.cookies.used.table.header.4' />,
        <IntlMessages id='page.cookies.used.table.header.5' />,
        <IntlMessages id='page.cookies.used.table.header.6' />
    ],
    td: [
        [
            <IntlMessages id='page.cookies.used.table.body.1.1' />,
            <IntlMessages id='page.cookies.used.table.body.1.2' />,
            <IntlMessages id='page.cookies.used.table.body.1.3' />,
            <IntlMessages id='page.cookies.used.table.body.1.4' />,
            <IntlMessages id='page.cookies.used.table.body.1.5' />,
            <IntlMessages id='page.cookies.used.table.body.1.6' />,
        ],
        [
            <IntlMessages id='page.cookies.used.table.body.2.1' />,
            <IntlMessages id='page.cookies.used.table.body.2.2' />,
            <IntlMessages id='page.cookies.used.table.body.2.3' />,
            <IntlMessages id='page.cookies.used.table.body.2.4' />,
            <IntlMessages id='page.cookies.used.table.body.2.5' />,
            <IntlMessages id='page.cookies.used.table.body.2.6' />,
        ],
        [
            <IntlMessages id='page.cookies.used.table.body.3.1' />,
            <IntlMessages id='page.cookies.used.table.body.3.2' />,
            <IntlMessages id='page.cookies.used.table.body.3.3' />,
            <IntlMessages id='page.cookies.used.table.body.3.4' />,
            <IntlMessages id='page.cookies.used.table.body.3.5' />,
            <IntlMessages id='page.cookies.used.table.body.3.6' />,
        ],
    ]
}

const browsersCookiePolicy = {
    FIREFOX: 'https://support.mozilla.org/en-US/kb/private-browsing-use-firefox-without-history',
    CHROME: 'https://support.google.com/chromebook/answer/7440301?co=GENIE.Platform%3DDesktop&hl=en',
    EDGE: 'https://support.microsoft.com/en-us/microsoft-edge/browse-inprivate-in-microsoft-edge-cd2c9a48-0bc4-b98e-5e46-ac40c84e27e2',
    SAFARI: 'https://support.apple.com/guide/safari/use-private-browsing-ibrw1069/mac',
    OPERA: 'https://help.opera.com/en/opera36/be-safe-and-private/'
}
import { INITIAL_PROCESS_DETAILS, ProcessDetailsActions } from "constants/processDetails/indexV2"
import { IProcessDetailsReducerAction } from "interfaces/processDetails/processDetailsReducer"

export const initialProcessDetailsReducer = INITIAL_PROCESS_DETAILS

export const processDetailsReducer = (state = initialProcessDetailsReducer, action: IProcessDetailsReducerAction) => {
    switch(action.type) {
        case ProcessDetailsActions.SET_PROCESS_DETAILS:
            return {
                ...action.payload[0]
            }
        case ProcessDetailsActions.CLEAN_UP_PROCESS_DETAILS:
            return {
                ...INITIAL_PROCESS_DETAILS
            }
        default:
            return state;
    }
}
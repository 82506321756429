import {
    ACCEPTED,
    BAD_GATEWAY,
    BAD_REQUEST,
    CREATE,
    INCORRECT_DATA,
    INTERNAL_SERVER_ERROR,
    MSG_BAD_GATEWAY,
    NO_CONTENT,
    OK,
    SERVICE_UNAVAILABLE,
    UNAUTHORIZED,
    UNEXPECTED_ERROR
} from "constants/statusCodeHttp";



/**
 * getMessageError
 *
 * @param response
 * @returns {*}
 */
export function getMessageError(response) {
    let messageError = null;
    switch (response?.status) {
        case NO_CONTENT:
            messageError = INCORRECT_DATA;
            break;
        case UNAUTHORIZED:
            messageError = response.data.message;
            break;
        case BAD_GATEWAY:
            messageError = MSG_BAD_GATEWAY;
            break;
        case BAD_REQUEST:
        case INTERNAL_SERVER_ERROR:
        case SERVICE_UNAVAILABLE:
            messageError = UNEXPECTED_ERROR;
            break;
        default:
            if (!!response?.statusText) messageError = response?.statusText;
            else if (!!response?.data && !!response?.data?.message) messageError = response.data.message;
            else messageError = response;
            break;
    }

    return messageError;
}



/**
 * isSuccessfulResponse
 *
 * @param response
 * @returns {*}
 */
export function isSuccessfulResponse(response) {
    return [OK, CREATE, ACCEPTED].includes(response);
}

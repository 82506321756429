import { FiltersActions } from "constants/filters";
import { IFiltersContext } from "interfaces/filters";
import { IReactNodeWithChildren } from "interfaces/utils";
import { createContext, useReducer } from "react";
import { filtersReducer, initialFiltersReducer } from "./filterReducer";

const FiltersContext = createContext<IFiltersContext>({
    filters: initialFiltersReducer,
    filtersDispatchHandler: () => { }
})

const FiltersProvider: React.FC<IReactNodeWithChildren> = ({children}) => {

    const [filters, filtersDispatch] = useReducer(filtersReducer, initialFiltersReducer);

    const filtersDispatchHandler = (type: FiltersActions, payload?: any) => {
        filtersDispatch({
            type: type,
            payload: payload
        })
    }

    const filtersContextValues: IFiltersContext = { filters, filtersDispatchHandler }

    return (
        <FiltersContext.Provider value={filtersContextValues}>
            {children}
        </FiltersContext.Provider>
    )
}

export { FiltersContext, FiltersProvider }
import { INITIAL_CLIENT } from "constants/client";
import { INITIAL_FACILITY } from "constants/facility";
import { ProcessesActions } from "constants/processesV2";
import { INITIAL_SOLUTION } from "constants/solution";
import { ICreateProcessState } from "interfaces/processes";
import { IProcessesReducerAction } from "interfaces/processes/processesReducer";

export const initialCreateProcessReducer: ICreateProcessState = {
    client: INITIAL_CLIENT,
    facility: INITIAL_FACILITY,
    solution: INITIAL_SOLUTION,
}

export const createProcessReducer = (state = initialCreateProcessReducer, action: IProcessesReducerAction) => {
    switch (action.type) {
        case ProcessesActions.SET_CLIENT:
            return {
                ...state,
                client: action.payload
            }
        case ProcessesActions.RESET_CLIENT:
            return {
                ...state,
                client: INITIAL_CLIENT,
                facility: INITIAL_FACILITY,
            }

        case ProcessesActions.SET_FACILITY:
            return {
                ...state,
                facility: action.payload
            }
        case ProcessesActions.RESET_FACILITY:
            return {
                ...state,
                facility: INITIAL_FACILITY,
            }

        case ProcessesActions.SET_SOLUTION:
            return {
                ...state,
                solution: action.payload
            }
        case ProcessesActions.RESET_SOLUTION:
            return {
                ...state,
                solution: INITIAL_SOLUTION,
            }
            
        default:
            return state
    }
}
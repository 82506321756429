import { useState } from 'react';
import { sha256 } from 'js-sha256';
import PropTypes from "prop-types";
import { useForm, Controller } from 'react-hook-form';

// Material UI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, IconButton } from '@save2compete/efz-design-system';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

// Icons
// import { ReactComponent as VisibilityIcon } from 'assets/images/icons/svg/visibility.svg';
// import { ReactComponent as VisibilityOffIcon } from 'assets/images/icons/svg/visibilityOff.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/svg/arrow-blue.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icons/svg/clear-selection.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/svg/green-check.svg';

// Components
import Alert from 'components/@efz/Alert';
import ErrorMessage from 'components/ErrorMessage';
import IntlMessages from 'components/util/IntlMessages';

// Services
import { handleCloseDialog, intlMessages, isFieldDefined, PASSWORD_CHECK } from 'services/util/auxiliaryUtils';

// Hooks
import useRecoveryPassword from 'hooks/recoverPassword';

/**
 * @context Route to RedefinePasswordDialog
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const RedefinePasswordDialog = ({
    setHasChangedPass,
    hasChangedPass,
    userID,
}) => {

    const [showPassword, setShowPassword] = useState(false);

    const {
        isRequestingRedefinePwd,
        fetchRedefinePassword
    } = useRecoveryPassword();


    // Form validation
    const { handleSubmit, control, formState: { errors }, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            newPassword: '',
            confirmPassword: ''
        }
    });

    const watchNewPassword = watch('newPassword');

    // From input rules validation
    const rules = {
        newPassword: {
            required: 'yup.message.required',
            minLength: {
                value: 8,
                message: 'yup.message.string.minCharacters'
            },
            validate: {
                hasLowercase: value => PASSWORD_CHECK.PASSWORD_HAS_LOWERCASE_REGEXP.test(value) || 'yup.message.password.required.lowerCase',
                hasUppercase: value => PASSWORD_CHECK.PASSWORD_HAS_UPPERCASE_REGEXP.test(value) || 'yup.message.password.required.upperCase',
                hasNumber: value => PASSWORD_CHECK.PASSWORD_HAS_NUMBER_REGEXP.test(value) || 'yup.message.password.required.number',
                hasSpecialCharacter: value => PASSWORD_CHECK.PASSWORD_HAS_SPECIAL_CHARACTER.test(value) || 'dialog.passwordRedefine.info.part5'
            }
        },
        confirmPassword: {
            required: 'yup.message.required',
            validate: value => value === watchNewPassword || 'yup.message.oneOf.matchPasswords'
        }
    };


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const onSubmitClickHandler = (payload) => {
        const body = {
            password: sha256(payload.newPassword)
        }
        fetchRedefinePassword(setHasChangedPass, userID, body);
    };


    const alertBullets = (size) => {
        const bullets = [];
        const rule = rules['newPassword'];
        const validationList = [
            (isFieldDefined(watchNewPassword) && watchNewPassword.length >= rule.minLength.value) ?? false,
            (isFieldDefined(watchNewPassword) && typeof (rule.validate.hasNumber(watchNewPassword)) === 'boolean') ?? false,
            (isFieldDefined(watchNewPassword) && typeof (rule.validate.hasUppercase(watchNewPassword)) === 'boolean') ?? false,
            (isFieldDefined(watchNewPassword) && typeof (rule.validate.hasLowercase(watchNewPassword)) === 'boolean') ?? false,
            (isFieldDefined(watchNewPassword) && typeof (rule.validate.hasSpecialCharacter(watchNewPassword)) === 'boolean') ?? false,
        ];

        const iconChooser = (type) => {
            switch (type) {
                case 'error':
                    return <ErrorIcon />;
                case 'success':
                    return <CheckIcon />;
                case 'arrow':
                default:
                    return <ArrowIcon />;
            }
        };

        for (let i = 1; i <= size; i++) {
            bullets.push(
                <div key={`dialog-bullet-${i}`} className={`dialog-bullet ${!isFieldDefined(watchNewPassword) ? '' : (`dialog-bullet-${!validationList[i - 1] ? 'error' : 'success'}`)}`}>
                    <div className='bullet-icon'>
                        {iconChooser(!isFieldDefined(watchNewPassword) ? 'arrow' : (!validationList[i - 1] ? 'error' : 'success'))}
                    </div>
                    <IntlMessages id={`dialog.passwordRedefine.info.part${i}`} />
                </div>
            )
        }
        return bullets;
    };


    const alertContentText = (
        <div className='dialog-bullets'>
            {alertBullets(5)}
        </div>
    );


    return (
        <div className="content-wrapper-fluid banner-home">
            <Dialog
                disableEscapeKeyDown
                onClose={(_, reason, onCloseHandler) => handleCloseDialog(_, reason, onCloseHandler)}
                open={!hasChangedPass}
                className="redefine-password-dialog"
            >
                <form onSubmit={handleSubmit(onSubmitClickHandler)}>
                    <DialogTitle className="dialog-title">
                        <IntlMessages id='dialog.passwordRedefine.title' />
                        <hr />
                    </DialogTitle>
                    <DialogContent className="dialog-body">
                        <div className='inputs-container'>
                            { /* newPassword */}
                            <div className="input-container">
                                <label>
                                    <IntlMessages id="dialog.passwordRedefine.label.newPassword" /> *
                                </label>
                                <Controller
                                    name="newPassword"
                                    control={control}
                                    rules={rules['newPassword']}
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            placeholder={intlMessages('dialog.passwordRedefine.label.newPassword')}
                                            type={showPassword ? 'text' : 'password'}
                                            error={!!errors["newPassword"]}
                                            variant={"outlined"}
                                            disabled={isRequestingRedefinePwd}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        variant='tertiary'
                                                        dataTestId="visibilityPassword"
                                                        onClick={handleClickShowPassword}
                                                        edge="start"
                                                        tabIndex={-1}
                                                        icon={showPassword ? "viewEye" : "eyeClosed" }
                                                    />
                                                </InputAdornment>
                                            }
                                        />
                                    )}
                                />
                                {errors["newPassword"] && <ErrorMessage error={errors["newPassword"]} values={{ min: rules["newPassword"].minLength.value }} />}
                            </div>

                            { /* confirmPassword */}
                            <div className="input-container">
                                <label>
                                    <IntlMessages id="dialog.passwordRedefine.label.confirmPassword" /> *
                                </label>
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    rules={rules['confirmPassword']}
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            placeholder={intlMessages('dialog.passwordRedefine.label.confirmPassword')}
                                            variant={"outlined"}
                                            type={showPassword ? 'text' : 'password'}
                                            error={!!errors["confirmPassword"]}
                                            disabled={isRequestingRedefinePwd}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        variant='tertiary'
                                                        dataTestId="visibilityPassword"
                                                        onClick={handleClickShowPassword}
                                                        edge="start"
                                                        tabIndex={-1}
                                                        icon={showPassword ? "viewEye" : "eyeClosed" }
                                                    >
                                                        {/* {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />} */}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    )}
                                />
                                {errors["confirmPassword"] && <ErrorMessage error={errors["confirmPassword"]} />}
                            </div>
                        </div>
                        <Alert
                            severity='info'
                            className="info-text"
                            IntlMessages={<IntlMessages id='dialog.passwordRedefine.info' />}
                            secondaryText={alertContentText}
                        >
                        </Alert>
                    </DialogContent>
                    <DialogActions className="dialog-buttons">
                        <Button
                            dataTestId="redefinePasswordDialog-submit"
                            type="submit"
                            disabled={isRequestingRedefinePwd}
                            loading={isRequestingRedefinePwd}
                        >
                            <IntlMessages id='dialog.passwordRedefine.button.send' />
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

//PropTypes
RedefinePasswordDialog.propTypes = {
    userID: PropTypes.number.isRequired,
    hasChangedPass: PropTypes.bool.isRequired,
    setHasChangedPass: PropTypes.func.isRequired,
};

export default RedefinePasswordDialog;
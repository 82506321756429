import IntlMessages from 'components/util/IntlMessages';
import { useState } from "react";

// Icons 
import { ReactComponent as CookieIcon } from 'assets/images/icons/svg/cookie.svg';
import useCookies from 'hooks/cookies';
import CookieConsent from 'components/CookieConsent';
import { Button } from '@save2compete/efz-design-system';

const CookieBanner = ({
    setIsCustomizeDialogOpen,
    cookieConsentRef
}) => {

    const {
        setCookie,
        PLATFORM_CUSTOMIZATION_COOKIE,
        PERSONALIZED_ADVERTISING_COOKIE
    } = useCookies();

    const cookiesHandler = () => {
        setCookie(PLATFORM_CUSTOMIZATION_COOKIE, true, 355)
        setCookie(PERSONALIZED_ADVERTISING_COOKIE, true, 730)
        cookieConsentRef.current.accept()
    }
    const [policyTypeToShow, setPolicyTypeToShow] = useState(null);

    const showPolicyHandler = (type = null) => {
        if (!!type) {
            setPolicyTypeToShow(type);
        } else {
            setPolicyTypeToShow(null);
        }
    }

    return (
        <div className='d-flex'>
            <div className='cookie-banner-content'>
                <div className='cookie-banner-title'>
                    <CookieIcon className='me-2' />
                    <span className='ms-2'>
                        <IntlMessages id='label.cookies.title' />
                    </span>
                </div>
                <div className='cookie-banner-text'>
                    <IntlMessages id='label.cookies.content' whitespaceEnd />
                    <span className='link' onClick={() => showPolicyHandler('privacy-policy')} ><IntlMessages id='label.here' /></span>.
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center'>
                <Button
                    dataTestId='acceptCookies-button'
                    className="cookie-banner-button mb-3"
                    onClick={cookiesHandler}
                    variant="primary"
                >
                    <IntlMessages id='label.cookies.acceptCookies' />
                </Button>
                <Button
                    dataTestId='privacySettings-button'
                    className='fs-s'
                    onClick={() => setIsCustomizeDialogOpen(true)}
                    variant="tertiary"
                >
                    <IntlMessages id='label.cookies.privacySettings' />
                </Button>
                {!!policyTypeToShow && <CookieConsent type={policyTypeToShow} close={showPolicyHandler} showPolicyHandler={showPolicyHandler} />}
            </div>
        </div>
    )
}

export default CookieBanner;
const { createContext, useState } = require("react");

const TIRContext = createContext({});

const TIRContextProvider = ({ children }) => {

    const [isRequestingTIR, setIsRequestingTIR] = useState(false);


    return (
        <TIRContext.Provider
            value={{
                isRequestingTIR,
                setIsRequestingTIR
            }}
        >
            {children}
        </TIRContext.Provider>
    )
}

export { TIRContext, TIRContextProvider }
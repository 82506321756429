/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - ALL
 * @see redux reducers docs: (basic) https://redux.js.org/basics/reducers  || (combinereducers) https://redux.js.org/api/combinereducers
 *
 * 
 * @version 20210702 only state router
 * @version 20200918
 * @since 20200918 Initial release
 *
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import settings from './settings';

const combinedReducers = (history) => combineReducers({
    router: connectRouter(history),
    settings
})

export default combinedReducers;
import { PRODUCT_IDS } from "constants/product"
import { COMPANY_IDS } from "constants/settings"
import { USERS_TYPE_ID } from "constants/user"
import { COMMENTS_CHARACTERS_LIMIT } from ".."

export const SITE_SURVEY_NAMES = {
    REAL_POWER_INSTALLED_PV: 'installed_power_kwp_pv_ss',
    REAL_POWER_INSTALLED_CFP: 'installed_power_kvar_cfp_ss',
    REAL_POWER_INSTALLED_IE: 'installed_power_kw_el_ss',
    REAL_AC_POWER_INSTALLED: 'installed_power_em_kva_me_ss',
    REAL_DC_POWER_INSTALLED: 'installed_power_em_kw_me_ss',
    REAL_INVERTERS_POWER: 'nominal_power_inverters_kw_pv_ss',
    EQUIPMENT_QUANTITY: 'equipment_quantity_ss',
    BATTERY_QUANTITY: 'battery_quantity_ss',
    CHARGER_QUANTITY: 'charger_quantity_ss',
    PANNEL_QUANTITY: 'pannel_quantity_ss',
    HARMONIC_FILTER: 'harmonic_filter_pfc_ss',
    EMS: 'ems_ss',
    CONCENTRATOR_QUANTITY: 'ems_concentrators_quantity_ss',
    ANNUAL_PRODUCTION: 'annual_estimated_production_kwh_ss',
    ANNUAL_PRODUCTION_MWH: 'annual_estimated_production_mwh_ss',
    NEW_ANNUAL_PRODUCTION_KWH: 'new_annual_production_kWh',
    /* FINANCIAL */
    REAL_COST_EQUIPMENT: 'equipment_cost',
    REAL_COST_FACILITY: 'installation_cost',
    ANNUAL_MAINTENANCE_COST: 'annual_maintenance_cost',
    SHOW_VALID_PRODUCTION_WARNING: 'show_valid_production_warning',
    VALID_PRODUCTION_ASSUMPTIONS: 'valid_production_assumptions',
    COSTS_OUTSIDE_ASSUMPTIONS: 'costs_outside_assumptions',
    COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE: 'costs_outside_assumptions_attributable',
    TYPE_OF_COSTS: 'type_of_costs',
    TYPE_OF_PRODUCTION_INPUT: 'type_of_production_input',
    PANEL_COST: 'panel_cost',
    INVERTER_COST: 'inverter_cost',
    BATTERY_COST: 'battery_cost',
    MONITORING_COST: 'monitoring_cost',
    EXTRA_COST: 'extra_cost',
    /* UPLOAD FILES */
    UPLOAD_FILES: 'upload_files',
    CURVE_LOAD: 'curveLoad',
    COMMENTS: 'state_comments',
    /* TABS */
    TECHNICAL_DETAILS: 'technical_details',
    WORK: 'work',
    FINANCIAL_INFO_SURVEY_SITE: 'financial_info_survey_site',
    DOCUMENTS_SURVEY_SITE: 'documents_survey_site',
    /* NEW INPUTS */
    TOTAL_POWER: 'total_power',
    METERS_LENGTH: 'cable_total',
    /* WORK INPUTS */
    HAS_DITCH: 'has_ditch',
    NEEDS_GUTTER: 'needs_gutter',
    MASSIF_REQUIRED: 'massive_required',
    NEEDS_ELECTRIC_PANEL: 'needs_electric_panel',
    NEEDS_ELECTRIC_PROJECT: 'needs_electric_project',
    WORK_COMMENTS: 'work_comments',
    TRENCH_METERS: 'trench_meters',
    PIPE_METERS: 'pipe_meters',
    ELECTRICAL_CABINETS: 'electrical_cabinets'
}

export const SITE_SURVEY_TABS = {
    [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
        name: SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
        tabHeader: 'processUploadPricingDocs.tabHeader.technicalDetails',
        tabLabel: 'processUploadPricingDocs.tabLabel.technicalDetails',
        tabLabelGroup: 'processUploadPricingDocs.tabLabel.technicalDetails.groups'

    },
    [SITE_SURVEY_NAMES.WORK]: {
        name: SITE_SURVEY_NAMES.WORK,
        tabHeader: 'processUploadPricingDocs.label.work',
        tabLabel: 'processUploadPricingDocs.label.work'
    },
    [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
        name: SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
        tabHeader: 'processUploadPricingDocs.tabHeader.infoSiteSurvey',
        tabLabel: 'processUploadPricingDocs.tabLabel.infoSiteSurvey'
    },
    [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
        name: SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE,
        tabHeader: 'processUploadPricingDocs.label.documentsSurveySite',
        tabLabel: 'processUploadPricingDocs.label.documentsSurveySite'
    }
}

export const SITE_SURVEY_CONFIGS = {
    [COMPANY_IDS.EDP_PT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: false,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: true,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: true,
                        validation: {
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: true,
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                'edit': {
                    visible: true
                },
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: false,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: true,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: true,
                        validation: {
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: true,
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: true
                },
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_CFP]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_CFP,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kvar',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.HARMONIC_FILTER]: {
                        name: SITE_SURVEY_NAMES.HARMONIC_FILTER,
                        label: 'processUploadPricingDocs.label.harmonicFilter',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
    
                        }
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.condensatorBattery',
                        visible: true,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: false,
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.WORK,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_AC_POWER_INSTALLED]: {
                        name: SITE_SURVEY_NAMES.REAL_AC_POWER_INSTALLED,
                        label: 'processUploadPricingDocs.label.installedPowerAC',
                        unit: 'kva',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_DC_POWER_INSTALLED]: {
                        name: SITE_SURVEY_NAMES.REAL_DC_POWER_INSTALLED,
                        label: 'processUploadPricingDocs.label.installedPowerDC',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.HARMONIC_FILTER]: {
                        name: SITE_SURVEY_NAMES.HARMONIC_FILTER,
                        label: 'processUploadPricingDocs.label.harmonicFilter',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.WORK]: {
                    [SITE_SURVEY_NAMES.HAS_DITCH]: {
                        name: SITE_SURVEY_NAMES.HAS_DITCH,
                        label: 'processUploadPricingDocs.label.hasDitch',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_GUTTER]: {
                        name: SITE_SURVEY_NAMES.NEEDS_GUTTER,
                        label: 'processUploadPricingDocs.label.needsGutter',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.MASSIF_REQUIRED]: {
                        name: SITE_SURVEY_NAMES.MASSIF_REQUIRED,
                        label: 'processUploadPricingDocs.label.massifRequired',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PANEL]: {
                        name: SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PANEL,
                        label: 'processUploadPricingDocs.label.needsElectricPanel',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PROJECT]: {
                        name: SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PROJECT,
                        label: 'processUploadPricingDocs.label.needsElectricProject',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.WORK_COMMENTS]: {
                        name: SITE_SURVEY_NAMES.WORK_COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TRENCH_METERS]: {
                        name: SITE_SURVEY_NAMES.TRENCH_METERS,
                        label: 'processUploadPricingDocs.label.trenchMeters',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PIPE_METERS]: {
                        name: SITE_SURVEY_NAMES.PIPE_METERS,
                        label: 'processUploadPricingDocs.label.pipeMeters',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ELECTRICAL_CABINETS]: {
                        name: SITE_SURVEY_NAMES.ELECTRICAL_CABINETS,
                        label: 'processUploadPricingDocs.label.electricalCabinets',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.chargersCost',
                        tooltip: 'viewBudget.tooltip.chargerEquipment',
                        visible: true,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: false,
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
    
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                }
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: false
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: false,
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true
                    }
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: false
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: false,
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true
                    }
                },
            },
            [PRODUCT_IDS.CFP]: {
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_CFP]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_CFP,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kvar',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.HARMONIC_FILTER]: {
                        name: SITE_SURVEY_NAMES.HARMONIC_FILTER,
                        label: 'processUploadPricingDocs.label.harmonicFilter',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
            [PRODUCT_IDS.ME]: {
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.WORK,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_AC_POWER_INSTALLED]: {
                        name: SITE_SURVEY_NAMES.REAL_AC_POWER_INSTALLED,
                        label: 'processUploadPricingDocs.label.installedPowerAC',
                        unit: 'kva',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.REAL_DC_POWER_INSTALLED]: {
                        name: SITE_SURVEY_NAMES.REAL_DC_POWER_INSTALLED,
                        label: 'processUploadPricingDocs.label.installedPowerDC',
                        unit: 'kw',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.HARMONIC_FILTER]: {
                        name: SITE_SURVEY_NAMES.HARMONIC_FILTER,
                        label: 'processUploadPricingDocs.label.harmonicFilter',
                        visible: false
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: false
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.WORK]: {
                    [SITE_SURVEY_NAMES.HAS_DITCH]: {
                        name: SITE_SURVEY_NAMES.HAS_DITCH,
                        label: 'processUploadPricingDocs.label.hasDitch',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_GUTTER]: {
                        name: SITE_SURVEY_NAMES.NEEDS_GUTTER,
                        label: 'processUploadPricingDocs.label.needsGutter',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.MASSIF_REQUIRED]: {
                        name: SITE_SURVEY_NAMES.MASSIF_REQUIRED,
                        label: 'processUploadPricingDocs.label.massifRequired',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PANEL]: {
                        name: SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PANEL,
                        label: 'processUploadPricingDocs.label.needsElectricPanel',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PROJECT]: {
                        name: SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PROJECT,
                        label: 'processUploadPricingDocs.label.needsElectricProject',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.WORK_COMMENTS]: {
                        name: SITE_SURVEY_NAMES.WORK_COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TRENCH_METERS]: {
                        name: SITE_SURVEY_NAMES.TRENCH_METERS,
                        label: 'processUploadPricingDocs.label.trenchMeters',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PIPE_METERS]: {
                        name: SITE_SURVEY_NAMES.PIPE_METERS,
                        label: 'processUploadPricingDocs.label.pipeMeters',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ELECTRICAL_CABINETS]: {
                        name: SITE_SURVEY_NAMES.ELECTRICAL_CABINETS,
                        label: 'processUploadPricingDocs.label.electricalCabinets',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
        }
    },
    [COMPANY_IDS.EDP_ES]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: false,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: true,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: true,
                        validation: {
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: true,
                    }
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
            [PRODUCT_IDS.CFP]: {
                'edit': {
                    visible: true
                },
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_CFP]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_CFP,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kvar',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.HARMONIC_FILTER]: {
                        name: SITE_SURVEY_NAMES.HARMONIC_FILTER,
                        label: 'processUploadPricingDocs.label.harmonicFilter',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
    
                        }
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.condensatorBattery',
                        visible: true,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: false,
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
            [PRODUCT_IDS.ME]: {
                'edit': {
                    visible: true
                },
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.WORK,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_AC_POWER_INSTALLED]: {
                        name: SITE_SURVEY_NAMES.REAL_AC_POWER_INSTALLED,
                        label: 'processUploadPricingDocs.label.installedPowerAC',
                        unit: 'kva',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_DC_POWER_INSTALLED]: {
                        name: SITE_SURVEY_NAMES.REAL_DC_POWER_INSTALLED,
                        label: 'processUploadPricingDocs.label.installedPowerDC',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.HARMONIC_FILTER]: {
                        name: SITE_SURVEY_NAMES.HARMONIC_FILTER,
                        label: 'processUploadPricingDocs.label.harmonicFilter',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                },
                [SITE_SURVEY_NAMES.WORK]: {
                    [SITE_SURVEY_NAMES.HAS_DITCH]: {
                        name: SITE_SURVEY_NAMES.HAS_DITCH,
                        label: 'processUploadPricingDocs.label.hasDitch',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_GUTTER]: {
                        name: SITE_SURVEY_NAMES.NEEDS_GUTTER,
                        label: 'processUploadPricingDocs.label.needsGutter',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.MASSIF_REQUIRED]: {
                        name: SITE_SURVEY_NAMES.MASSIF_REQUIRED,
                        label: 'processUploadPricingDocs.label.massifRequired',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PANEL]: {
                        name: SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PANEL,
                        label: 'processUploadPricingDocs.label.needsElectricPanel',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PROJECT]: {
                        name: SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PROJECT,
                        label: 'processUploadPricingDocs.label.needsElectricProject',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.WORK_COMMENTS]: {
                        name: SITE_SURVEY_NAMES.WORK_COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TRENCH_METERS]: {
                        name: SITE_SURVEY_NAMES.TRENCH_METERS,
                        label: 'processUploadPricingDocs.label.trenchMeters',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PIPE_METERS]: {
                        name: SITE_SURVEY_NAMES.PIPE_METERS,
                        label: 'processUploadPricingDocs.label.pipeMeters',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ELECTRICAL_CABINETS]: {
                        name: SITE_SURVEY_NAMES.ELECTRICAL_CABINETS,
                        label: 'processUploadPricingDocs.label.electricalCabinets',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.chargersCost',
                        tooltip: 'viewBudget.tooltip.chargerEquipment',
                        visible: true,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: false,
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
    
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                }
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: false
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: false,
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true
                    }
                },
            },
            [PRODUCT_IDS.CFP]: {
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_CFP]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_CFP,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kvar',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.HARMONIC_FILTER]: {
                        name: SITE_SURVEY_NAMES.HARMONIC_FILTER,
                        label: 'processUploadPricingDocs.label.harmonicFilter',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: false,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
            [PRODUCT_IDS.ME]: {
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.WORK,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_AC_POWER_INSTALLED]: {
                        name: SITE_SURVEY_NAMES.REAL_AC_POWER_INSTALLED,
                        label: 'processUploadPricingDocs.label.installedPowerAC',
                        unit: 'kva',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.REAL_DC_POWER_INSTALLED]: {
                        name: SITE_SURVEY_NAMES.REAL_DC_POWER_INSTALLED,
                        label: 'processUploadPricingDocs.label.installedPowerDC',
                        unit: 'kw',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.HARMONIC_FILTER]: {
                        name: SITE_SURVEY_NAMES.HARMONIC_FILTER,
                        label: 'processUploadPricingDocs.label.harmonicFilter',
                        visible: false
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: false
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TOTAL_POWER]: {
                        name: SITE_SURVEY_NAMES.TOTAL_POWER,
                        label: 'processUploadPricingDocs.label.totalPower',
                        unit: 'kw',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    },
                    [SITE_SURVEY_NAMES.METERS_LENGTH]: {
                        name: SITE_SURVEY_NAMES.METERS_LENGTH,
                        label: 'processUploadPricingDocs.label.metersLength',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        },
                    }
                },
                [SITE_SURVEY_NAMES.WORK]: {
                    [SITE_SURVEY_NAMES.HAS_DITCH]: {
                        name: SITE_SURVEY_NAMES.HAS_DITCH,
                        label: 'processUploadPricingDocs.label.hasDitch',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_GUTTER]: {
                        name: SITE_SURVEY_NAMES.NEEDS_GUTTER,
                        label: 'processUploadPricingDocs.label.needsGutter',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.MASSIF_REQUIRED]: {
                        name: SITE_SURVEY_NAMES.MASSIF_REQUIRED,
                        label: 'processUploadPricingDocs.label.massifRequired',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PANEL]: {
                        name: SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PANEL,
                        label: 'processUploadPricingDocs.label.needsElectricPanel',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PROJECT]: {
                        name: SITE_SURVEY_NAMES.NEEDS_ELECTRIC_PROJECT,
                        label: 'processUploadPricingDocs.label.needsElectricProject',
                        visible: true,
                        editable: true
                    },
                    [SITE_SURVEY_NAMES.WORK_COMMENTS]: {
                        name: SITE_SURVEY_NAMES.WORK_COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.TRENCH_METERS]: {
                        name: SITE_SURVEY_NAMES.TRENCH_METERS,
                        label: 'processUploadPricingDocs.label.trenchMeters',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PIPE_METERS]: {
                        name: SITE_SURVEY_NAMES.PIPE_METERS,
                        label: 'processUploadPricingDocs.label.pipeMeters',
                        unit: 'm',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ELECTRICAL_CABINETS]: {
                        name: SITE_SURVEY_NAMES.ELECTRICAL_CABINETS,
                        label: 'processUploadPricingDocs.label.electricalCabinets',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true
                    },
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
        }
    },
    [COMPANY_IDS.EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: true,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: true,
                        validation: {
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: true,
                    }
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
    
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                },
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: false
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: false,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    },
    
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                    }
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true
                    }
                }
            },
        }
    },
    [COMPANY_IDS.EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                        }
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: true,
                        editable: true,
                        validation: {
                            required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: true,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: true,
                        validation: {
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: true,
                    },
    
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.EXTRA_COST]: {
                        name: SITE_SURVEY_NAMES.EXTRA_COST,
                        label: 'viewBudget.label.extraCost',
                        validation: {
                            required: "yup.message.required",
                        },
                        visible: true,
                    }
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
    
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true,
                    }
                }
            }
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                'sections': {
                    visible: [
                        SITE_SURVEY_NAMES.TECHNICAL_DETAILS,
                        SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE,
                        SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE
                    ]
                },
                [SITE_SURVEY_NAMES.TECHNICAL_DETAILS]: {
                    [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                        name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                        label: 'processUploadPricingDocs.label.installedPower',
                        unit: 'kwp',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                        name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                        label: 'processUploadPricingDocs.label.nominalPower',
                        unit: 'kw',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                        label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.EMS]: {
                        name: SITE_SURVEY_NAMES.EMS,
                        label: 'processUploadPricingDocs.label.ems',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                        name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                        label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                        name: SITE_SURVEY_NAMES.CURVE_LOAD,
                        label: 'processUploadPricingDocs.label.uploadLoadCurve',
                        subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                        min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                        hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                        downloadLabel: 'label.uploaded',
                        invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                        managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                        visible: true,
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                        label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                        typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                        unit: 'mwh',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    }
                },
                [SITE_SURVEY_NAMES.FINANCIAL_INFO_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                        label: 'processUploadPricingDocs.label.realCostFacility',
                        visible: true
                    },
                    [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                        name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                        label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                        visible: true,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                        name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                        label: 'viewBudget.label.typeOfCost',
                        visible: false
                    },
                    [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                        name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                        label: 'viewBudget.label.totalEquipmentCost',
                        visible: false,
                        validation: {
                            // required: "yup.message.required",
                            min: {
                                value: 0,
                                message: 'yup.message.number.positive'
                            },
                        }
                    },
                    [SITE_SURVEY_NAMES.PANEL_COST]: {
                        name: SITE_SURVEY_NAMES.PANEL_COST,
                        label: 'viewBudget.label.panelsCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.INVERTER_COST]: {
                        name: SITE_SURVEY_NAMES.INVERTER_COST,
                        label: 'viewBudget.label.invertersCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.BATTERY_COST]: {
                        name: SITE_SURVEY_NAMES.BATTERY_COST,
                        label: 'viewBudget.label.batteryCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.MONITORING_COST]: {
                        name: SITE_SURVEY_NAMES.MONITORING_COST,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                    [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.validProductionAssumptions',
                        visible: false,
                    },
    
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                        label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                        visible: false,
                    },
                    [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                        name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                        label: 'viewBudget.label.monitoringCost',
                        visible: false,
                    }
                },
                [SITE_SURVEY_NAMES.DOCUMENTS_SURVEY_SITE]: {
                    [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                        name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                        label: 'processUploadPricingDocs.label.uploadFiles',
                        visible: true,
                    },
    
                    [SITE_SURVEY_NAMES.COMMENTS]: {
                        name: SITE_SURVEY_NAMES.COMMENTS,
                        label: 'processUploadPricingDocs.label.comments',
                        validation: {
                            required: "yup.message.required",
                            maxLength: {
                                value: COMMENTS_CHARACTERS_LIMIT,
                                message: 'yup.message.string.maxCharacters'
                            },
                            visible: true,
                        },
                        visible: true
                    }
                }
            }
        }
    },
    [COMPANY_IDS.EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                'edit': {
                    visible: true
                },
                [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                    name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                    label: 'processUploadPricingDocs.label.installedPower',
                    unit: 'kwp',
                    visible: true,
                    editable: true,
                    validation: {
                        required: "yup.message.required",
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    }
                },
                [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                    name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                    label: 'processUploadPricingDocs.label.nominalPower',
                    unit: 'kw',
                    visible: true,
                    editable: true,
                    validation: {
                        required: "yup.message.required",
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    }
                },
                [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                    name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                    label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: "yup.message.required",
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    }
                },
                [SITE_SURVEY_NAMES.EMS]: {
                    name: SITE_SURVEY_NAMES.EMS,
                    label: 'processUploadPricingDocs.label.ems',
                    visible: true,
                    editable: true,
                    validation: {
                        required: "yup.message.required",
                    }
                },
                [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                    name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                    label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: "yup.message.required",
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    }
                },
                [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                    name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                    label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                    typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                    unit: 'mwh',
                    visible: true,
                    editable: true,
                    validation: {
                        required: "yup.message.required",
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    }
                },
                [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                    name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                    label: 'processUploadPricingDocs.label.realCostFacility',
                    visible: true,
                    editable: true,
                    validation: {
                        required: "yup.message.required",
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    }
                },
                [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                    name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                    label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                    visible: false,
                    editable: true,
                    validation: {
                        required: "yup.message.required",
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    }
                },
                [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                    name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                    label: 'viewBudget.label.typeOfCost',
                    visible: true
                },
                [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                    name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                    label: 'viewBudget.label.totalEquipmentCost',
                    visible: true,
                    validation: {
                        // required: "yup.message.required",
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    }
                },
                [SITE_SURVEY_NAMES.PANEL_COST]: {
                    name: SITE_SURVEY_NAMES.PANEL_COST,
                    label: 'viewBudget.label.panelsCost',
                    visible: true,
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive'
                    },
                },
                [SITE_SURVEY_NAMES.INVERTER_COST]: {
                    name: SITE_SURVEY_NAMES.INVERTER_COST,
                    label: 'viewBudget.label.invertersCost',
                    visible: true,
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive'
                    },
                },
                [SITE_SURVEY_NAMES.BATTERY_COST]: {
                    name: SITE_SURVEY_NAMES.BATTERY_COST,
                    label: 'viewBudget.label.batteryCost',
                    visible: true,
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive'
                    },
                },
                [SITE_SURVEY_NAMES.MONITORING_COST]: {
                    name: SITE_SURVEY_NAMES.MONITORING_COST,
                    label: 'viewBudget.label.monitoringCost',
                    visible: true,
                    validation: {
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    },
                },
                [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                    name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                    name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                    name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                    visible: true,
                },
                [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                    name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                    visible: true,
                },
                [SITE_SURVEY_NAMES.EXTRA_COST]: {
                    name: SITE_SURVEY_NAMES.EXTRA_COST,
                    label: 'viewBudget.label.extraCost',
                    validation: {
                        required: "yup.message.required",
                    },
                    visible: true,
                },
                [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                    name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                    label: 'processUploadPricingDocs.label.uploadFiles',
                    visible: true,
                },
                [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                    name: SITE_SURVEY_NAMES.CURVE_LOAD,
                    label: 'processUploadPricingDocs.label.uploadLoadCurve',
                    subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                    min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                    hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                    downloadLabel: 'label.uploaded',
                    invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                    managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                    visible: true,
                },
                [SITE_SURVEY_NAMES.COMMENTS]: {
                    name: SITE_SURVEY_NAMES.COMMENTS,
                    label: 'processUploadPricingDocs.label.comments',
                    validation: {
                        required: "yup.message.required",
                        maxLength: {
                            value: COMMENTS_CHARACTERS_LIMIT,
                            message: 'yup.message.string.maxCharacters'
                        },
                        visible: true,
                    },
                    visible: true,
                }
            },
        },
        [USERS_TYPE_ID.PARTNER]: {
            [PRODUCT_IDS.SPV]: {
                [SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV]: {
                    name: SITE_SURVEY_NAMES.REAL_POWER_INSTALLED_PV,
                    label: 'processUploadPricingDocs.label.installedPower',
                    unit: 'kwp',
                    visible: true
                },
                [SITE_SURVEY_NAMES.REAL_INVERTERS_POWER]: {
                    name: SITE_SURVEY_NAMES.REAL_INVERTERS_POWER,
                    label: 'processUploadPricingDocs.label.nominalPower',
                    unit: 'kw',
                    visible: true
                },
                [SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY]: {
                    name: SITE_SURVEY_NAMES.EQUIPMENT_QUANTITY,
                    label: 'processUploadPricingDocs.tooltip.equipmentQuantity',
                    visible: true
                },
                [SITE_SURVEY_NAMES.EMS]: {
                    name: SITE_SURVEY_NAMES.EMS,
                    label: 'processUploadPricingDocs.label.ems',
                    visible: true
                },
                [SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY]: {
                    name: SITE_SURVEY_NAMES.CONCENTRATOR_QUANTITY,
                    label: 'processUploadPricingDocs.label.emsConcentratorsQuantity',
                    visible: true
                },
                [SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH]: {
                    name: SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH,
                    label: 'processUploadPricingDocs.label.annualEstimatedProduction',
                    typeLabel: 'processUploadPricingDocs.label.typeOfProductionInput',
                    unit: 'mwh',
                    visible: true
                },
                [SITE_SURVEY_NAMES.REAL_COST_FACILITY]: {
                    name: SITE_SURVEY_NAMES.REAL_COST_FACILITY,
                    label: 'processUploadPricingDocs.label.realCostFacility',
                    visible: true
                },
                [SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST]: {
                    name: SITE_SURVEY_NAMES.ANNUAL_MAINTENANCE_COST,
                    label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                    visible: false,
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive'
                    },
                },
                [SITE_SURVEY_NAMES.TYPE_OF_COSTS]: {
                    name: SITE_SURVEY_NAMES.TYPE_OF_COSTS,
                    label: 'viewBudget.label.typeOfCost',
                    visible: false
                },
                [SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT]: {
                    name: SITE_SURVEY_NAMES.REAL_COST_EQUIPMENT,
                    label: 'viewBudget.label.totalEquipmentCost',
                    visible: false,
                    validation: {
                        // required: "yup.message.required",
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive'
                        },
                    }
                },
                [SITE_SURVEY_NAMES.PANEL_COST]: {
                    name: SITE_SURVEY_NAMES.PANEL_COST,
                    label: 'viewBudget.label.panelsCost',
                    visible: false,
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive'
                    },
                },
                [SITE_SURVEY_NAMES.INVERTER_COST]: {
                    name: SITE_SURVEY_NAMES.INVERTER_COST,
                    label: 'viewBudget.label.invertersCost',
                    visible: false,
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive'
                    },
                },
                [SITE_SURVEY_NAMES.BATTERY_COST]: {
                    name: SITE_SURVEY_NAMES.BATTERY_COST,
                    label: 'viewBudget.label.batteryCost',
                    visible: false,
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive'
                    },
                },
                [SITE_SURVEY_NAMES.MONITORING_COST]: {
                    name: SITE_SURVEY_NAMES.MONITORING_COST,
                    label: 'viewBudget.label.monitoringCost',
                    visible: false,
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive'
                    },
                },
                [SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                    name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: false,
                },
                [SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                    name: SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: false,
                },
                [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                    name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                    visible: false,
                },
                [SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                    name: SITE_SURVEY_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                    label: 'viewBudget.label.monitoringCost',
                    visible: false,
                },
                [SITE_SURVEY_NAMES.UPLOAD_FILES]: {
                    name: SITE_SURVEY_NAMES.UPLOAD_FILES,
                    label: 'processUploadPricingDocs.label.uploadFiles',
                    visible: true,
                },
                [SITE_SURVEY_NAMES.CURVE_LOAD]: {
                    name: SITE_SURVEY_NAMES.CURVE_LOAD,
                    label: 'processUploadPricingDocs.label.uploadLoadCurve',
                    subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                    min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                    hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                    downloadLabel: 'label.uploaded',
                    invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                    managerLabel: 'processUploadPricingDocs.label.productionCurveLoaded',
                    visible: true,
                },
                [SITE_SURVEY_NAMES.COMMENTS]: {
                    name: SITE_SURVEY_NAMES.COMMENTS,
                    label: 'processUploadPricingDocs.label.comments',
                    validation: {
                        required: "yup.message.required",
                        maxLength: {
                            value: COMMENTS_CHARACTERS_LIMIT,
                            message: 'yup.message.string.maxCharacters'
                        },
                        visible: true,
                    },
                    visible: true
                }
            },
        }
    },
}

export const TYPES_OF_PRODUCTION_INPUT = {
    MANUAL: 'manual',
    CURVE: 'curve'
}
/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description API requests to the context of Auth (LogIn, Registry)
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */

import base64 from 'react-native-base64';
import axios from 'services/axios';
import {
    URL_AUTH,
    URL_USERS
} from 'constants/endpoints'
import {
    NETWORK_ERROR
} from "constants/statusCodeHttp";

import {
    getEndpoint
} from "services/endpoints";

/**
 * LogIn
 *
 * @param data
 * @type Object {username: string, password: string}
 * @returns {Promise<AxiosResponse<T> | never>}
 */

const CancelToken = axios.CancelToken;
let authCancel;
let putResetPwdCancelExecutor;

export const auth = (data) => {
    authCancel && authCancel();

    return axios.post(getEndpoint(URL_AUTH),
        {},
        {
            headers: {
                Authorization: "Basic " + base64.encode(data.username + ":" + data.password),
                'Content-Type': 'application/json',
                timeout: 15000,
            },
            cancelToken: new CancelToken(function executor(c) {
                authCancel = c;// An executor function receives a cancel function as a parameter
            }),
        }).then(response => (response))
        .catch(error => (error.response ? error.response : NETWORK_ERROR));
};

export const getResetPassword = (email) => {
    return axios.get(
        getEndpoint(URL_USERS) + '/credentials-email/' + email
    ).then(response => (response)).catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
};

export const putRedefinePassword = (userID, body) => {
    putResetPwdCancelExecutor && putResetPwdCancelExecutor();
    return axios.put(
        `${getEndpoint(URL_USERS)}/${userID}`,
        body,
        {
            cancelToken: new CancelToken(function executor(c) {
                putResetPwdCancelExecutor = c;
            })
        }
    ).then(response => (response)).catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
};

export const getApiTheme = () => {
    return axios.get(
        getEndpoint(URL_USERS) + '/theme'
    ).then(response => (response)).catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
};
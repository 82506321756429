import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconButton } from '@save2compete/efz-design-system';


const GenericDialog = ({ open, title, body, buttons, handleClose, className }) => {
    return (
        <Dialog onClose={handleClose} open={open} className={`generic-dialog ${className ?? ''}`} maxWidth='sm'>
              <IconButton
                dataTestId="generic-dialog-close"
                className={'generic-dialog-close'}
                onClick={handleClose}
                icon="xMarkExit"
                variant="tertiary"/>
            <DialogTitle
                onClose={handleClose}
                className="generic-dialog-header"
            >
                {title}
            </DialogTitle>
            <DialogContent className="generic-dialog-body">
                {body}
            </DialogContent>
            <DialogActions className="generic-dialog-actions d-flex justify-content-center">
                {buttons}
            </DialogActions>
        </Dialog>
    )
}

GenericDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    // This is comment just to check the warnings off
    /*     title: PropTypes.elementType.isRequired,
        body: PropTypes.elementType.isRequired,
        buttons: PropTypes.elementType.isRequired, */
    handleClose: PropTypes.func.isRequired,
}

export default memo(GenericDialog)

import { createContext, useContext, useMemo } from "react";
import { bool, object } from "prop-types";

// Constants
import { GENERAL_INFORMATION_CONFIGS } from "constants/processDetails/configs";

// Contexts
import UserContext from "contexts/userContext";
import { ProcessDetailsContext } from "contexts/processDetailsContext";

// Services
import { areReformulatedProposals } from "services/forms/processDetails";

const GeneralInformationContext = createContext({
    configGeneralInformation: object,
    areReformulatedProposals: bool
});

const GeneralInformationContextProvider = ({ children }) => {

    const {
        userTypeID,
        userCompanyID
    } = useContext(UserContext);

    const {
        processDetails,
        processDetailsTab
    } = useContext(ProcessDetailsContext)

    const configGeneralInformation = useMemo(() => (
        GENERAL_INFORMATION_CONFIGS?.[userCompanyID]?.[userTypeID]?.[processDetails?.[processDetailsTab]?.product_type_id]
    ), [processDetails,userCompanyID,userTypeID,processDetailsTab]);

    return (
        <GeneralInformationContext.Provider
            value={{
                configGeneralInformation,
                areReformulatedProposals: areReformulatedProposals(processDetails ?? [])
            }}
        >
            {children}
        </GeneralInformationContext.Provider>
    )
}

export {GeneralInformationContext, GeneralInformationContextProvider}
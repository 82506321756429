import { useState, createContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSettings from 'hooks/settings';
import AppLocale from 'lngProvider';
import { LOCALE_PICKERS } from 'constants/user';

const SettingsContext = createContext({});

export const SettingsProvider = ({ children }) => {
    
    const locale = useSelector((state) => state?.settings?.locale);
    const [darkTheme, setDarkTheme] = useState(false);
    
    const { switchLang } = useSettings();

    const currentAppLocale = useMemo(() => AppLocale[locale.locale], [locale]);
    const localePickers = useMemo(() => LOCALE_PICKERS[locale.locale], [locale])

    return (
        <SettingsContext.Provider value={
            { 
                locale,
                darkTheme,
                setDarkTheme,
                switchLang,
                currentAppLocale,
                localePickers
            }
        }>
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsContext;
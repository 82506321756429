import { de, enGB, es, it, pl, pt } from 'date-fns/locale';
import defaultTheme from 'themes/defaultTheme';
import edpTheme from 'themes/edpTheme';
import { AVAILABLE_USER_LOCALES } from './user';

// Number of characters needed to trigger a search on input (used to optimise API response speeds)
export const MIN_CHARACTERES_TO_REQUEST = 3;

// * DEFAULT OBJECTS * \\

export const ENVIRONMENTS_FE = {
    DEV: 'DEV',
    TST: 'TST',
    PRD: 'PRD',
};

export const AVAILABLE_LOCALES = {
    PT: 'pt',
    ES: 'es',
    EN: 'en',
    PL: 'pl',
    IT: 'it',
    GR: 'gr',
    DE: 'de'
}
export const COMPANY_IDS = {
    EDP_PT: 1,
    EDP_ES: 2,
    EDP_UK: 3, //Field 2.0
    EDP_IT: 6,
    EFZ: 7, // Demo
    EDP_PL: 8,
    EFZ_PPC: 15,
    BASE_UK_B2B: 18 //FIXME: não sei o numero
}

export const AVAILABLE_COMPANIES = [
    {
        id: COMPANY_IDS.EDP_PT,
        name: 'EDP',
        fullName: 'EDP PT',
        theme: edpTheme,
        usePoweredBy: true
    },
    {
        id: COMPANY_IDS.EDP_ES,
        name: 'EDP',
        fullName: 'EDP ES',
        theme: edpTheme,
        usePoweredBy: true
    },
    {
        id: COMPANY_IDS.EDP_UK,
        name: 'EDP',
        fullName: 'EDP UK',
        theme: edpTheme,
        usePoweredBy: true
    },
    {
        id: COMPANY_IDS.EDP_IT,
        name: 'EDP',
        fullName: 'EDP IT',
        theme: edpTheme,
        usePoweredBy: true
    },
    {
        id: COMPANY_IDS.EDP_PL,
        name: 'EDP',
        fullName: 'EDP PL',
        theme: edpTheme,
        usePoweredBy: true
    },
    {
        id: COMPANY_IDS.EFZ_PPC,
        name: 'EFZ_PPC',
        fullName: 'EFZ PPC',
        theme: defaultTheme,
        usePoweredBy: true
    },
    {
        id: COMPANY_IDS.EFZ,
        name: 'EFZ DEMO',
        fullName: 'EFZ Demo',
        theme: defaultTheme,
        usePoweredBy: true
    },
    {
        id: COMPANY_IDS.BASE_UK_B2B,
        name: 'EFZ_BASE_UK_B2B',
        fullName: 'EFZ Base UK B2B',
        theme: defaultTheme,
        usePoweredBy: true
    },
];
export const AVAILABLE_LANGUAGES = [
    {
        languageId: 'portuguese',
        locale: AVAILABLE_LOCALES.PT,
        userLocale: AVAILABLE_USER_LOCALES.ptPT,
        isoLocale: AVAILABLE_LOCALES.PT,
        localePickers: AVAILABLE_LOCALES.PT,
        name: 'label.portuguese',
        nameTranslated: 'label.portugueseTranslated',
        icon: AVAILABLE_LOCALES.PT,
        flagIcon: `assets/images/icons/svg/flags/pt.svg`,
        dateLocale: pt
    },
    {
        languageId: 'spanish',
        locale: AVAILABLE_LOCALES.ES,
        userLocale: AVAILABLE_USER_LOCALES.esES,
        isoLocale: AVAILABLE_LOCALES.ES,
        localePickers: AVAILABLE_LOCALES.ES,
        name: 'label.spanish',
        nameTranslated: 'label.spanishTranslated',
        icon: AVAILABLE_LOCALES.ES,
        flagIcon: `assets/images/icons/svg/flags/es.svg`,
        dateLocale: es
    },
    {
        languageId: 'english',
        locale: AVAILABLE_LOCALES.EN,
        userLocale: AVAILABLE_USER_LOCALES.enGB,
        isoLocale: AVAILABLE_LOCALES.EN,
        localePickers: 'en-gb',
        name: 'label.english',
        nameTranslated: 'label.englishTranslated',
        icon: 'gb',
        flagIcon: `assets/images/icons/svg/flags/gb.svg`,
        dateLocale: enGB
    },
    {
        languageId: 'czech',
        locale: AVAILABLE_LOCALES.CZ,
        userLocale: AVAILABLE_USER_LOCALES.czCZ,
        isoLocale: AVAILABLE_LOCALES.CZ,
        localePickers: 'cs',
        name: 'label.czech',
        nameTranslated: 'label.czechTranslated',
        icon: AVAILABLE_LOCALES.CZ,
        flagIcon: `assets/images/icons/svg/flags/cz.svg`,
        dateLocale: enGB
    },
    {
        languageId: 'italian',
        locale: AVAILABLE_LOCALES.IT,
        userLocale: AVAILABLE_USER_LOCALES.itIT,
        isoLocale: AVAILABLE_LOCALES.IT,
        localePickers: AVAILABLE_LOCALES.IT,
        name: 'label.italian',
        nameTranslated: 'label.italianTranslated',
        icon: AVAILABLE_LOCALES.IT,
        flagIcon: `assets/images/icons/svg/flags/it.svg`,
        dateLocale: it
    },
    {
        languageId: 'polish',
        locale: AVAILABLE_LOCALES.PL,
        userLocale: AVAILABLE_USER_LOCALES.plPL,
        isoLocale: AVAILABLE_LOCALES.PL,
        localePickers: AVAILABLE_LOCALES.PL,
        name: 'label.polish',
        nameTranslated: 'label.polishTranslated',
        icon: AVAILABLE_LOCALES.PL,
        flagIcon: `assets/images/icons/svg/flags/pl.svg`,
        dateLocale: pl
    },
    {
        languageId: 'German (Switzerland)',
        locale: AVAILABLE_LOCALES.DE,
        userLocale: AVAILABLE_USER_LOCALES.deCH,
        isoLocale: 'de',
        localePickers: 'de-ch',
        name: 'label.deutsch',
        nameTranslated: 'label.deutschTranslated',
        icon: AVAILABLE_LOCALES.DE,
        flagIcon: `assets/images/icons/svg/flags/de.svg`,
        dateLocale: de
    },
    {
        languageId: 'German',
        locale: AVAILABLE_LOCALES.DE,
        userLocale: AVAILABLE_USER_LOCALES.deDE,
        isoLocale: 'de',
        localePickers: 'de',
        name: 'label.deutsch',
        nameTranslated: 'label.deutschTranslated',
        icon: AVAILABLE_LOCALES.DE,
        flagIcon: `assets/images/icons/svg/flags/de.svg`,
        dateLocale: de
    },
    {
        languageId: 'Greek',
        locale: AVAILABLE_LOCALES.GR,
        userLocale: AVAILABLE_USER_LOCALES.elGR,
        isoLocale: AVAILABLE_LOCALES.GR,
        localePickers: 'el-gr',
        name: 'label.greek',
        nameTranslated: 'label.greekTranslated',
        icon: AVAILABLE_LOCALES.GR,
        flagIcon: `assets/images/icons/svg/flags/gb.svg`,
        dateLocale: enGB
    },
];
// * ACTION TYPES * \\




//Registry const
export const BASENAME_URL = '/';
export const BASENAME_URL_LOGIN = BASENAME_URL + 'login';
export const BASENAME_URL_APP = BASENAME_URL + 'app/';
export const BASENAME_URL_HOME = BASENAME_URL_APP + 'home';
export const BASENAME_URL_PROCESSES = BASENAME_URL_APP + 'processes';
export const BASENAME_URL_DASHBOARD = BASENAME_URL_APP + 'dashboard';
export const BASENAME_URL_COOKIES_POLICIES = BASENAME_URL_APP + 'cookie-policies';
export const BASENAME_URL_CREATE_PROCESS = BASENAME_URL_PROCESSES + '/create';
export const BASENAME_URL_CREATE_ASSET_MANAGEMENT = BASENAME_URL_PROCESSES + '/create-asset';
export const BASENAME_URL_CLIENT = BASENAME_URL_APP + 'client';



// CUSTOMIZER
export const THEME_COLOR = 'THEME_COLOR';
export const DARK_THEME = 'DARK_THEME';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';


// *  REDUX STATE * \\
export const INITIAL_SETTINGS = {
    darkTheme: false,
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'label.english',
        icon: 'gb'
    }
};

//ACTION
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

// API MESSAGE TAGS 
export const API_ERROR_TAGS = {
    ERR_FILE_VALIDATION: 'ERR_FILE_VALIDATION',
    FILES_TOO_LARGE: 'FILES_TOO_LARGE',
    NO_FILES_AVAILABLE: 'NO_FILES_AVAILABLE',
    EMAIL_NOT_REGISTERED: 'EMAIL_NOT_REGISTERED',
    CLIENT_NOT_FOUND: 'CLIENT_NOT_FOUND',
    NO_PROPOSAL_FOUND: 'NO_PROPOSAL_FOUND',
    NO_PROCESS_FOUND: 'NO_PROCESS_FOUND'
}
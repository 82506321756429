import { lazy } from 'react';
import { connect } from "react-redux";
import lazyLoader from 'services/lazyLoader';
import { isUserFieldV2 } from 'services/user';
import { withTracker } from 'services/withTracker';

const ProcessesContainer = lazy(() => lazyLoader(() => import("pages/Restricted/Processes")));
const ProcessesContainerV2 = lazy(() => lazyLoader(() => import("pages/Restricted/Processes/indexV2")));


/**
 * @context Route to Processes List
 *
 * @param props
 * @returns {*}
 * @constructor
 */
 const Processes = (props) => ( isUserFieldV2(props.userTypeID) ? <ProcessesContainerV2/> : <ProcessesContainer/> );


const mapStateToProps = () => ({
});

export default connect(mapStateToProps,
    { }
)(withTracker(Processes, 'Processes List'));

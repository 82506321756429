import { createContext, useContext, useMemo } from "react";
import { array, number, object, shape } from "prop-types";

// Constants
import { FINANTIAL_INFORMATION_CONFIGS, FINANTIAL_INFORMATION_CONFIGS_DEFAULTS } from "constants/processDetails/configs";
import { PROCESS_DETAILS_INPUT_NAMES } from "constants/processDetails";

// Contexts
import UserContext from "contexts/userContext";
import { ProcessDetailsContext } from "contexts/processDetailsContext";

const FinantialInformationContext = createContext({
    configFinantialInformation: object,
    userCompanyID: number,
    userTypeID: number,
    preValues: object,
    posValues: object,
    processDetails: array,
    reformulateValues: object,
    oldProposalValues: shape({
        pre: object,
        pos: object
    })
});
FinantialInformationContext.displayName = 'efz-FinancialInfoContext'

const { FINANCIAL_INFORMATION, CONTRACT_ID, STATE_ID } = PROCESS_DETAILS_INPUT_NAMES;
const FinantialInformationContextProvider = ({ children }) => {

    const {
        userTypeID,
        userCompanyID
    } = useContext(UserContext);

    const {
        processDetails,
        processDetailsTab
    } = useContext(ProcessDetailsContext)

    const configFinantialInformation = useMemo(() => (
        FINANTIAL_INFORMATION_CONFIGS?.[userCompanyID]?.[userTypeID]?.[processDetails?.[processDetailsTab]?.product_type_id]?.[processDetails?.[processDetailsTab]?.payment_method]
        ?? FINANTIAL_INFORMATION_CONFIGS_DEFAULTS?.[userCompanyID]?.[userTypeID]?.[processDetails?.[processDetailsTab]?.product_type_id]
    ), [processDetails, userCompanyID, userTypeID, processDetailsTab]);

    const preValues = useMemo(() => processDetails?.[processDetailsTab]?.pre?.[FINANCIAL_INFORMATION], [processDetails, processDetailsTab])
    const posValues = useMemo(() => processDetails?.[processDetailsTab]?.pos?.[FINANCIAL_INFORMATION], [processDetails, processDetailsTab])
    // const reformulateValues = useMemo(() => processDetails?.[0]?.pos?.[FINANCIAL_INFORMATION], [processDetails]);
    const oldProposalValues = useMemo(() => ({
        pre: processDetails?.find(process => !process?.[PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED])?.pre?.[FINANCIAL_INFORMATION],
        pos: processDetails?.find(process => !process?.[PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED])?.pos?.[FINANCIAL_INFORMATION],
        contractID: processDetails?.find(process => !process?.[PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED])?.[CONTRACT_ID]
    }), [processDetails])

    const reformulateValues = useMemo(() => processDetails?.filter(process => process?.is_reformulated).map(process => process?.pos?.[FINANCIAL_INFORMATION]), [processDetails])

    return (
        <FinantialInformationContext.Provider
            value={{
                configFinantialInformation,
                userCompanyID,
                userTypeID,
                preValues,
                posValues,
                processDetails,
                reformulateValues,
                oldProposalValues,
                currentState: processDetails?.[0]?.[STATE_ID]
            }}
        >
            {children}
        </FinantialInformationContext.Provider>
    )
}

export { FinantialInformationContext, FinantialInformationContextProvider }
import {   
    IconButton,
} from '@save2compete/efz-design-system';

const CloseButton = ({ closeToast }) => {
    return (
        <IconButton 
            dataTestId="toast-close-button"
            size="bg"
            icon='xMarkExit'
            variant="tertiary"
        />
    );
};

export default CloseButton;
import { createContext, useState } from 'react';

const AutoCompleteContext = createContext({
    servicesManagers: [],
});

const AutoCompleteProvider = ({ children }) => {
    const [serviceManagerOptions, setServiceManagerOptions] = useState([]);


    return (
        <AutoCompleteContext.Provider value={
            {
                serviceManagerOptions,
                setServiceManagerOptions,
            }
        }>
            {children}
        </AutoCompleteContext.Provider>
    );
}

export { AutoCompleteContext, AutoCompleteProvider }
import { useContext, memo } from 'react';

import LanguageItem from 'components/Header/LanguageSwitcher/LanguageItem';
import { AVAILABLE_LANGUAGES, AVAILABLE_LOCALES } from "constants/settings";
import { isDevelopment } from 'services/settings';
import UserContext from 'contexts/userContext';

const LanguageSwitcher = ({ switchLanguage, handleRequestClose, activeLanguage }) => {
    const userLanguage = useContext(UserContext).language;
    const userNativeLanguage = AVAILABLE_LANGUAGES.find(language => language.locale === userLanguage);
    const englishLanguage = AVAILABLE_LANGUAGES.find(language => language.locale === AVAILABLE_LOCALES.EN);
    const portugueseLanguage = AVAILABLE_LANGUAGES.find(language => language.locale === AVAILABLE_LOCALES.PT);


    return (
        <div className="language-list">
            <ul className="list-unstyled px-0 m-0">
                {/* PORTUGUESE - DEV */}
                {(isDevelopment() && (!!userLanguage && userLanguage !== AVAILABLE_LOCALES.PT)) &&
                    <LanguageItem
                        key="language_list_1"
                        language={portugueseLanguage}
                        handleRequestClose={handleRequestClose}
                        switchLanguage={switchLanguage}
                        isActive={portugueseLanguage.icon === activeLanguage}
                    />
                }
                {/* USER LOCALE */}
                {!!userLanguage && userLanguage !== AVAILABLE_LOCALES.EN &&
                    <LanguageItem
                        key="language_list_2"
                        language={userNativeLanguage}
                        handleRequestClose={handleRequestClose}
                        switchLanguage={switchLanguage}
                        isActive={userNativeLanguage?.icon === activeLanguage}
                    />
                }
                {/* ENGLISH */}
                <LanguageItem
                    key="language_list_3"
                    language={englishLanguage}
                    handleRequestClose={handleRequestClose}
                    switchLanguage={switchLanguage}
                    isActive={englishLanguage.icon === activeLanguage}
                />
            </ul>
        </div>
    )
};

export default memo(LanguageSwitcher);


// import appLocaleData from 'react-intl/index';
import esMessages from 'lngProvider/locales/es_ES.json';

const saLang = {
  messages: {
    ...esMessages
  },
  locale: 'es-ES',
  // data: appLocaleData
};
export default saLang;

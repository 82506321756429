import { useEffect, useRef, useContext, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { IntlProvider } from 'react-intl';
import "assets/vendors/style"

import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';
import Error404 from 'components/Error404';
import CacheBuster from 'pages/CacheBuster';
import CloseButton from 'components/CloseButtonToast';
import CookieBanner from 'components/CookieBanner';
import IntlMessages from 'components/util/IntlMessages';
import Restricted from './Restricted';
import CustomizeCookiesDialog from 'components/CustomizeCookiesDialog';


// Contexts
import SettingsContext from 'contexts/settingsContext';

// Constants
import { BASENAME_URL } from "constants/settings";
import muiOverrides from 'constants/muiOverrides';
import useAPITheme from 'hooks/theme';
import { EfzThemeProvider } from '@save2compete/efz-design-system';




const App = ({
    match,
    location
}) => {

    const cookieConsentRef = useRef();

    const [isCustomizeDialogOpen, setIsCustomizeDialogOpen] = useState(false);

    const {
        theme
    } = useAPITheme()

    const {
        darkTheme,
        currentAppLocale,
        localePickers 
    } = useContext(SettingsContext);

    // Gets company thme or defaults to defaultTheme
    const appliedTheme = useRef(createTheme(theme));

    //componentDidMountWithUseEffect
    useEffect(() => {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    }, []);//eslint-disable-line


    // * THEME SELECTION * \\
    // Can't be inside a useEffect because it only triggers after the component finishes rendering, and we need the theme when it's rendering
    if (darkTheme) {
        appliedTheme.current = createTheme(
            { ...appliedTheme.current, palette: { ...appliedTheme.current.palette, mode: 'dark' } }
        );
    }
    else {
        // Material UI theme set
        appliedTheme.current = createTheme(theme);
        appliedTheme.current = {
            ...appliedTheme.current,
            ...muiOverrides
        };
    }

    // Set CSS Theme colors
    let bodyStyles = document.body.style;
    bodyStyles.setProperty('--primary-color', appliedTheme.current.palette.primary.main);
    bodyStyles.setProperty('--primary-color-light', appliedTheme.current.palette.primary.light);
    bodyStyles.setProperty('--primary-color-dark', appliedTheme.current.palette.primary.dark);
    bodyStyles.setProperty('--primary-color-bleached', appliedTheme.current.palette.primary.bleached);
    bodyStyles.setProperty('--secondary-color', appliedTheme.current.palette.secondary.main);
    bodyStyles.setProperty('--secondary-color-light', appliedTheme.current.palette.secondary.light);
    bodyStyles.setProperty('--secondary-color-dark', appliedTheme.current.palette.secondary.dark);
    if (!!appliedTheme.current.palette.tertiary) {
        bodyStyles.setProperty('--tertiary-color', appliedTheme.current.palette.tertiary.main);
        bodyStyles.setProperty('--tertiary-color-light', appliedTheme.current.palette.tertiary.light);
        bodyStyles.setProperty('--tertiary-color-dark', appliedTheme.current.palette.tertiary.dark);
    }
    bodyStyles.setProperty('--text-color', appliedTheme.current.palette.text.primary);
    bodyStyles.setProperty('--text-color-secondary', appliedTheme.current.palette.text.secondary);
    bodyStyles.setProperty('--background-color', appliedTheme.current.palette.background.default);
    bodyStyles.setProperty('--paper-color', appliedTheme.current.palette.background.paper);
    bodyStyles.setProperty('--divider-color', appliedTheme.current.palette.divider);
    bodyStyles.setProperty('--disabled-color', appliedTheme.current.palette.action.disabled);
    bodyStyles.setProperty('--disabled-bg-color', appliedTheme.current.palette.action.disabledBackground);

    return (
        <CacheBuster location={location}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={appliedTheme.current}>
                    <EfzThemeProvider theme={appliedTheme.current}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localePickers}>
                            <IntlProvider
                                locale={currentAppLocale.locale}
                                messages={currentAppLocale.messages}
                            >
                                <CssBaseline />
                                <div className="app-main">
                                    <Routes>
                                        <Route path={`app/*`} element={<Restricted />} />
                                        <Route path={`login`} element={<Login />} />
                                        <Route path={`${BASENAME_URL}reset-password`} element={<ResetPassword />} />
                                        <Route path={`${BASENAME_URL}`} element={<Login />} />
                                        <Route element={<Error404 />} />
                                    </Routes>
                                    <ToastContainer autoClose={2500} hideProgressBar newestOnTop={true} closeButton={CloseButton} />
                                    <CookieConsent
                                        ref={cookieConsentRef}
                                        buttonStyle={{ display: "none" }}
                                        containerClasses={'cookie-banner'}
                                        disableStyles={true}
                                        buttonClasses="btn cookie-banner-button MuiButton-root MuiButton-contained"
                                        buttonText={<IntlMessages id='label.cookies.acceptCookies' />}
                                        overlayClasses='cookie'
                                        overlay
                                    >
                                        <CookieBanner setIsCustomizeDialogOpen={setIsCustomizeDialogOpen} cookieConsentRef={cookieConsentRef} />
                                    </CookieConsent>
                                    {
                                        isCustomizeDialogOpen && <CustomizeCookiesDialog
                                            cookieBannerHandler={cookieConsentRef.current}
                                            isOpen={isCustomizeDialogOpen}
                                            setIsOpen={setIsCustomizeDialogOpen}
                                        />
                                    }

                                </div>
                            </IntlProvider>
                        </LocalizationProvider>
                    </ EfzThemeProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </CacheBuster>
    );
};
export default App;

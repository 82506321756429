import { arrayOf, func, number, object, shape } from "prop-types";
import { createContext, useState } from "react";

const ReasonsContext = createContext({
    reasons: shape({
        reformulatePSE: arrayOf(object),
        reformulateSM: arrayOf(object),
        reformulate: arrayOf(object),
        onHold: arrayOf(object),
        cancelled: arrayOf(object),
        infeasible: arrayOf(object),
    }),
    setReasons: func,
    reasonsPushed: arrayOf(number),
    setReasonsPushed: func
});

const ReasonsContextProvider = ({ children }) => {

    const [reasons, setReasons] = useState({
        reformulatePSE: [],
        reformulateSM: [],
        reformulate: [],
        onHold: [],
        cancelled: [],
        unfeasible: [] 
    });
    const [reasonsPushed, setReasonsPushed] = useState([]);

    return (
        <ReasonsContext.Provider
            value={{
                reasons,
                setReasons,
                reasonsPushed,
                setReasonsPushed
            }}
        >
            {children}
        </ReasonsContext.Provider>
    )
}

export { ReasonsContext, ReasonsContextProvider }
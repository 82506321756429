import { auth } from 'api/user';
import { OK } from 'constants/statusCodeHttp';
import jwt_decode from 'jwt-decode';
import { AVAILABLE_COMPANIES, AVAILABLE_LANGUAGES, COMPANY_IDS } from 'constants/settings';
import { INITIAL_USER } from 'constants/user';
import { getMessageError } from 'services/statusCodeHttp';
import useNotifications from 'hooks/notifications';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { GA_EVT_LOGIN, GA_TYPE_EVT } from 'constants/gAnalytics';
import {  isDefined } from 'services/util/auxiliaryUtils';
import { matomoEvt } from 'services/matomo';
import { MatomoEventTypes, MatomoEvents, MatomoSections } from 'constants/matomo';
import { getUserType } from 'services/user';
/**
 * 
 * @param {function} setIsUserAuthenticated 
 * @param {function} setIsLoadingLogin 
 * @param {function} setUser 
 * @param {function} switchLang 
 * @returns 
 */
const useAuth = (
    setIsUserAuthenticated,
    setIsLoadingLogin,
    setUser,
    switchLang
    ) => {

    const { toastNotify } = useNotifications(); 

    const getTokenInfo = (token) => {
        let jwtDecodeToken = jwt_decode(token);

        if (!isDefined(AVAILABLE_COMPANIES.find(company => company.id === jwtDecodeToken.user.company_id))) {
            jwtDecodeToken.user.company_id = COMPANY_IDS.EFZ;
        }
    
        let lngObj = AVAILABLE_LANGUAGES.find(obj => obj.locale === jwtDecodeToken.user.language);
        let companyName = AVAILABLE_COMPANIES.find(company => company.id === jwtDecodeToken.user.company_id).name;
        if (!!lngObj) {
            switchLang(lngObj);
        }
        return {
                ...jwtDecodeToken.user,
                companyName: companyName
            }
        ;
    }

    function handleLoginOK(token) {
        localStorage.setItem('userToken', token);
        setUser(
            getTokenInfo(token)
        );
        gAnalyticsEvt(GA_EVT_LOGIN?.[getTokenInfo(token).profile_id],GA_TYPE_EVT.LOGIN)
        matomoEvt({
            type: MatomoEvents.LOGIN,
            event: MatomoEventTypes.LOGIN,
            section: MatomoSections.USER_TYPE,
            value: getUserType(getTokenInfo(token).profile_id)
        })
        setIsUserAuthenticated(true);
    }

    const logoutHandler = () => {
        localStorage.removeItem("userToken");
        localStorage.removeItem("has_asset_management");
        setIsUserAuthenticated(false);
        setUser(INITIAL_USER);

    }

    const loginHandler = async (data) => {
        setIsLoadingLogin(true);
        const loginResponse = await auth(data);
        if (loginResponse?.status === OK) {
            const token = loginResponse?.data?.data?.token;
            handleLoginOK(token)
        } else {
            toastNotify('error',getMessageError(loginResponse))
        }
        setIsLoadingLogin(false);
    };

    return { 
        loginHandler,
        getTokenInfo,
        logoutHandler,
        switchLang
    }
};

export default useAuth;
/**
 * @author Miguel Cervera <miguel.cervera@caixamagica.pt>, Daniel Bastos <daniel.bastos@caixamagica.pt>
 *
 * @description Component Context - Individual Module Component
 * @article about ContainerComponent https://medium.com/@learnreact/container-components-c0e67432e005#.1a9j3w1jl
 *
 * @version 20200918
 * @since 20200918 Adding Modules
 *
 */

import { useEffect } from 'react';
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import IntlMessages from 'components/util/IntlMessages';


//Component
const Loading = ({size, className, useTopLoading, label, color, ...rest}) => {
    NProgress.configure({ showSpinner: false, trickleSpeed: 30 });

    if(!!useTopLoading && !NProgress.isStarted()) NProgress.start();

    useEffect(() => {
        return () => {
            if(!!useTopLoading) NProgress.done();
        };
    }, [useTopLoading]);

    return (
        <div className={`loading-component${className ? " " + className : ""}`} {...rest}>
            <CircularProgress size={!!size ? size : 30} color={color}/>
            { label &&
                <label>
                    <IntlMessages id={label} />
                </label>
            }
        </div>
    )
};

//PropTypes
Loading.propTypes = {
    size: PropTypes.number,
    className: PropTypes.string,
    useTopLoading: PropTypes.bool,
    label: PropTypes.string,
    color: PropTypes.string
}

export default Loading;
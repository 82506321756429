// import appLocaleData from 'react-intl/index';
import ptMessages from 'lngProvider/locales/pt_PT.json';

const ptLang = {
  messages: {
    ...ptMessages
  },
  locale: 'pt-PT',
  // data: appLocaleData
};
export default ptLang;

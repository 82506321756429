import { AuthProvider } from './authContext';
import { combineComponents } from 'services/util/context';
import { UserProvider } from './userContext';
import { SettingsProvider } from './settingsContext';

const providers = [
    UserProvider,
    SettingsProvider,
    AuthProvider,
]
export const AppContextProvider = combineComponents(...providers);
import { useState, createContext, useContext, useMemo } from 'react';
import { array, func, number, object, string } from 'prop-types';

// Configs
import { CONFIG_TIMELINE,STATE_SECTION  } from 'constants/timeline/configs';

// Contexts
import UserContext from './userContext';
import SettingsContext from './settingsContext';
import { ProcessDetailsContext } from './processDetailsContext';

// Services
import { getLastStateActive } from 'services/forms/processUpdateState';
import { areReformulatedProposals } from 'services/forms/processDetails';

const {
    TIR
} = STATE_SECTION;

export const TIRContext = createContext();
const TimelineContext = createContext({
    userTypeID: number,
    userCompanyID: number,
    timeline: array,
    setTimeline: func,
    activeStateConfig: object,
    activeState: number,
    fieldDataSS: object,
    locale: string,
    stateConfig: object,
    processDetails: array,
    setProcessDetails: func,
    areReformulatedProposals: array,
});
const TimelineContextProvider = ({ children }) => {

    const {
        userTypeID,
        userCompanyID
    } = useContext(UserContext);

    const {
        locale
    } = useContext(SettingsContext);

    const {
        processDetails,
        setProcessDetails,
    } = useContext(ProcessDetailsContext);

    const [timeline, setTimeline] = useState([]);

    let activeState = useMemo(() => getLastStateActive(timeline)?.state_id, [timeline]);

    const activeStateConfig = useMemo(() => (CONFIG_TIMELINE?.[userCompanyID]?.[userTypeID]?.[activeState])
        , [userCompanyID, userTypeID, activeState]);

    const stateConfig = useMemo(() => (CONFIG_TIMELINE?.[userCompanyID]?.[userTypeID])
        , [userCompanyID, userTypeID]);

    return (
        <TimelineContext.Provider
            value={{
                userTypeID,
                userCompanyID,
                timeline,
                setTimeline,
                activeStateConfig,
                activeState,
                fieldDataSS: processDetails?.[0]?.field_data_ss ?? null,
                locale,
                stateConfig,
                processDetails,
                setProcessDetails,
                areReformulatedProposals: areReformulatedProposals(processDetails ?? []),
                hasTIR: activeStateConfig?.visible.includes(TIR)
            }}
        >
            {children}
        </TimelineContext.Provider>
    )
}

export { TimelineContext, TimelineContextProvider }
/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Container to Restricted responsável por construir o routeamento do container e sincroniza o componente em questão.
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */

import { lazy, useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

// Components
import HeaderContainerV2 from "pages/Restricted/shared/Header/indexV2";
import FooterContainer from "./shared/Footer";
import Loading from 'components/Loading';
import RedefinePasswordDialog from 'components/Dialogs/RedefinePasswordDialog';
import { RestrictedProvider } from 'contexts/providers/RestrictedProvider';

// Routes
import Home from "routes/Home";
import Dashboard from "routes/Dashboard";
import Processes from "routes/Processes";
import ProcessDetails from "routes/ProcessDetails";
import lazyLoader from 'services/lazyLoader';
import CookiePolicy from 'routes/CookiePolicy';
import CreateProcess from 'routes/CreateProcess';
import CreateAssetManagement from 'routes/CreateAssetManagement';

//Hooks
import useNotifications from 'hooks/notifications';

//Services
import { isTokenValid, isUserFieldV2, isUserManager } from 'services/user';

//Constants
import { SESSION_EXPIRED, TOAST_SESSION_EXPIRED_ID } from 'constants/statusCodeHttp';
import { BASENAME_URL } from 'constants/settings';

// Contexts
import UserContext from 'contexts/userContext';
import AuthContext from 'contexts/authContext';

const Error404 = lazy(() => lazyLoader(() => import("components/Error404")));

const Restricted = ({ match }) => {

    const { userID, hasChangedPass, setHasChangedPass, userTypeID } = useContext(UserContext);
    const { isUserAuthenticated, logoutHandler } = useContext(AuthContext);

    const { toastNotify } = useNotifications();

    useEffect(() => { // Must be in a use effect to avoid updating redux's state while rendering this component. Use effect only runs after renders.
        if (!isTokenValid() && isUserAuthenticated) {//istokenvalido
            toastNotify('warning', SESSION_EXPIRED, TOAST_SESSION_EXPIRED_ID);
            logoutHandler();
        }
    }, [isUserAuthenticated]); // eslint-disable-line

    if (!isUserAuthenticated) { // If userToken is deleted while user is logged in
        return <Navigate to={{ pathname: BASENAME_URL + 'login' }} />
    }

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
        document.body.classList.add('ios-mobile-view-height')
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
        document.body.classList.remove('ios-mobile-view-height')
    }

    return (
        <div className={`app-container`}>
            {
                isUserAuthenticated && <div className="app-main-container">
                    <HeaderContainerV2 />
                    {/*<Tour/>*/}
                    <RestrictedProvider>
                        <main id='main-content' className="app-main-content-wrapper">
                            <div className={`${!isUserFieldV2(userTypeID) ? 'app-main-content' : 'app-main-content-v2' }`}>
                                <Suspense fallback={<Loading useTopLoading={true} size={30} />} >
                                    <Routes>
                                        <Route path={`home/`} element={ isUserFieldV2(userTypeID) ? <Processes userTypeID={userTypeID} /> : <Home />} />
                                        <Route path={`dashboard/`} element={<Dashboard userTypeID={userTypeID} />} />
                                        <Route path={`processes/`} element={<Processes userTypeID={userTypeID} /> } />
                                        <Route path={`processes/:id/details/`} element={<ProcessDetails userTypeID={userTypeID} />} />
                                        {isUserFieldV2(userTypeID) && <Route path={`processes/create/`} element={isUserManager(userTypeID) ? <CreateProcess /> : null} />}
                                        {isUserFieldV2(userTypeID) && <Route path={`processes/create-asset/`} element={isUserManager(userTypeID) ? <CreateAssetManagement /> : null} />}
                                        <Route path={`cookie-policies`} element={<CookiePolicy />} />
                                        <Route path={`404/*`} element={<Error404 />} />
                                        <Route element={<Error404 />} />
                                    </Routes>
                                </Suspense>
                            </div>
                            <FooterContainer isUserAuthenticated={isUserAuthenticated} />
                            {
                                <RedefinePasswordDialog
                                    userID={userID}
                                    setHasChangedPass={setHasChangedPass}
                                    hasChangedPass={hasChangedPass}
                                />
                            }
                        </main>
                    </RestrictedProvider>
                </div>
            }
        </div>
    );
};

Restricted.propTypes = {
    // match: PropTypes.object.isRequired,
};

export default Restricted;

import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import IntlMessages from 'components/util/IntlMessages';
import CookieConsent from 'components/CookieConsent';
import { routeToCookiePolicy } from 'services/cookies';
import CustomizeCookiesDialog from 'components/CustomizeCookiesDialog';

/**
 * @context Container to Footer
 *
 * @returns {*}
 * @constructor
 */
const Footer = ({ isUserAuthenticated }) => {

	let navigate = useNavigate();

	const yearCurrent = useMemo(() => (new Date().getFullYear()), [])
	const [showPolicy, setShowPolicy] = useState(false);
	const [showPrivacySettings, setShowPrivacySettings] = useState(false);
	const [policyTypeToShow, setPolicyTypeToShow] = useState(null);

	const showPolicyHandler = (type = null) => {
		if (!!type) {
			if (!isUserAuthenticated) {
				setPolicyTypeToShow(type);
			} else {
				routeToCookiePolicy(navigate, type)
			}
		} else {
			setShowPolicy(false);
		}
	}

	useEffect(() => {
		if (!!policyTypeToShow) {
			setShowPolicy(true);
		} else {
			setShowPolicy(false);
		}
	}, [policyTypeToShow]);

	useEffect(() => {
		if (!showPolicy) {
			setPolicyTypeToShow(null);
		}
	}, [showPolicy]);

	return (
		<>
			<footer className="app-footer">
				<div className="container d-flex align-items-center justify-content-center">
					<span className="d-inline-block">
						&copy; <IntlMessages id='footer.copyright' values={{ year: yearCurrent }} />
					</span>
					<div className='footer-policy-links h-100'>
						<span onClick={() => showPolicyHandler('use-conditions')}>
							<IntlMessages id='footer.conditions' />
						</span>
						<span onClick={() => showPolicyHandler('privacy-policy')}>
							<IntlMessages id='footer.privacyPolilicy' />
						</span>
						<span onClick={() => showPolicyHandler('cookies-policy')}>
							<IntlMessages id='footer.cookiesPolicy' />
						</span>
						<span onClick={() => setShowPrivacySettings(!showPrivacySettings)}>
							<IntlMessages id='footer.privacySettings' />
						</span>
					</div>
				</div>
			</footer>
			{showPolicy && <CookieConsent type={policyTypeToShow} close={showPolicyHandler} showPolicyHandler={showPolicyHandler} />}
			{
				showPrivacySettings && <CustomizeCookiesDialog
					isOpen={showPrivacySettings}
					setIsOpen={setShowPrivacySettings}
					isUserAuthenticated={isUserAuthenticated}
				/>
			}

		</>
	)
};

export default Footer;

/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @see react-intl docs: https://github.com/formatjs/react-intl
 *
 * @version 20200915
 * @since 20200915 Initial release
 *
 */

import ptPTLang from './entries/pt-PT';
import esESLang from './entries/es-ES';
import enGBLang from './entries/en-GB';
import plPLLang from './entries/pl-PL';
import itITLang from './entries/it-IT';
import elGRLang from './entries/el-GR';
import deDELang from './entries/de-DE';

import { defineMessages } from 'react-intl';

const AppLocale = {
  pt: ptPTLang,
  es: esESLang,
  en: enGBLang,
  it: itITLang,
  pl: plPLLang,
  gr: elGRLang,
  de: deDELang
};

defineMessages(AppLocale.pt.data);
defineMessages(AppLocale.es.data);
defineMessages(AppLocale.en.data);
defineMessages(AppLocale.pl.data);
defineMessages(AppLocale.it.data);
defineMessages(AppLocale.gr.data);
defineMessages(AppLocale.de.data);

export default AppLocale;
import { memo, useContext } from 'react';
import PropTypes from 'prop-types';

//Material UI
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

//Our Components
import { getUserType } from 'services/user';
import { ReactComponent as GoToIcon } from "assets/images/icons/svg/go-to.svg";
import { ReactComponent as AvatarIcon } from 'assets/images/icons/svg/avatar.svg';
import IntlMessages from 'components/util/IntlMessages';

// Contexts
import AuthContext from 'contexts/authContext';
import UserContext from 'contexts/userContext';
import SettingsContext from 'contexts/settingsContext';

const UserCard = ({ isOpen, handleToggle }) => {

    const { logoutHandler } = useContext(AuthContext);
    const { userTypeID, userUsername } = useContext(UserContext);
    const { setDarkTheme } = useContext(SettingsContext);

    return (
        <Dropdown
            isOpen={isOpen}
            toggle={handleToggle}>

            <DropdownToggle
                className="header-dropdown-toggle"
                tag="span"
                data-toggle="dropdown"
            >
                <div className="menu-item-content-last">
                    <div className={`default-svg${isOpen ? "--open" : ""}`}>
                        <AvatarIcon height={24} />
                    </div>
                </div>
            </DropdownToggle>

            <DropdownMenu right positionFixed>
                <div className="dropdown-header-section user-card">
                    <div className="header-section-text ms-2">
                        <h5 className="mb-0">
                            {userUsername}
                        </h5>
                        <h6 className='mb-0 mt-1'>
                            {getUserType(userTypeID)}
                        </h6>
                    </div>
                    <div className="invisible">
                        {/* Set Dark Theme */}
                        <div className="p-2" onClick={setDarkTheme}>
                            <span>
                                <i className="zmdi zmdi-lamp dark-mode-icon" />
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-fill align-items-end justify-content-end">
                        <div className="tertiary-button">
                            <span
                                onClick={logoutHandler}
                            >
                                <GoToIcon className="me-1" />
                                <IntlMessages id="label.logout" />
                            </span>
                        </div>
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    )
}


//PropTypes
UserCard.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
}
export default memo(UserCard);
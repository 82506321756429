
import { isDefined } from 'services/util/auxiliaryUtils';
import clone from "fast-copy";
import jwt_decode from "jwt-decode";
import { INITIAL_USER, USERS_TYPE_ID } from 'constants/user';
import { AVAILABLE_COMPANIES } from 'constants/settings';

/**
 * getUserType
 *
 * @param typeID
 * @returns {string}
 */
export const getUserType = (typeID) => {

    switch (typeID) {
        case USERS_TYPE_ID.MANAGER:
            return 'Manager';
        case USERS_TYPE_ID.PARTNER:
            return 'Partner';
        case USERS_TYPE_ID.MANAGER_V2:
            return 'Manager';
        case USERS_TYPE_ID.PARTNER_V2:
            return 'Field Tech';
        default:
            return 'DEFAULT';
    }
};

/**
 * isTokenValid
 *
 * @returns {boolean}
 */
export const isTokenValid = () => (isDefined(localStorage.getItem('userToken')));

/**
 * getInitialUser
 */
export const getInitialUser = () => {
    let userToken = localStorage.getItem('userToken');
    const _isTokenValid = isTokenValid();
    let user = (_isTokenValid) ? jwt_decode(userToken).user : null;
    let companyObj = (_isTokenValid) ? AVAILABLE_COMPANIES.find(company => company.id === user.company_id) : { name: null };


    return clone({
        ...clone(INITIAL_USER),
        ...user,
        auth: {
            isRequesting: false,
            isUserAuthenticated: _isTokenValid
        },
        companyName: _isTokenValid ? companyObj.name : null
    });
}

export const isUserManager = (userTypeID) => {
    return userTypeID === USERS_TYPE_ID.MANAGER || userTypeID === USERS_TYPE_ID.MANAGER_V2;
}

export const isUserPartner = (userTypeID) => {
    return userTypeID === USERS_TYPE_ID.PARTNER || userTypeID === USERS_TYPE_ID.PARTNER_V2;
}

export const isUserFieldV2 = (userTypeID ) => {
    return userTypeID === USERS_TYPE_ID.PARTNER_V2 || userTypeID === USERS_TYPE_ID.MANAGER_V2 
}

export const getUserTypeDimension = (userTypeID) => {
    switch (userTypeID) {
        case USERS_TYPE_ID.MANAGER:
        case USERS_TYPE_ID.MANAGER_V2:
            return 'Manager';
        case USERS_TYPE_ID.PARTNER:
        case USERS_TYPE_ID.PARTNER_V2:
            return 'Partner';
        default:
            return 'DEFAULT';
    }
}

export const getCompanyDimension = (companyID) => {
    const companyName = AVAILABLE_COMPANIES.find(comp => comp.id === companyID).fullName;
    return `${companyName} (Company ID: ${companyID})`;
}
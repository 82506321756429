import { array, bool, func } from 'prop-types';
import { useState, createContext } from 'react';

export const filterOptionsDefaultState = {
    product: [],
    state: [],
    partner: [],
    salesOperationsManager: [],
    activeSalesOperationsManager: [],
    notStandardReasons: [],
    paymentMethods: [],
    reason: []
}

const FiltersContext = createContext({
    filtersOptions: array,
    setFiltersOptions: func,
    isLoadingOptions: bool,
    setIsLoadingOptions: func,
    processFilters: array,
    setProcessFilters: func,
    globalFilter: array,
    setGlobalFilter: func,
    dashboardFilters: array,
    setDashboardFilters: func,
});

/**
 * Context to store filters options and and selections
 * @param {*} param0 
 * @returns 
 */
export const FiltersProvider = ({ children }) => {
    const [filtersOptions, setFiltersOptions] = useState(filterOptionsDefaultState);
    const [isLoadingOptions, setIsLoadingOptions] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('')

    const [processFilters, setProcessFilters] = useState(null);
    const [dashboardFilters, setDashboardFilters] = useState(null);

    return (
        <FiltersContext.Provider value={{
            filtersOptions,
            setFiltersOptions,
            isLoadingOptions,
            setIsLoadingOptions,
            processFilters,
            setProcessFilters,
            globalFilter,
            setGlobalFilter,
            dashboardFilters,
            setDashboardFilters,
        }}>
            {children}
        </FiltersContext.Provider>
    )
}

export default FiltersContext;
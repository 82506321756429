import { USERS_TYPE_ID } from "constants/user";

//Icons
import { ReactComponent as SwitchIcon } from "assets/images/timeline/actions/switch.svg";
import { ReactComponent as SendIcon } from "assets/images/timeline/actions/send.svg";
import { ReactComponent as EditRequestIcon1 } from "assets/images/timeline/actions/edit1.svg";
import { ReactComponent as EditRequestIcon2 } from "assets/images/timeline/actions/edit2.svg";
import { ReactComponent as ReturnIcon } from "assets/images/timeline/actions/return.svg";
import { TimelineStates } from "types/timeline";

export enum TimelineStateButton {
    UPDATE_STATUS = 'UPDATE_STATUS',
    SEND_FORM = 'SEND_FORM',
    EDIT_REQUEST = 'EDIT_REQUEST',
    RETURN_PREVIOUS_STATUS = 'RETURN_PREVIOUS_STATUS',
    SERVICE_REQUEST = 'SERVICE_REQUEST',
    SEND_TO_ASSET_MANAGEMENT = 'SEND_TO_ASSET_MANAGEMENT',
    SCHEDULE_PRE_QUOTE_SURVEY = 'SCHEDULE_PRE_QUOTE_SURVEY',
    RESCHEDULE_PRE_QUOTE_SURVEY = 'RESCHEDULE_PRE_QUOTE_SURVEY'
}

export type TCONFIG_TIMELINE_V2 = {
    [key: number]: {
        [key: string]: {
            buttons: any
        }
    }
}

export type TCONFIG_TIMELINE_DIALOG = {
    [key: string]: any
}

export enum TimelineDialogInputNames {
    NEW_STATUS = 'NEW_STATUS',
    FIELD_TEAM = 'FIELD_TEAM',
    SCHEDULE_DATE = 'SCHEDULE_DATE',
    SCHEDULE_TIME = 'SCHEDULE_TIME',
    COMMENTS = 'COMMENTS',
    UPLOAD_FILES = 'UPLOAD_FILES',
    UPLOADED_FILES = 'UPLOADED_FILES',
    SELECTED_FORM = 'SELECTED_FORM',
    FORM_USER = 'FORM_USER'
}

export const CONFIG_TIMELINE_V2: TCONFIG_TIMELINE_V2 = {
    [USERS_TYPE_ID.PARTNER_V2]: {
        [TimelineStates.PRE_QUOTE_SURVEY_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.schedulePreQuoteSurveyRequested",
                    rescheduleLabel: "page.field2.0.timeline.buttons.reschedulePreQuoteSurveyRequested",
                    icon: <EditRequestIcon2 className="me-2" />,
                    action: TimelineStateButton.SCHEDULE_PRE_QUOTE_SURVEY
                }
            ]
        },
        [TimelineStates.PRE_INSTALLATION_SURVEY_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.schedulePreQuoteSurveyRequested",
                    rescheduleLabel: "page.field2.0.timeline.buttons.reschedulePreQuoteSurveyRequested",
                    icon: <EditRequestIcon2 className="me-2" />,
                    action: TimelineStateButton.SCHEDULE_PRE_QUOTE_SURVEY
                }
            ]
        },
        [TimelineStates.INSTALLATION_KICK_OFF_SURVEY_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.schedulePreQuoteSurveyRequested",
                    rescheduleLabel: "page.field2.0.timeline.buttons.reschedulePreQuoteSurveyRequested",
                    icon: <EditRequestIcon2 className="me-2" />,
                    action: TimelineStateButton.SCHEDULE_PRE_QUOTE_SURVEY
                }
            ]
        },
        [TimelineStates.COMISSIONING_PENDING]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.schedulePreQuoteSurveyRequested",
                    rescheduleLabel: "page.field2.0.timeline.buttons.reschedulePreQuoteSurveyRequested",
                    icon: <EditRequestIcon2 className="me-2" />,
                    action: TimelineStateButton.SCHEDULE_PRE_QUOTE_SURVEY
                }
            ]
        },
        [TimelineStates.PREVENTIVE_MAINTENANCE_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.schedulePreQuoteSurveyRequested",
                    rescheduleLabel: "page.field2.0.timeline.buttons.reschedulePreQuoteSurveyRequested",
                    icon: <EditRequestIcon2 className="me-2" />,
                    action: TimelineStateButton.SCHEDULE_PRE_QUOTE_SURVEY
                }
            ]
        },
        [TimelineStates.EVALUATION_MAINTENANCE_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.schedulePreQuoteSurveyRequested",
                    rescheduleLabel: "page.field2.0.timeline.buttons.reschedulePreQuoteSurveyRequested",
                    icon: <EditRequestIcon2 className="me-2" />,
                    action: TimelineStateButton.SCHEDULE_PRE_QUOTE_SURVEY
                }
            ]
        },
        [TimelineStates.CORRECTIVE_MAINTENANCE_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.schedulePreQuoteSurveyRequested",
                    rescheduleLabel: "page.field2.0.timeline.buttons.reschedulePreQuoteSurveyRequested",
                    icon: <EditRequestIcon2 className="me-2" />,
                    action: TimelineStateButton.SCHEDULE_PRE_QUOTE_SURVEY
                }
            ]
        },
    },
    [USERS_TYPE_ID.MANAGER_V2]: {
        [TimelineStates.CREATED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                }
            ]
        },
        [TimelineStates.PRE_QUOTE_SURVEY_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.sendForm",
                    icon: <SendIcon className="me-2" width="16" height="16" />,
                    action: TimelineStateButton.SEND_FORM
                },
                {
                    label: "page.field2.0.timeline.buttons.editRequest",
                    icon: <EditRequestIcon1 className="me-2" />,
                    action: TimelineStateButton.EDIT_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.PRE_QUOTE_SURVEY_COMPLETED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.PRE_INSTALLATION_SURVEY_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.sendForm",
                    icon: <SendIcon className="me-2" width="16" height="16" />,
                    action: TimelineStateButton.SEND_FORM
                },
                {
                    label: "page.field2.0.timeline.buttons.editRequest",
                    icon: <EditRequestIcon1 className="me-2" />,
                    action: TimelineStateButton.EDIT_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.PRE_INSTALLATION_SURVEY_COMPLETED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.INSTALLATION_KICK_OFF_SURVEY_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.sendForm",
                    icon: <SendIcon className="me-2" width="16" height="16" />,
                    action: TimelineStateButton.SEND_FORM
                },
                {
                    label: "page.field2.0.timeline.buttons.editRequest",
                    icon: <EditRequestIcon2 className="me-2 with-stroke" />,
                    action: TimelineStateButton.EDIT_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.INSTALLATION_KICK_OFF_SURVEY_COMPLETED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.COMISSIONING_PENDING]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.sendForm",
                    icon: <SendIcon className="me-2" width="16" height="16" />,
                    action: TimelineStateButton.SEND_FORM
                },
                {
                    label: "page.field2.0.timeline.buttons.editRequest",
                    icon: <EditRequestIcon2 className="me-2 with-stroke" />,
                    action: TimelineStateButton.EDIT_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.COMISSIONING_COMPLETE]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.PAUSED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.CANCELLED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.PREVENTIVE_MAINTENANCE_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.sendForm",
                    icon: <SendIcon className="me-2" width="16" height="16" />,
                    action: TimelineStateButton.SEND_FORM
                },
                {
                    label: "page.field2.0.timeline.buttons.editRequest",
                    icon: <EditRequestIcon2 className="me-2 with-stroke" />,
                    action: TimelineStateButton.EDIT_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.PREVENTIVE_MAINTENANCE_COMPLETED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.serviceRequest",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.SERVICE_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.EVALUATION_MAINTENANCE_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.sendForm",
                    icon: <SendIcon className="me-2" width="16" height="16" />,
                    action: TimelineStateButton.SEND_FORM
                },
                {
                    label: "page.field2.0.timeline.buttons.editRequest",
                    icon: <EditRequestIcon2 className="me-2 with-stroke" />,
                    action: TimelineStateButton.EDIT_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.EVALUATION_MAINTENANCE_COMPLETED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.serviceRequest",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.SERVICE_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.CORRECTIVE_MAINTENANCE_REQUESTED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                },
                {
                    label: "page.field2.0.timeline.buttons.sendForm",
                    icon: <SendIcon className="me-2" width="16" height="16" />,
                    action: TimelineStateButton.SEND_FORM
                },
                {
                    label: "page.field2.0.timeline.buttons.editRequest",
                    icon: <EditRequestIcon2 className="me-2 with-stroke" />,
                    action: TimelineStateButton.EDIT_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.CORRECTIVE_MAINTENANCE_COMPLETED]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.serviceRequest",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.SERVICE_REQUEST
                },
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.COMISSIONING_COMPLETE_ASSET]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.serviceRequest",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.SERVICE_REQUEST
                }
            ]
        },
        [TimelineStates.PAUSED_ASSET]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.CANCELLED_ASSET]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.returnPreviousStatus",
                    icon: <ReturnIcon className="me-2" />,
                    action: TimelineStateButton.RETURN_PREVIOUS_STATUS
                }
            ]
        },
        [TimelineStates.CREATED_ASSET]: {
            buttons: [
                {
                    label: "page.field2.0.timeline.buttons.updateStatus",
                    icon: <SwitchIcon className="me-2" />,
                    action: TimelineStateButton.UPDATE_STATUS
                }
            ]
        },
    }
}
import { combineComponents } from 'services/util/context';
import { AutoCompleteProvider } from 'contexts/autoCompleteContext';
import { FiltersProvider } from 'contexts/filtersContext';
import { FiltersProvider as FiltersProviderV2 } from 'contexts/filters/filtersContext';
import { ProcessDetailsContextProvider } from 'contexts/processDetailsContext';
import { ProcessDetailsProvider } from 'contexts/processDetailsV2/processDetailsContext';
import { ProcessesProvider } from 'contexts/processes/processesContext';
import { CreateProcessesProvider } from 'contexts/processes/createProcess/createProcessContext';
import { StructuresProvider } from 'contexts/structures/structuresContext';
import { AssetManagementProvider } from 'contexts/assetManagement/assetManagementContext';

const providers = [
    AutoCompleteProvider,
    FiltersProvider,
    ProcessDetailsContextProvider,
    ProcessDetailsProvider,
    ProcessesProvider,
    FiltersProviderV2,
    CreateProcessesProvider,
    StructuresProvider,
    AssetManagementProvider
]
export const RestrictedProvider = combineComponents(...providers);
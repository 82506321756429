import { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASENAME_URL_HOME } from "constants/settings";
import { isDefined } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';
import FormRecoverPassword from 'components/Forms/RecoverPassword';
import FooterContainer from 'pages/Restricted/shared/Footer';

import { ReactComponent as FieldLogo } from 'assets/@efz/logos/logo-efz-vert.svg';
import { ReactComponent as NGBackground } from 'assets/images/auth/svg/login.svg';

// Contexts
import UserContext from 'contexts/userContext';
import AuthContext from 'contexts/authContext';

// Hooks
import useRecoveryPassword from 'hooks/recoverPassword';

const ResetPassword = () => {

    const { isUserAuthenticated } = useContext(AuthContext);
    const { userTypeID } = useContext(UserContext);

    const {
        isRequesting,
        fetchResetPassword,
        msgResetPassword
    } = useRecoveryPassword();

    let navigate = useNavigate();

    // componentWillUnmountWithUseEffect
    useEffect(() => {
        return () => {
            toast.dismiss(); // dismiss all notifications
        }
    }, []); //eslint-disable-line

    // if user is authenticated, redirect
    useEffect(() => {
        if (isUserAuthenticated && isDefined(userTypeID)) {
            navigate(BASENAME_URL_HOME);
        }
    }, [isUserAuthenticated]); //eslint-disable-line

    //handleOnSubmit
    const handleOnSubmit = ({ email }) => {
        fetchResetPassword(email);
    };

    return (
        <div className={'app-main-login'}>

            <section className="login-svg-bg">
            <div className="login-svg-ng">
                    <NGBackground />
                </div> 
            </section>

            <section className="login-top-section">
                <div>
                    <div className="app-login-container animated fadeIn animation-duration-2">
                        <div className="login-content m-4">
                            {/* Header */}
                            <div className='login-header'>
                                <FieldLogo />
                            </div>
                            {/* Body */}
                            <div className="login-body">
                                <div className="login-body-text">
                                    <h4><IntlMessages id={'page.resetPassword.subtitle'} /></h4>
                                </div>
                                <FormRecoverPassword 
                                    isRequesting={isRequesting}
                                    msgResetPassword={msgResetPassword}
                                    handleOnSubmit={handleOnSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterContainer isUserAuthenticated={false} />
        </div>
    );
};

export default ResetPassword;

/**
 * @author Daniel Bastos <daniel.bastos@caixamagica.pt>,
 *
 * @description API requests to the context of Processes
 *
 * @version 20200917
 * @since 20200917 Initial release
 *
 */

import axios from 'services/axios';
import { StatusCodes } from 'http-status-codes';
import useNotifications from 'hooks/notifications';
import {
    URL_FILE_UPLOAD,
    URL_PROCESS_LOGS,
    URL_PRODUCTS,
    URL_SALES_OPERATIONS_MANAGERS,
    URL_SERVICE_MANAGERS,
    URL_STATES,
    URL_PAYMENT_METHODS,
    URL_NOT_STANDARD_REASONS,
    URL_AUDIT_FILES,
    URL_PROCESS,
    URL_CLIENTS,
    URL_STRUCTURES,
    URL_ASSET_MANAGEMENT,
    URL_REASONS,
    URL_VYN_REASONS
} from 'constants/endpoints'
import {
    NETWORK_ERROR
} from "constants/statusCodeHttp";
import {
    getEndpoint,
    getConfigHeader
} from "services/endpoints";
import { objectToFormData } from 'services/util/auxiliaryUtils';

const CancelToken = axios.CancelToken;
let posCreateProcessCancel;
let postCreateProcessAssetManagementCancel;

/**
 * Get Process Logs
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getProcessesLogs = (params) => {
    return axios.get(getEndpoint(URL_PROCESS_LOGS),
        {
            headers: getConfigHeader(),
            params: params,
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Process Logs
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getProcessesLogsV2 = (params) => {
    return axios.get(getEndpoint(URL_PROCESS),
        {
            headers: getConfigHeader(),
            params: params,
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Process Logs
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getAssetManagementProcesses = (params) => {
    return axios.get(getEndpoint(URL_ASSET_MANAGEMENT),
        {
            headers: getConfigHeader(),
            params: params,
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Process Logs
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getClients = (params) => {
    return axios.get(getEndpoint(URL_CLIENTS),
        {
            headers: getConfigHeader(),
            params: params,
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Process Timeline
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getProcessTimeline = (params) => {
    return axios.get(getEndpoint(URL_PROCESS_LOGS) + '/timeline/' + params,
        {
            headers: getConfigHeader(),
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * getFileFromProcess
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getProcessFiles = (params) => {
    return axios.get(
        getEndpoint(URL_FILE_UPLOAD) + '/' + params.contract_id,
        {
            headers: getConfigHeader(),
            responseType: 'arraybuffer'
        }
    ).then(response => {

        const { notify } = useNotifications();
        if (StatusCodes.BAD_REQUEST === response?.status) notify({ data: null, message: 'NO_FILES_AVAILABLE', status: response?.status });
        return (response);
    }).catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
}
/**
 * getFileFromProcess
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getProcessFilesV2 = (params) => {
    return axios.get(
        getEndpoint(URL_FILE_UPLOAD) + '/' + params.download_tag + '/' + params.contract_id,
        {
            headers: getConfigHeader(),
            responseType: 'arraybuffer'
        }
    ).then(response => {

        const { notify } = useNotifications();
        if (StatusCodes.BAD_REQUEST === response?.status) notify({ data: null, message: 'NO_FILES_AVAILABLE', status: response?.status });
        return (response);
    }).catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
}

/**
 * Get Search Products Filter
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getStructures = () => {
    return axios.get(getEndpoint(URL_STRUCTURES),
        {
            headers: getConfigHeader(),
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Search Products Filter
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getSearchProductsFilter = (withBattery) => {
    return axios.get(getEndpoint(URL_PRODUCTS),
        {
            headers: getConfigHeader(),
            params: {
                with_batteries: withBattery
            }
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Search States Filter
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getSearchStatesFilter = () => {
    return axios.get(getEndpoint(URL_STATES),
        {
            headers: getConfigHeader()
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Search Sales Operations Manager Filter
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getSearchSalesOperationsManager = (active_so_managers = false) => {
    return axios.get(getEndpoint(URL_SALES_OPERATIONS_MANAGERS),
        {
            headers: getConfigHeader(),
            params: {
                'active_so_managers': active_so_managers
            }
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Search States Filter
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getServiceManager = (search) => {
    return axios.get(getEndpoint(URL_SERVICE_MANAGERS) + '?service_manager=' + search,
        {
            headers: getConfigHeader()
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Search Payment Methods Filter
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getSearchPaymentMethods = () => {
    return axios.get(getEndpoint(URL_PAYMENT_METHODS),
        {
            headers: getConfigHeader()
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Process Not Standard Reasons
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getNotStandardReasons = () => {
    return axios.get(getEndpoint(URL_NOT_STANDARD_REASONS),
        {
            headers: getConfigHeader()
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Process Not Standard Reasons
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getVynReasons = () => {
    return axios.get(getEndpoint(URL_VYN_REASONS),
        {
            headers: getConfigHeader()
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * Get Filter Reasons
 *
 * @param data
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getFilterReasons = (state_id) => {
    return axios.get(`${getEndpoint(URL_REASONS)}/${state_id}`,
        {
            headers: getConfigHeader()
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
};

/**
 * getAuditFiles
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAuditFiles = (params) => {
    return axios.get(
        getEndpoint(URL_AUDIT_FILES) + '/' + params.contract_id,
        {
            headers: getConfigHeader(),
            responseType: 'arraybuffer'
        }
    ).then(response => {
        const { notify } = useNotifications();
        if (StatusCodes.BAD_REQUEST === response?.status) notify({ data: null, message: 'NO_FILES_AVAILABLE', status: response?.status });
        return (response);
    }).catch(error =>
        (error.response ? error.response : NETWORK_ERROR)
    );
}

/**
 * postProcess
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postProcess = (body) => {
    posCreateProcessCancel && posCreateProcessCancel();
    return axios.post(getEndpoint(URL_PROCESS),
        objectToFormData(body),
        {
            headers: {
                ...getConfigHeader(),
                'Content-Type': 'multipart/form-data'
            },
            cancelToken: new CancelToken(function executor(c) {
                posCreateProcessCancel = c;// An executor function receives a cancel function as a parameter
            }),
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
}

/**
 * postProcessAssetManagement
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postProcessAssetManagement = (body) => {
    postCreateProcessAssetManagementCancel && postCreateProcessAssetManagementCancel();
    return axios.post(getEndpoint(URL_ASSET_MANAGEMENT),
        objectToFormData(body),
        {
            headers: {
                ...getConfigHeader(),
                'Content-Type': 'multipart/form-data'
            },
            cancelToken: new CancelToken(function executor(c) {
                postCreateProcessAssetManagementCancel = c;// An executor function receives a cancel function as a parameter
            }),
        }).then(response => (response)).catch(error =>
            (error.response ? error.response : NETWORK_ERROR)
        );
}


import { defineMessages } from 'react-intl';
import deMessages from 'lngProvider/locales/de_DE.json';

const deLang = {
  messages: {
    ...deMessages
  },
  locale: 'de-DE',
  data: defineMessages
};
export default deLang;

import { createContext, useContext, useMemo } from "react";
import { object } from "prop-types";

// Constants
import { CLIENT_INFORMATION_CONFIGS } from "constants/processDetails/configs";

// Contexts
import UserContext from "contexts/userContext";
import { ProcessDetailsContext } from "contexts/processDetailsContext";

const ClientInformationContext = createContext({
    configClientInformation: object
});

const ClientInformationContextProvider = ({ children }) => {

    const {
        userTypeID,
        userCompanyID
    } = useContext(UserContext);

    const {
        processDetails,
        processDetailsTab
    } = useContext(ProcessDetailsContext)

    const configClientInformation = useMemo(() => (
        CLIENT_INFORMATION_CONFIGS?.[userCompanyID]?.[userTypeID]?.[processDetails?.[processDetailsTab]?.product_type_id]
    ), [processDetails,userCompanyID,userTypeID,processDetailsTab]); 

    return (
        <ClientInformationContext.Provider
            value={{
                configClientInformation
            }}
        >
            {children}
        </ClientInformationContext.Provider>
    )
}

export { ClientInformationContext, ClientInformationContextProvider}
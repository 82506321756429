import React, { memo, useEffect, useState } from "react"
import { Button } from '@save2compete/efz-design-system';
import GenericDialog from "components/GenericDialog";
import GenericTitle from "components/GenericTitle";
import IntlMessages from "components/util/IntlMessages";
import CookieConsent from "components/CookieConsent";
// import Alert from "components/@efz/Alert";
import useCookies from "hooks/cookies";
import { routeToCookiePolicy } from "services/cookies";
import { useNavigate } from "react-router-dom";


const CustomizeCookiesDialog = ({
    isOpen,
    setIsOpen,
    cookieBannerHandler,
    isUserAuthenticated
}) => {

    const navigate = useNavigate();

    const {
        setCookie,
        // getCookie,
        // CONSENT_COOKIE,
        PLATFORM_CUSTOMIZATION_COOKIE,
        PERSONALIZED_ADVERTISING_COOKIE
    } = useCookies();

    // const _platformCustomizationCookie = getCookie(PLATFORM_CUSTOMIZATION_COOKIE) ?? true;
    // const _personalizedAdvertisingCookie = getCookie(PERSONALIZED_ADVERTISING_COOKIE) ?? true;
    // const _consentCookie = getCookie(CONSENT_COOKIE);

    const [showPolicy, setShowPolicy] = useState(false);
    // const [showWarning, setShowWarning] = useState(false);
    const [policyTypeToShow, setPolicyTypeToShow] = useState(null);

    // const [personalizedAdvertising, setPersonalizedAdvertising] = useState(_personalizedAdvertisingCookie);
    // const [platformCustomization, setPlatformCustomization] = useState(_platformCustomizationCookie);

    const showPolicyHandler = (type = null) => {
        if (!!type) {
            if (!isUserAuthenticated) {
                setPolicyTypeToShow(type);
            } else {
                setIsOpen(false);
                routeToCookiePolicy(navigate, type);
            }
        } else {
            setShowPolicy(false);
        }
    }


    const switchHandler = () => {
        setCookie(PLATFORM_CUSTOMIZATION_COOKIE, 365)
        setCookie(PERSONALIZED_ADVERTISING_COOKIE, 730)
        setIsOpen(false)
        cookieBannerHandler?.accept()
    }

    // useEffect(() => {
    //     if ((!personalizedAdvertising && _personalizedAdvertisingCookie) || (!platformCustomization && _platformCustomizationCookie)) {
    //         setShowWarning(true) 
    //     } else {
    //         setShowWarning(false)
    //     }
    // }, [personalizedAdvertising, platformCustomization, _platformCustomizationCookie, _personalizedAdvertisingCookie])

    useEffect(() => {
        if (!!policyTypeToShow) {
            setShowPolicy(true);
        } else {
            setShowPolicy(false);
        }
    }, [policyTypeToShow]);

    useEffect(() => {
        if (!showPolicy) {
            setPolicyTypeToShow(null);
        }
    }, [showPolicy]);



    const dialogTitle = (
        <GenericTitle
            textId="label.cookies.privacySettings"
            redLine
        />
    );

    const dialogBody = (
        <>
            <section className='privacy-section'>
                <div className='privacy-section-content'>
                    <div>
                        <IntlMessages id='dialog.privacy.1' />
                        <br />
                        <ul>
                            <li><IntlMessages id='dialog.privacy.1.1' /></li>
                            <li><IntlMessages id='dialog.privacy.1.2' /></li>
                            <li><IntlMessages id='dialog.privacy.1.3' /></li>
                            <li><IntlMessages id='dialog.privacy.1.4' /></li>
                            <li><IntlMessages id='dialog.privacy.1.5' /></li>
                        </ul>
                        <IntlMessages id='dialog.privacy.2.1' whitespaceEnd /> <span className='link' onClick={() => showPolicyHandler('privacy-policy')}><IntlMessages id='label.here' /></span>
                        <IntlMessages id='dialog.privacy.2.2' whitespaceEnd /> <span className='link' onClick={() => showPolicyHandler('cookies-policy')}><IntlMessages id='label.here' /></span>.
                    </div>
                    <div className='label-category' />
                </div>
            </section>

            <section className='privacy-section'>
                <div className='privacy-section-title'>
                    <div className='privacy-section-title-first-line'>
                        <span><IntlMessages id='dialog.privacy.essential' /></span>
                        <span className='label-category'><IntlMessages id='label.alwaysOn' /></span>
                    </div>
                    <hr />
                </div>
                <div className='privacy-section-content'>
                    <div>
                        <IntlMessages id='dialog.privacy.essential.1' />
                    </div>
                    <div className='label-category' />
                </div>
            </section>

            <section className='privacy-section'>
                <div className='privacy-section-title'>
                    <div className='privacy-section-title-first-line'>
                        <span><IntlMessages id='dialog.privacy.customization' /></span>
                        <span className='label-category'><IntlMessages id='label.alwaysOn' /></span>
                    </div>
                    <hr />
                </div>
                <div className='privacy-section-content'>
                    <div>
                        <IntlMessages id='dialog.privacy.customization.1' />
                        <br />
                        <ul>
                            <li><IntlMessages id='dialog.privacy.customization.1.1' /></li>
                            <li><IntlMessages id='dialog.privacy.customization.1.2' /></li>
                        </ul>
                        <IntlMessages id='dialog.privacy.customization.2' />
                    </div>
                    <div className='label-category' />
                </div>
            </section>

            {/* <section className='privacy-section'>
                <div className='privacy-section-title'>
                    <div className='privacy-section-title-first-line'>
                        <span><IntlMessages id='dialog.privacy.add' /></span>
                        <div className='label-category'>
                            <Switch
                                checked={personalizedAdvertising}
                                onChange={(e) => setPersonalizedAdvertising(e.target.checked)}
                            />
                        </div>
                    </div>
                    <hr />
                </div>
                <div className='privacy-section-content'>
                    <div>
                        <IntlMessages id='dialog.privacy.add.1' />
                        <br />
                        <ul>
                            <li><IntlMessages id='dialog.privacy.add.1.1' /></li>
                            <li><IntlMessages id='dialog.privacy.add.1.2' /></li>
                            <li><IntlMessages id='dialog.privacy.add.1.3' /></li>
                            <li><IntlMessages id='dialog.privacy.add.1.4' /></li>
                            <li><IntlMessages id='dialog.privacy.add.1.5' /></li>
                        </ul>
                        <IntlMessages id='dialog.privacy.add.2' />
                    </div>
                    <div className='label-category' />
                </div>
                {
                    (showWarning && _consentCookie) && (
                        <Alert
                            severity='warning'
                            className='mt-2'
                            <span><IntlMessages id='dialog.privacy.alert' /></span>
                        />
                    )
                }
            </section> */}
        </>
    );

    const dialogButtons = (
        <div className="w-100 d-flex justify-content-center privacy-dialog-actions">
            {/* <Button
                className='tertiary-button-2'
                disableRipple
                type={'button'}
                onClick={() => setIsOpen(false)}
                disableFocusRipple
                variant="textSecondary"
            >
                <IntlMessages id="label.back" />
            </Button> */}

            <Button
                dataTestId="collective-info-next-button"
                onClick={switchHandler}
                variant="primary"
            >
                <IntlMessages id="label.consent" />
            </Button>
        </div>
    );

    return (
        <>
            {!showPolicy && <GenericDialog
                open={isOpen}
                handleClose={() => setIsOpen(false)}
                title={dialogTitle}
                body={dialogBody}
                buttons={dialogButtons}
                className={'cookies-dialog'}
            />}
            {showPolicy && <CookieConsent type={policyTypeToShow} close={showPolicyHandler} showPolicyHandler={showPolicyHandler} />}
        </>
    );
}

export default memo(CustomizeCookiesDialog);
export interface IAssetManagementState {
    processDetailsHasAssetManagement: boolean,
    processesHasAssetManagement: boolean,
    processDetailsHasProcessView: boolean,
    viewToShow: 'asset' | 'installation'
}

export enum AssetManagementActions {
    SET_PROCESSES_HAS_ASSET_MANAGEMENT = 'SET_PROCESSES_HAS_ASSET_MANAGEMENT',
    SET_PROCESS_DETAILS_HAS_ASSET_MANAGEMENT = 'SET_PROCESS_DETAILS_HAS_ASSET_MANAGEMENT',
    SET_VIEW_TO_SHOW = 'SET_VIEW_TO_SHOW',
    SET_PROCESS_DETAILS_HAS_PROCESS_VIEW = 'SET_PROCESS_DETAILS_HAS_PROCESS_VIEW'
}

export interface IAssetManagementReducerAction {
    type: AssetManagementActions,
    payload?: any
}

export interface IAssetManagementContext {
    assetManagementState: IAssetManagementState,
    assetManagementDispatchHandler: <Actions extends AssetManagementActions>(type: Actions, payload?: TAMDispatcherValues<Actions>) => void,
}


export type TAMDispatcherValues<Action> =
    Action extends typeof AssetManagementActions.SET_PROCESSES_HAS_ASSET_MANAGEMENT ? any :
    Action extends typeof AssetManagementActions.SET_PROCESS_DETAILS_HAS_ASSET_MANAGEMENT ? any :
    Action extends typeof AssetManagementActions.SET_PROCESS_DETAILS_HAS_PROCESS_VIEW ? boolean :
    Action extends typeof AssetManagementActions.SET_VIEW_TO_SHOW ? 'installation' | 'asset' :
    any
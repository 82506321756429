import { memo } from 'react';
import IntlMessages from 'components/util/IntlMessages';

const LanguageItem = ({ language, switchLanguage, handleRequestClose, isActive }) => {
    const { name, nameTranslated, isoLocale } = language;

    return (
        <li
            className={`pointer ${isActive ? 'active' : ''}`}
            onClick={() => {
                handleRequestClose();
                switchLanguage(language);
            }}
        >
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                    <span className='language-icon'>{isoLocale}</span>
                    <span className="ms-1">-</span>
                    <h5 className="mb-0 ms-1">
                        <IntlMessages id={nameTranslated} />
                    </h5>
                </div>
                <span className='language-italic'>
                    <IntlMessages id={name} />
                </span>
            </div> 
        </li>
    );
};

export default memo(LanguageItem);

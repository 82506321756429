
import { StructuresActions } from "constants/structures";
import { IStructuresContext } from "interfaces/structures";
import { IReactNodeWithChildren } from "interfaces/utils";
import { createContext, useReducer } from "react";
import { initialStructuresReducer, structuresReducer } from "./structuresReducer";

const StructuresContext = createContext<IStructuresContext>({
    structuresState: initialStructuresReducer,
    structuresDispatchHandler: () => { }
})

const StructuresProvider: React.FC<IReactNodeWithChildren> = ({children}) => {

    const [structuresState, structuresDispatch] = useReducer(structuresReducer, initialStructuresReducer);

    const structuresDispatchHandler = (type: StructuresActions, payload?: any) => {
        structuresDispatch({
            type: type,
            payload: payload
        })
    }

    const structuresContextValues: IStructuresContext = { structuresState, structuresDispatchHandler }

    return (
        <StructuresContext.Provider value={structuresContextValues}>
            {children}
        </StructuresContext.Provider>
    )
}

export { StructuresContext, StructuresProvider }
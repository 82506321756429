import { BASENAME_URL } from "constants/settings";
import { Button } from '@save2compete/efz-design-system';
import { useNavigate } from "react-router-dom";
import { withTracker } from 'services/withTracker';
import {ReactComponent as BrokenLightBulb} from "assets/images/404/svg/broken-lightbulb.svg";
import { ReactComponent as BackgroundLeft } from 'assets/images/404/svg/left-graph.svg';
import { ReactComponent as BackgroundRight } from 'assets/images/404/svg/right-graph.svg';
import { ReactComponent as BackgroundBottom } from 'assets/images/404/svg/bottom-graph.svg';
import IntlMessages from 'components/util/IntlMessages';

const Error404 = () => {
    let navigate = useNavigate();

    return (
        <div className="content-wrapper-fluid page404-container">
            <div className="ornaments-svg">
                <div className="ornament-left">
                    <BackgroundLeft />
                </div>
                <div className="ornament-bottom-container">
                    <div className="ornament-bottom">
                        <BackgroundBottom />
                    </div>
                </div>
                <div className="ornament-right">
                    <BackgroundRight />
                </div>
            </div>

            <section className="page404-content-container">
                <div className="content-container">
                    <div className="page404-content-image">
                        <BrokenLightBulb />
                    </div>
                    <div className="page404-content-text">
                        <h3 className="text-center mt-3">
                            <IntlMessages id="page.404.description.part1" />
                        </h3>
                        <span className="text-center"><IntlMessages id="page.404.description.part2" /></span>
                    </div>
                    
                </div>
                <div className="w-100 text-center">
                    <Button
                        dataTestId="go-home-button"
                        onClick={() => navigate(BASENAME_URL)}
                    >
                        <IntlMessages id='page.404.button.goHome' />
                    </Button>
                </div>
                
            </section>
        </div>
    )
}

export default withTracker(Error404, 'Error 404');
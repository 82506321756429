import { createContext, useReducer, useState, useEffect, useContext } from "react";
import { StatusCodes } from "http-status-codes";

// Contexts
import { initialCreateProcessReducer, createProcessReducer } from "./createProcessReducer";

// Constants
import { ProcessesActions } from "constants/processesV2";

// Interfaces
import { IClient } from "interfaces/client";
import { IFacility } from "interfaces/facility";
import { ICreateProcessContext, IGetSearchPaymentMethods, TBusinessModel } from "interfaces/processes";
import { ISolution } from "interfaces/solution";
import { IReactNodeWithChildren } from "interfaces/utils";

// Services
import { intlMessages, isFieldDefined } from "services/util/auxiliaryUtils";

//API
import { getSearchPaymentMethods } from "api/processes";
import { isUserManager } from "services/user";
import UserContext from "contexts/userContext";
import { IUsersContext } from "interfaces/users";
import useNotifications from "hooks/notifications";


const CreateProcessesContext = createContext<ICreateProcessContext>({
    creationState: initialCreateProcessReducer,
    createProcessDispatchHandler: () => { },
    clientList: [],
    setClientList: () => { },
    clientSearchHistory: [],
    setClientSearchHistory: () => { },
    facilityList: [],
    setFacilityList: () => { },
    facilitySearchHistory: [],
    setFacilitySearchHistory: () => { },
    solutionsList: [],
    setSolutionsList: () => { },
    isRequestingFacilitiesOnClientSearch: false,
    setIsRequestingFacilitiesOnClientSearch: () => { },
    isRequestingSolutions: false,
    setIsRequestingSolutions: () => { },
    isCreatingProcess: false,
    setIsCreatingProcess: () => { },
    resetClientHandler: () => { },
    resetFacilityHandler: () => { },
    resetSolutionHandler: () => { },
    businessModelsList: []
})
CreateProcessesContext.displayName = 'efz-CreateProcesses'

const CreateProcessesProvider: React.FC<IReactNodeWithChildren> = ({ children }) => {
    
    const { toastNotify } = useNotifications()
    const { userTypeID } = useContext(UserContext) as IUsersContext 

    const [creationState, createProcessDispatch] = useReducer(createProcessReducer, initialCreateProcessReducer);
    const createProcessDispatchHandler = (type: ProcessesActions, payload?: any) => {
        createProcessDispatch({
            type: type,
            payload: payload
        })
    }

    const [businessModelsList, setBusinessModelsList] = useState<TBusinessModel[]>([])

    const [clientList, setClientList] = useState<IClient[]>([])
    const [clientSearchHistory, setClientSearchHistory] = useState<IClient[]>([])

    const [facilityList, setFacilityList] = useState<IFacility[]>([]);
    const [facilitySearchHistory, setFacilitySearchHistory] = useState<IFacility[]>([])

    const [solutionsList, setSolutionsList] = useState<ISolution[]>([])

    const [isRequestingFacilitiesOnClientSearch, setIsRequestingFacilitiesOnClientSearch] = useState(false);
    const [isRequestingSolutions, setIsRequestingSolutions] = useState<boolean>(false);
    const [isCreatingProcess, setIsCreatingProcess] = useState<boolean>(false);

    const resetClientHandler = () => {
        createProcessDispatchHandler(ProcessesActions.RESET_CLIENT)
        setFacilityList([])
    }

    const resetFacilityHandler = () => {
        createProcessDispatchHandler(ProcessesActions.RESET_FACILITY)

        if (!isFieldDefined(creationState?.client.id)) {
            setFacilityList([]);
        }
    }

    const resetSolutionHandler = () => {
        createProcessDispatchHandler(ProcessesActions.RESET_SOLUTION)
        setSolutionsList([])
    }

    const fetchBusinessModelsList = async () => {
        if(isUserManager(userTypeID)) {
            try {
                const rspPM: IGetSearchPaymentMethods = await getSearchPaymentMethods()
                if(rspPM?.status === StatusCodes.OK) {
                    rspPM?.data?.data.forEach(bm => {
                        bm.label = intlMessages(`product.typeBusinessModel.${bm.description}`)
                        bm.value = bm.business_model_ids
                    })
                    setBusinessModelsList(rspPM?.data?.data)
                } else {
                    setBusinessModelsList([])
                }
            } catch (error) {
                //Sentry
                toastNotify('error', error?.toString());
            }
        }
    }


    useEffect(() => {
        fetchBusinessModelsList()
    },[])//eslint-disable-line

    const createProcessContextValues: ICreateProcessContext = {
        creationState,
        createProcessDispatchHandler,
        clientList,
        setClientList,
        clientSearchHistory,
        setClientSearchHistory,
        facilityList,
        setFacilityList,
        facilitySearchHistory,
        setFacilitySearchHistory,
        solutionsList,
        setSolutionsList,
        isRequestingFacilitiesOnClientSearch,
        setIsRequestingFacilitiesOnClientSearch,
        isRequestingSolutions,
        setIsRequestingSolutions,
        isCreatingProcess,
        setIsCreatingProcess,
        resetClientHandler,
        resetFacilityHandler,
        resetSolutionHandler,
        businessModelsList
    }

    return (
        <CreateProcessesContext.Provider value={createProcessContextValues}>
            {children}
        </CreateProcessesContext.Provider>
    )
}

export { CreateProcessesContext, CreateProcessesProvider }
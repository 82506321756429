//Libraries
import { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import useTheme from '@mui/material/styles/useTheme';

//Constants
import { BASENAME_URL, BASENAME_URL_DASHBOARD, BASENAME_URL_PROCESSES } from 'constants/settings';
import { getStaticFiles } from 'constants/endpoints';
import { MatomoEvents, MatomoEventTypes, MatomoSections } from 'constants/matomo';

//Components
import LangSwitcherCard from 'components/Header/LangSwitcherCard';
import UserCard from 'components/Header/UserCard';
import ProcessesCard from 'components/Header/ProcessesCard';
import DashboardCard from 'components/Header/DashboardCard';
import LazyImage from 'components/util/LazyImage';

// Contexts
import AuthContext from 'contexts/authContext';
import UserContext from 'contexts/userContext';
import SettingsContext from 'contexts/settingsContext';

// Icons
import { ReactComponent as FieldLogo } from 'assets/@efz/logos/logo-field.svg';

//Services
import { isUserFieldV2 } from 'services/user';
import { matomoEvt } from 'services/matomo';

//Interfaces
import { IUsersContext } from 'interfaces/users';
import { IAuthContext, ISettingsContext } from 'interfaces/utils';

const HeaderV2 = () => {
	const { userTypeID, userUsername } = useContext(UserContext) as IUsersContext;
	const { locale, setDarkTheme } = useContext(SettingsContext) as ISettingsContext;
	const { logoutHandler, switchLang } = useContext(AuthContext) as IAuthContext;

	let navigate = useNavigate();
	const [isOpenLangSwitcher, setIsOpenLangSwitcher] = useState(false);
	const [isOpenUserInfo, setIsOpenUserInfo] = useState(false);

	const theme = useTheme();

	useEffect(() => {
		window.addEventListener('resize', () => {});
	}, []);//eslint-disable-line

	// DashboardCard
	const handleOnClickGoToDashboard = useCallback(() => (navigate(BASENAME_URL_DASHBOARD)), []);//eslint-disable-line

	// ProcesesCard
	const handleOnClickGoToProcesses = useCallback(() => (navigate(BASENAME_URL_PROCESSES)), []);//eslint-disable-line

	// langSwitcherCard
	const langCode = locale.icon;
	const handleToggleLangSwitcherCard = useCallback(() => (setIsOpenLangSwitcher(!isOpenLangSwitcher)), [isOpenLangSwitcher]);//eslint-disable-line
	const handleSwitchLanguage = useCallback((locale: any) => (switchLang(locale)), []);//eslint-disable-line

	// UserCard
	const handleToggleUserCard = useCallback(() => (setIsOpenUserInfo(!isOpenUserInfo)), [isOpenUserInfo]);//eslint-disable-line
	const handleUserSignOut = useCallback(() => (logoutHandler()), []); //eslint-disable-line

	return (
		<nav className="app-header">
			<div className="pt-0 d-flex flex-row h-100">
				<div className="header-logo me-3">
					<Link to={ isUserFieldV2(userTypeID) ? `${BASENAME_URL_PROCESSES}` : `${BASENAME_URL}app/home`} >
						<FieldLogo className='header-efz-logo me-4' height={12}/>
						{/* @ts-ignore */}
						<LazyImage src={getStaticFiles(theme?.logo?.src, '')} alt="efz" sizeImg={40} customClassName='header-logo-placeholder' />
					</Link>
				</div>

				<div className="header-menu" >
					<ul className="menu-list">
						{/* List of Process */}
                        {/* @ts-ignore */}
						<li 
							className={`menu-item py-3`} 
							onClick={() => matomoEvt({
								type: MatomoEvents.TOP_LEVEL_NAV,
								event: MatomoEventTypes.CLICK,
								section: MatomoSections.PROCESSES,
								dimension: null
							})}
						>
							<ProcessesCard
								handleOnClickGoTo={handleOnClickGoToProcesses}
                                //@ts-ignore
								isThisPathActive={window.location.pathname === BASENAME_URL_PROCESSES}
							/>
						</li>

						{/* Dashboard */}
                        {/* @ts-ignore */}
						<li 
							className={`menu-item py-3`} 
							onClick={() => matomoEvt({
								type: MatomoEvents.TOP_LEVEL_NAV,
								event: MatomoEventTypes.CLICK,
								section: MatomoSections.DASHBOARD,
								dimension: null
							})}
						>
							<DashboardCard
								handleOnClickGoTo={handleOnClickGoToDashboard}
                                //@ts-ignore
								isThisPathActive={window.location.pathname === BASENAME_URL_DASHBOARD}
							/>
						</li>


						{/* lang */}
                        {/* @ts-ignore */}
						<li 
							className={`menu-item${isOpenLangSwitcher ? '-expanded' : ''}`} 
							onClick={() => matomoEvt({
								type: MatomoEvents.TOP_LEVEL_NAV,
								event: MatomoEventTypes.CLICK,
								section: MatomoSections.LANGUAGE_SELECTOR,
								dimension: null
							})}
						>
							<LangSwitcherCard
								isOpen={isOpenLangSwitcher}
								handleToggle={handleToggleLangSwitcherCard}
								handleSwitchLanguage={handleSwitchLanguage}
								langCode={langCode}
							/>
						</li>

						{/* user */}
                        {/* @ts-ignore */}
						<li 
							className={`menu-item${isOpenUserInfo ? '-expanded' : ''}`} 
							onClick={() => matomoEvt({
								type: MatomoEvents.TOP_LEVEL_NAV,
								event: MatomoEventTypes.CLICK,
								section: MatomoSections.PROFILE,
								dimension: null
							})}
						>
							<UserCard
								isOpen={isOpenUserInfo}
								handleToggle={handleToggleUserCard}
                                //@ts-ignore
								handleUserSignOut={() => logoutHandler()}
								userTypeID={userTypeID}
								username={userUsername}
								setDarkTheme={setDarkTheme}
							/>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);

};

export default HeaderV2;

/* import {
    GA_EVT_CLICK_DASHBOARD_CALENDAR,
    GA_EVT_CLICK_DASHBOARD_FILTERS,
    GA_EVT_CLICK_HOMEPAGE,
    GA_EVT_CLICK_NAVIGATION,
    GA_EVT_CLICK_PROCESS_DETAILS,
    GA_EVT_CLICK_PROCESS_DETAILS_EDIT_CLIENT_DATA,
    GA_EVT_CLICK_PROCESS_DETAILS_EDIT_TECH_DETAILS,
    GA_EVT_CLICK_PROCESS_DETAILS_INFO_SITE_SURVEY,
    GA_EVT_CLICK_PROCESS_DETAILS_TIMELINE,
    GA_EVT_CLICK_PROCESS_LIST,
    GA_EVT_CLICK_PROCESS_LIST_FILTERS,
    GA_EVT_CLICK_PROCESS_LIST_PAGINATION,
    GA_TYPE_EVT
} from "constants/gAnalytics"; */

export function gAnalyticsEvt(label, type = '', user = null, evt = 'Click') {
    /* switch (type) {
        case GA_TYPE_EVT.PROCESS_LIST:
            window.gtag('event', evt, GA_EVT_CLICK_PROCESS_LIST[label][user]);
            break;
        case GA_TYPE_EVT.PROCESS_LIST_PAGINATION:
            window.gtag('event', evt, GA_EVT_CLICK_PROCESS_LIST_PAGINATION[label][user]);
            break;
        case GA_TYPE_EVT.PROCESS_LIST_FILTERS:
            window.gtag('event', evt, GA_EVT_CLICK_PROCESS_LIST_FILTERS[label][user]);
            break;
        case GA_TYPE_EVT.DASHBOARD_FILTER:
            window.gtag('event', evt, GA_EVT_CLICK_DASHBOARD_FILTERS[label][user]);
            break;
        case GA_TYPE_EVT.PROCESS_DETAILS:
            window.gtag('event', evt, GA_EVT_CLICK_PROCESS_DETAILS[label][user]);
            break;
        case GA_TYPE_EVT.PROCESS_DETAILS_TIMELINE:
            window.gtag('event', evt, GA_EVT_CLICK_PROCESS_DETAILS_TIMELINE[label][user]);
            break;
        case GA_TYPE_EVT.PROCESS_DETAILS_EDIT_CLIENT_DATA:
            window.gtag('event', evt, GA_EVT_CLICK_PROCESS_DETAILS_EDIT_CLIENT_DATA[label][user]);
            break;
        case GA_TYPE_EVT.PROCESS_DETAILS_TECH_DETAILS:
            window.gtag('event', evt, GA_EVT_CLICK_PROCESS_DETAILS_EDIT_TECH_DETAILS[label][user]);
            break;
        case GA_TYPE_EVT.PROCESS_DETAILS_INFO_SITE_SURVEY:
            window.gtag('event', evt, GA_EVT_CLICK_PROCESS_DETAILS_INFO_SITE_SURVEY[label][user]);
            break;
        case GA_TYPE_EVT.HOMEPAGE:
            window.gtag('event', evt, GA_EVT_CLICK_HOMEPAGE[label][user]);
            break;
        case GA_TYPE_EVT.NAVIGATION:
            window.gtag('event', evt, GA_EVT_CLICK_NAVIGATION[label][user]);
            break;
        case GA_TYPE_EVT.DASHBOARD_CALENDAR:
            window.gtag('event', evt, GA_EVT_CLICK_DASHBOARD_CALENDAR[label][user]);
            break;
        case GA_TYPE_EVT.LOGIN:
            window.gtag('event', evt, label);
            break;
        default:
            break;
    } */
}
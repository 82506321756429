import { getBaseURL } from "services/endpoints";
import { isDefined } from "services/util/auxiliaryUtils";

// * ACTION TYPES * \\
export const URL_BASE = '';
export const URL_AUTH = 'auth';
export const URL_PROCESS_LOGS = 'process-logs';
export const URL_PROCESS = 'process';
export const URL_ASSET_MANAGEMENT = 'asset-management-process';
export const URL_PROCESS_DASHBOARD = 'dashboard';
export const URL_PROCESS_SCHEDULES = 'schedules';
export const URL_PROCESS_FORMS = 'forms';
export const URL_STATES = 'states';
export const URL_PARTNERS = 'partners';
export const URL_SERVICE_MANAGERS = 'service-managers';
export const URL_STANDBY_REASON = 'standby-reasons';
export const URL_CANCEL_REASON = 'cancel-reasons';
export const URL_REFORMULATED_PSE_REASON = 'reformulate-reasons-pse';
export const URL_REFORMULATED_REASON = 'reformulate-reasons';
export const URL_REFORMULATED_SERVICE_MANAGER_REASON = 'reformulate-reasons-service-manager';
export const URL_UNFEASIBLE_REASON = 'unfeasible-reasons';
export const URL_FILE_UPLOAD = 'file-upload';
export const URL_PRODUCTS = 'products';
export const URL_USERS = 'users';
export const URL_EXTRA_INFORMATION = 'extra-information';
export const URL_SALES_OPERATIONS_MANAGERS = 'sales-operation-manager';
export const URL_PAYMENT_METHODS = 'payment-methods';
export const URL_PRODUCTION_ARRAY_FILE = 'production-array-file';
export const URL_NOT_STANDARD_REASONS = 'not-standard-reasons';
export const URL_VYN_REASONS = 'vyn-reasons';
export const URL_AUDIT_FILES = 'audit-files';
export const URL_CLIENTS = 'clients';
export const URL_FACILITIES = 'facilities';
export const URL_STRUCTURES = 'structures';
export const URL_AVAILABLE_PROPOSALS = 'available-proposals';
export const URL_REASONS = 'reasons';
export const URL_GET_USERS = 'get-users';


/**
 * getStaticFiles 
 * @description ficheiros estaticos que encontra-se na AzrStorage
 * 
 * @param {*} filename 
 */
export function getStaticFiles(filename, dir1 = '', dir2 = '', dir3 = '') {
    return isDefined(filename) ? `${getBaseURL()}${process.env.REACT_APP_API_URL_STATIC_FILES}${dir1}${dir2}${dir3}${filename}` : undefined;
}

export const DEBOUNCE_TIME = 300;
export const CONFIG_HEADERS = {
    'Content-Type': 'application/json; charset=utf-8',
    cancellable: true,
    timeout: 80000
};

import { AssetManagementActions, IAssetManagementReducerAction, IAssetManagementState } from "interfaces/assetManagement"

export const initialAssetManagementReducer: IAssetManagementState = {
    processDetailsHasAssetManagement: false,
    processesHasAssetManagement: false,
    processDetailsHasProcessView: false,
    viewToShow: 'installation'
}

export const assetManagementReducer = (state = initialAssetManagementReducer, action: IAssetManagementReducerAction) => {
    switch(action.type) {
        case AssetManagementActions.SET_PROCESSES_HAS_ASSET_MANAGEMENT:
            return {
                ...state,
                processesHasAssetManagement: action?.payload
            }
        case AssetManagementActions.SET_PROCESS_DETAILS_HAS_ASSET_MANAGEMENT: 
            return {
                ...state,
                processDetailsHasAssetManagement: action?.payload
            }
        case AssetManagementActions.SET_VIEW_TO_SHOW: 
            return {
                ...state,
                viewToShow: action?.payload
            }
        case AssetManagementActions.SET_PROCESS_DETAILS_HAS_PROCESS_VIEW: 
            return {
                ...state,
                processDetailsHasProcessView: action?.payload
            }
        default:
            return state
    }
}
import { useDispatch } from "react-redux";
import { SWITCH_LANGUAGE } from "constants/settings";
/**
 * 
 * @param {function} 
 * @returns 
 */

const useSettings = (locale) => {
    const dispatch = useDispatch();

    const switchLang = (lang) => {
        dispatch({ type: SWITCH_LANGUAGE, payload: lang })
    };

    return {
        switchLang
    }
}

export default useSettings;
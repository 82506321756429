// import appLocaleData from 'react-intl/index';
import plMessages from 'lngProvider/locales/pl_PL.json';

const plLang = {
  messages: {
    ...plMessages
  },
  locale: 'pl-PL',
  // data: appLocaleData
};
export default plLang;